import { Entity } from "types/Models/entity";
import { emptyApi } from "./emptyApi";
import { BillingInvoice } from "types/Models/billing";
import { Subscription } from "types/Models/subscription";

type FilingState = {
  state_id: string;
  name: string;
  country: string;
};

type Discount = {
  coupon?: {
    uuid: string;
    coupon_code: string;
  };
  discount_value: number;
};

export type Cart = {
  uuid: string;
  group_id: string;
  entity_id: string;
  cart_items: {
    uuid: string;
    quantity: number;
    unit_price: number;
    subscription?: Subscription;
    discounted_price?: number;
    product_details: {
      product_id: string;
      product_content_type_id: string;
      product_name: string;
      company_entity?: Pick<Entity, "country" | "country_code" | "name">;
      season?: string;
      has_balancing_payment?: boolean;
      product_description: string;
      slabs: string;
      state?: FilingState;
    };
    ra_state_name?: string;
    tier_information?: {
      uuid: string;
      range: string;
      amount: string;
    };
    subtotal: number;
    total: number;
    discount?: Discount;
  }[];
  order_summary: {
    subtotal: number;
    items_count: number;
    discount?: Discount;
    total: number;
  };
};

export type Product = {
  uuid: string;
  title: string;
  category: string;
  product_type: string;
  product_content_type_id: 229;
  quantity_type: string;
  description: string;
  display_text: string;
  approx_price: string;
  approx_deadline: string;
  default_task_template?: string;
  government_fee?: string;
  seasons: number[];
  share_data: {
    is_sharable: boolean;
  };
  states?: FilingState[];
  billing_period?: string;
  has_from_date: boolean;
  has_to_date: boolean;
  base_task_template_id: string;
  extension?: string;
};

export const productsApi = emptyApi.injectEndpoints({
  endpoints: (build) => ({
    getAllProducts: build.query<Record<string, Product[]>, { gorupId: string }>(
      {
        query: ({ gorupId }) => {
          return {
            url: `/api/inkle/billing/group/${gorupId}/product/`,
          };
        },
      }
    ),
    getCart: build.query<Cart, { groupId: string; entityId: string }>({
      query: ({ groupId, entityId }) => ({
        url: `/api/inkle/billing/group/${groupId}/entity/${entityId}/cart/`,
      }),
      providesTags: (res) => (res ? [{ type: "CART" }] : []),
    }),
    addProductToCart: build.mutation<
      Cart,
      {
        groupId: string;
        entityId: string;
        payload: {
          entity_id?: string;
          product_id: string;
          product_content_type_id: number;
          season?: string | null;
          quantity?: number;
          selected_tier_amount?: number | string;
          ra_state_name?: string;
          payment_amount?: number;
          state_id?: string;
        };
      }
    >({
      query: ({ groupId, payload, entityId }) => ({
        url: `/api/inkle/billing/group/${groupId}/entity/${entityId}/cart/`,
        body: payload,
        method: "POST",
      }),
      invalidatesTags: (res) => (res ? [{ type: "CART" }] : []),
    }),
    removeProductFromCart: build.mutation<
      void,
      {
        groupId: string;
        entityId: string;
        cartItemId: string;
      }
    >({
      query: ({ groupId, cartItemId, entityId }) => ({
        url: `/api/inkle/billing/group/${groupId}/entity/${entityId}/cart-item/${cartItemId}`,
        method: "DELETE",
      }),
      invalidatesTags: [{ type: "CART" }],
    }),
    checkoutCart: build.mutation<
      { invoice: BillingInvoice | null; cart_details: Cart },
      { groupId: string; cartId: string; entityId: string }
    >({
      query: ({ cartId, groupId, entityId }) => ({
        url: `/api/inkle/billing/group/${groupId}/entity/${entityId}/cart/${cartId}/checkout/`,
        method: "POST",
      }),
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetAllProductsQuery,
  useAddProductToCartMutation,
  useGetCartQuery,
  useRemoveProductFromCartMutation,
  useCheckoutCartMutation,
} = productsApi;
