import { AmountSuperScript } from "components/design/AmountSuperScript";
import Loader from "components/design/loader";
import { Avatar } from "components/DesignSystem/AvatarGroup/Avatar";
import { Button } from "components/DesignSystem/Button/Button";
import Modal from "components/DesignSystem/Modal/Modal";
import Table from "components/DesignSystem/Table/V2/Table";
import { TextArea } from "components/DesignSystem/TextArea/TextArea";
import { REQUESTED_INFO_CUSTOM_TYPE } from "constants/chatType";
import { DD_MMM_YYYY } from "constants/date";
import dayjs from "dayjs";
import { Form, Formik, useFormikContext } from "formik";
import { AnimatePresence, motion } from "framer-motion";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useRoleBasedView } from "hooks/useRoleBasedView";
import { useToast } from "hooks/useToast";
import { useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import {
  CellContext,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "react-table-8.10.7";
import {
  useAddClarificationMutation,
  useDeleteOpenItemTxnMutation,
  useGetOpenItemsQuery,
  useSendClarificationRequestMutation,
} from "store/apis/openItem";
import { openFloatingChat, setToOpenChatId } from "store/slices/chat";
import { OpenItem } from "types/Models/openItem";
import { BackendError } from "types/utils/error";
import ToolTip from "components/design/toolTip";
import ConditionalToolTip from "components/design/conditionalToolTip";
import { ApplyToAllIcon } from "components/icons/ApplyToAllIcon";
import { requestInfoValidation } from "formValidations/requestInfoValidation";
import { DeleteIcon } from "components/icons/delete";

type RequestInfoProps = {
  isOpen: boolean;
  onClose: () => void;
  csvId: string;
  channelUrl: string;
};

export const DeleteOpenItem = (info: CellContext<OpenItem, string>) => {
  const { alertToast } = useToast();
  const { uuid: groupId } = useCurrentGroupContext();
  const [deleteOpenItemTxn, { isLoading }] = useDeleteOpenItemTxnMutation();
  const uuid = info.getValue();

  const DeleteTxn = async (openItemId: string) => {
    try {
      await deleteOpenItemTxn({ groupId, openItemId }).unwrap();
    } catch (error) {
      alertToast({
        message: (error as BackendError)?.data?.error?.message,
      });
    }
  };
  return (
    <Button
      customType="ghost_icon"
      onClick={() => DeleteTxn(uuid)}
      disabled={isLoading}
      isLoading={isLoading}
    >
      {isLoading ? "" : <DeleteIcon />}
    </Button>
  );
};

const ApplyToAll = ({
  info,
}: {
  info: CellContext<OpenItem, string | undefined>;
}) => {
  const { setValues, values } = useFormikContext<{
    information_requests: Record<string, string>;
  }>();

  const copyTextToAll = () => {
    setValues({
      information_requests: Object.fromEntries(
        Object.keys(values.information_requests).map((k) => [
          k,
          values.information_requests[info.row.original.uuid],
        ])
      ),
    });
  };

  return (
    <div className="t-flex t-items">
      <ToolTip text="Apply to all">
        <Button
          type="button"
          customType="ghost_icon"
          size="small"
          onClick={copyTextToAll}
        >
          <ApplyToAllIcon />
        </Button>
      </ToolTip>
    </div>
  );
};

const RequestInput = (info: CellContext<OpenItem, string | undefined>) => {
  const { isAdmin, isCpa } = useRoleBasedView();

  return (
    <div className="t-flex t-items-center t-gap-1.5">
      <TextArea
        name={`information_requests.${info.row.original.uuid}]`}
        disabled={!Boolean(isAdmin || isCpa)}
        cols={100}
        rows={1}
        id={info.row.original.uuid}
        onFocus={() =>
          // @ts-ignore
          info.table.options.meta?.setFocusedItem(info.row.original.uuid)
        }
        placeholder="Enter information..."
      />
    </div>
  );
};

export const RequestInfo = ({
  isOpen,
  onClose,
  csvId,
  channelUrl,
}: RequestInfoProps) => {
  const dispatch = useDispatch();
  const { successToast, alertToast } = useToast();
  const { uuid: groupId } = useCurrentGroupContext();
  const [focusedItem, setFocusedItem] = useState<string | null>(null);

  const { data: requestedTxn, isLoading } = useGetOpenItemsQuery(
    { csvId, groupId },
    { skip: !groupId || !csvId, refetchOnMountOrArgChange: true }
  );

  const [sendRequestInfo] = useSendClarificationRequestMutation();

  const [addRequestInfo, { isLoading: isUpdating }] =
    useAddClarificationMutation();

  const { open_items = [] } = requestedTxn || {};

  const columnHelper = createColumnHelper<OpenItem>();

  const columns = useMemo(
    () => [
      columnHelper.accessor((row) => row, {
        id: "Date",
        cell: (info) => {
          const { date } = info.getValue();
          return (
            <div className="t-text-subtext t-text-text-30">
              {dayjs(date).format(DD_MMM_YYYY)}
            </div>
          );
        },
        header: "Date",
        size: 15,
      }),
      columnHelper.accessor((row) => row, {
        id: "Vendor",
        cell: (info) => {
          const { merchant, logo } = info.getValue();
          if (!Boolean(merchant)) {
            return "-";
          }

          return (
            <div className="t-flex t-gap-2 t-items-center">
              <Avatar src={logo || ""} alt={merchant!} />
              <div className="t-text-subtext t-text-text-60">{merchant}</div>
            </div>
          );
        },
        header: "Vendor",
        size: 10,
      }),
      columnHelper.accessor("description", {
        id: "Description",
        size: 15,
        cell: (info) => {
          const description = info.getValue();
          return (
            <ConditionalToolTip condition={description}>
              <span className="t-line-clamp-1 t-flex t-justify-start">
                {description || "-"}
              </span>
            </ConditionalToolTip>
          );
        },
      }),
      columnHelper.accessor((row) => row, {
        id: "Amount",
        cell: (info) => {
          const { amount } = info.getValue();
          return (
            <div className="t-flex t-justify-end t-mr-10 t-text-subtext t-text-text-60">
              <AmountSuperScript amount={Number(amount)!} />
            </div>
          );
        },
        header: () => (
          <div className="t-flex t-justify-end t-mr-10">Amount</div>
        ),
        size: 10,
      }),
      columnHelper.accessor("information_request", {
        id: "RequestInformation",
        cell: RequestInput,
        header: () => (
          <div className="after:t-font-bold after:t-text-red after:t-content-['_*']">
            Request Information
          </div>
        ),
        size: 40,
      }),
      columnHelper.accessor("information_request", {
        id: "Applytoall",
        header: "",
        cell: (info) =>
          // @ts-ignore
          info.table.options.meta?.focusedItem === info.row.original.uuid && (
            <ApplyToAll info={info} />
          ),
        size: 10,
      }),
      columnHelper.accessor("uuid", {
        id: "Delete",
        cell: DeleteOpenItem,
        header: "",
        size: 5,
      }),
    ],
    [open_items]
  );

  const table = useReactTable({
    data: open_items || [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    defaultColumn: {
      size: 10,
      minSize: 5,
      maxSize: 100,
    },
    meta: {
      setFocusedItem,
      focusedItem,
    },
  });

  const onSubmit = async (values: {
    information_requests: Record<string, string>;
  }) => {
    try {
      const entries = Object.entries(values.information_requests);
      await Promise.allSettled(
        entries.map(
          async ([uuid, request]) =>
            await addRequestInfo({
              groupId,
              openItemId: uuid,
              informationRequest: request,
            }).unwrap()
        )
      );
    } catch (error) {}

    try {
      await sendRequestInfo({
        channelUrl,
        csvId,
        customType: REQUESTED_INFO_CUSTOM_TYPE,
        groupId,
      }).unwrap();
      onClose();
      dispatch(setToOpenChatId(channelUrl));
      dispatch(openFloatingChat());
      successToast({ message: "Request sent" });
    } catch (error) {
      alertToast({
        message: (error as BackendError)?.data?.error?.message,
      });
    }
  };

  if (!isOpen) {
    return null;
  }

  return (
    <Formik
      initialValues={{
        information_requests: Object.fromEntries(
          open_items.map((i) => [i.uuid, i.information_request || ""])
        ),
      }}
      onSubmit={onSubmit}
      enableReinitialize
      validateOnMount
      validationSchema={requestInfoValidation}
    >
      {({ isValid, submitForm, isSubmitting }) => (
        <Modal.Root open={isOpen} onOpenChange={onClose}>
          <Modal.Content size="xxl" asChild>
            <Form>
              <>
                <Modal.Header>
                  <div>
                    <Modal.Title>Request Info</Modal.Title>
                    <Modal.Subtitle>
                      {open_items.length} Requested
                    </Modal.Subtitle>
                  </div>
                  <Modal.Close />
                </Modal.Header>
                <Modal.Body>
                  {isLoading && open_items.length === 0 && <Loader />}
                  {!isLoading && open_items.length > 0 && (
                    <Table.Container>
                      <Table.Content>
                        <Table.Head>
                          {table.getHeaderGroups().map((headerGroup) => (
                            <Table.Row key={headerGroup.id}>
                              {headerGroup.headers.map((header) => (
                                <Table.HeadCell
                                  key={header.id}
                                  style={{
                                    width: `${header.getSize()}%`,
                                  }}
                                >
                                  {header.isPlaceholder
                                    ? null
                                    : flexRender(
                                        header.column.columnDef.header,
                                        header.getContext()
                                      )}
                                </Table.HeadCell>
                              ))}
                            </Table.Row>
                          ))}
                        </Table.Head>
                        <Table.Body>
                          <AnimatePresence>
                            {table.getRowModel().rows.map((row) => {
                              return (
                                <motion.tr
                                  layout
                                  key={row.original.uuid}
                                  transition={{
                                    duration: 0.1,
                                    ease: "easeOut",
                                  }}
                                  animate={{ x: 0 }}
                                  exit={{ x: -1000 }}
                                  className="t-px-3 t-border-solid t-border-neutral-0 t-border-b t-border-0 t-text-body"
                                >
                                  {row.getVisibleCells().map((cell) => {
                                    return (
                                      <Table.Cell
                                        key={cell.id}
                                        style={{
                                          width: `${cell.column.getSize()}%`,
                                        }}
                                      >
                                        {flexRender(
                                          cell.column.columnDef.cell,
                                          cell.getContext()
                                        )}
                                      </Table.Cell>
                                    );
                                  })}
                                </motion.tr>
                              );
                            })}
                          </AnimatePresence>
                        </Table.Body>
                      </Table.Content>
                    </Table.Container>
                  )}
                </Modal.Body>
                <Modal.Footer className="t-flex t-justify-end">
                  <Button
                    customType="primary"
                    disabled={!isValid || isSubmitting}
                    onClick={submitForm}
                    isLoading={isSubmitting}
                  >
                    Submit
                  </Button>
                </Modal.Footer>
              </>
            </Form>
          </Modal.Content>
        </Modal.Root>
      )}
    </Formik>
  );
};
