export const DeadlineClock = () => (
  <svg
    width="101"
    height="99"
    viewBox="0 0 101 99"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_dd_1911_64411)">
      <ellipse cx="51" cy="48.8505" rx="45" ry="44.8505" fill="white" />
    </g>
    <g filter="url(#filter1_dd_1911_64411)">
      <ellipse cx="51" cy="47.6545" rx="30" ry="29.9003" fill="white" />
    </g>
    <path d="M51 4V9.08306" stroke="#CECDD0" />
    <path d="M51 88.9169V94" stroke="#CECDD0" />
    <path d="M95.7 48.8505L90.6 48.8505" stroke="#CECDD0" />
    <path d="M78 48.8505H46.2" stroke="#706A85" stroke-width="2" />
    <path d="M51 14.7642V53.6346" stroke="#CC4859" />
    <path d="M11.1 48.8505L5.99998 48.8505" stroke="#CECDD0" />
    <defs>
      <filter
        id="filter0_dd_1911_64411"
        x="0"
        y="0"
        width="101"
        height="98.701"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="-2" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.345098 0 0 0 0 0.317647 0 0 0 0 0.439216 0 0 0 0.1 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_1911_64411"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="1" dy="1" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.345098 0 0 0 0 0.317647 0 0 0 0 0.439216 0 0 0 0.1 0"
        />
        <feBlend
          mode="normal"
          in2="effect1_dropShadow_1911_64411"
          result="effect2_dropShadow_1911_64411"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect2_dropShadow_1911_64411"
          result="shape"
        />
      </filter>
      <filter
        id="filter1_dd_1911_64411"
        x="15"
        y="13.7542"
        width="71"
        height="68.8007"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="-2" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.345098 0 0 0 0 0.317647 0 0 0 0 0.439216 0 0 0 0.08 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_1911_64411"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="1" dy="1" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.345098 0 0 0 0 0.317647 0 0 0 0 0.439216 0 0 0 0.08 0"
        />
        <feBlend
          mode="normal"
          in2="effect1_dropShadow_1911_64411"
          result="effect2_dropShadow_1911_64411"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect2_dropShadow_1911_64411"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);
