export const DeadlineClockBig = () => (
  <svg
    width="150"
    height="150"
    viewBox="0 0 161 159"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_dd_1744_99475)">
      <circle cx="81" cy="79" r="75" fill="white" />
    </g>
    <g filter="url(#filter1_dd_1744_99475)">
      <circle cx="81" cy="77" r="50" fill="white" />
    </g>
    <path d="M81 4V12.5" stroke="#CECDD0" />
    <path d="M81 146V154.5" stroke="#CECDD0" />
    <path d="M155.5 79L147 79" stroke="#CECDD0" />
    <path d="M126 79H73" stroke="#706A85" stroke-width="2" />
    <path d="M81 22V87" stroke="#CC4859" />
    <path d="M14.5 79L6 79" stroke="#CECDD0" />
    <defs>
      <filter
        id="filter0_dd_1744_99475"
        x="0"
        y="0"
        width="161"
        height="159"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="-2" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.345098 0 0 0 0 0.317647 0 0 0 0 0.439216 0 0 0 0.1 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_1744_99475"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="1" dy="1" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.345098 0 0 0 0 0.317647 0 0 0 0 0.439216 0 0 0 0.1 0"
        />
        <feBlend
          mode="normal"
          in2="effect1_dropShadow_1744_99475"
          result="effect2_dropShadow_1744_99475"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect2_dropShadow_1744_99475"
          result="shape"
        />
      </filter>
      <filter
        id="filter1_dd_1744_99475"
        x="25"
        y="23"
        width="111"
        height="109"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="-2" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.345098 0 0 0 0 0.317647 0 0 0 0 0.439216 0 0 0 0.08 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_1744_99475"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="1" dy="1" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.345098 0 0 0 0 0.317647 0 0 0 0 0.439216 0 0 0 0.08 0"
        />
        <feBlend
          mode="normal"
          in2="effect1_dropShadow_1744_99475"
          result="effect2_dropShadow_1744_99475"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect2_dropShadow_1744_99475"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);
