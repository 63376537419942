export const PAID = "Paid";
export const PENDING = "Pending";
export const IN_PROGRESS = "In Progress";
export const PARTIAL_REFUND = "Partial Refund";
export const FULL_REFUND = "Full Refund";

export const PAYMENT_SUCCESS = "Payment Successful!";
export const PAYMENT_FAIL = "Error in accepting payment";

export const CARD_DELETE_MESSAGE = "Your card has been deleted";

export const UNPAID = "unpaid";

export const ONE_TIME = "ONE_TIME";
export const RECURRING = "RECURRING";

export const CARD_ACCORDION = "CardAccordion";

export const cardStyle = {
  hidePostalCode: true,
  style: {
    base: {
      color: "#000",
      fontSize: "16px",
      "::placeholder": {
        color: "#32325d",
      },
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a",
    },
  },
};

export const AddCardStyle = {
  hidePostalCode: true,
  style: {
    base: {
      color: "#706A85",
      fontSize: "16px",
      "::placeholder": {
        color: "#706A85",
      },
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a",
    },
  },
};
export const TIER_SEATS = 3;
export const AUTOFILL_ADDRESS_TYPE_TAG = "AUTOFILL_ADDRESS_TYPE_TAG";
export const BILLING = "billing";

export const BILLING_SEASON_FILTER_DATA = [
  { name: "2023", title: "2023" },
  { name: "2022", title: "2022" },
  { name: "2021", title: "2021" },
  { name: "2020", title: "2020" },
];

export const billingFilterNameMapping = {
  fullrefund: "full refund",
  partialrefund: "partial refund",
  latefee: "late fees",
};
