import { multipleFileDelete } from "apis/documents";
import ConditionalToolTip from "components/design/conditionalToolTip";
import { DeleteModal } from "components/design/deleteModal";
import { LoadingToast } from "components/design/LoadingToast";
import { Button } from "components/DesignSystem/Button/Button";
import { Export } from "components/icons/Export";
import {
  FILE_FOLDER_DELETED,
  FILE_FOLDER_DELETE_FAILED,
  FILE_FOLDER_DOWNLOADED,
  FILE_FOLDER_DOWNLOAD_FAILED,
} from "constants/analyticsEvents";
import { useAnalytics } from "hooks/useAnalytics";
import { useCopyToClipboard } from "hooks/useCopyToClipboard";
import { useQuery } from "hooks/useQuery";
import { useToast } from "hooks/useToast";
import { useContext, useEffect, useMemo, useState } from "react";
import Gridview from "static/images/GridView.svg";
import Listview from "static/images/ListView.svg";
import {
  useExportFolderMutation,
  useLazyGetSearchContentQuery,
  useRenameFileFolderMutation,
} from "store/apis/documents";
import { openLink } from "utils/openLink";
import Actions from "./Actions";
import AddActionDropdown from "./addActionDropdown";
import { DocumentContext } from "./documentContext";
import { MoveFileModal } from "./documentsModal/moveFileModal";
import { RenameFileFolderModal } from "./documentsModal/renameFileFolderModal";

export const DocHeaderActions = ({
  userGroupId,
  groupId,
  openAddFolderModal,
  setLoad,
  ParentId,
  setLoadingFileUploadStart,
  uploadPayload,
  open,
  listView,
  changeViewType,
  setUploadPayload,
  uploadLoad,
  setUploadLoad,
  setLoadingMultipleFileDelete,
  groupDocID,
  setMovedFolderName,
  setNewFolderName,
  entityFileAndFolder,
}) => {
  const { trackEvent } = useAnalytics();
  const {
    selectedFiles,
    setSelectedFiles,
    setCurrentPageNo,
    setEntityFileAndFolder,
    handleFolderClick,
  } = useContext(DocumentContext);
  const query = useQuery();
  const { alertToast, successToast } = useToast();
  const searchTerm = query.get("search");
  let selectedFileIds = [...selectedFiles.map(({ uuid }) => uuid)];

  const isAllSelected = useMemo(
    () => entityFileAndFolder.every(({ isChecked }) => isChecked),
    [entityFileAndFolder]
  );

  const [deleteModalShow, setDeleteModalShow] = useState(false);
  const [moveFileModalShow, setMoveFileModalShow] = useState(false);
  const [renameModalShow, setRenameModalShow] = useState(false);
  const [getSearchContent] = useLazyGetSearchContentQuery();
  const [exportFolder, { isLoading: isExporting }] = useExportFolderMutation();
  const { copyToClipboard } = useCopyToClipboard();
  const selectedFileData = useMemo(() => [...selectedFiles], [selectedFiles]);
  const [renameFileAndFolder] = useRenameFileFolderMutation();
  const [selectedFileCount, setSelectedFileCount] = useState(0);

  const linkText = `${process.env.REACT_APP_URL}docs/${groupId}/${selectedFileData?.[0]?.parent_uuid}`;

  useEffect(() => {
    setSelectedFileCount(selectedFileData.length);
  }, [selectedFileData.length]);

  const selectAllToggle = () => {
    const updatedMapData = entityFileAndFolder.map((ele) => ({
      ...ele,
      isChecked: !isAllSelected,
    }));
    setEntityFileAndFolder(updatedMapData);

    if (isAllSelected) {
      setSelectedFiles([]);
      return;
    }
    const selected = updatedMapData.map(
      ({ name, uuid, type, parent_uuid, is_locked }) => {
        return {
          uuid,
          name,
          type,
          parent_uuid,
          is_locked,
        };
      }
    );
    setSelectedFiles(selected);
  };

  const multipleDownloads = async (e) => {
    e.stopPropagation();
    try {
      const { download_url } = await exportFolder({
        groupId,
        fileId: selectedFileIds,
      }).unwrap();
      successToast({
        title: "Export Completed",
      });
      openLink(download_url);
      trackEvent(FILE_FOLDER_DOWNLOADED, {
        file_or_folder_ids: selectedFileIds,
      });
    } catch (error) {
      alertToast({ message: error?.data?.error?.message });
      trackEvent(FILE_FOLDER_DOWNLOAD_FAILED, {
        file_or_folder_ids: selectedFileIds,
        error: error?.data?.error?.message,
      });
    }
  };

  const multipleDelete = async () => {
    try {
      const response = await multipleFileDelete({
        groupId,
        fileIds: selectedFileIds,
      });
      trackEvent(FILE_FOLDER_DELETED, { file_or_folder_ids: selectedFileIds });
      setLoadingMultipleFileDelete(true);
      if (searchTerm) {
        const {
          files = [],
          folders = [],
          current_page,
        } = await getSearchContent({
          searchTerm,
          groupId,
          pageNum: 1,
        }).unwrap();
        setCurrentPageNo(current_page);

        let updatedMapData = [...folders, ...files].map((ele) => {
          return {
            ...ele,
            isChecked: false,
          };
        });
        setEntityFileAndFolder(updatedMapData);
      } else {
        let updatedMapData = [
          ...response.data.data.subfolders,
          ...response.data.data.files,
        ].map((ele) => {
          return {
            ...ele,
            isChecked: false,
          };
        });
        setEntityFileAndFolder(updatedMapData);
      }
      setSelectedFiles([]);
      setDeleteModalShow(false);
      setTimeout(() => {
        setLoadingMultipleFileDelete(false);
      }, 1500);
    } catch (error) {
      trackEvent(FILE_FOLDER_DELETE_FAILED, {
        file_or_folder_ids: selectedFileIds,
        error: error?.data?.error?.message,
      });
      alertToast({ message: error?.response?.data?.error?.message });
    }
  };

  const renameFolder = async ({ file_name }) => {
    try {
      const data = await renameFileAndFolder({
        groupId,
        payload: { file_name },
        fileId: selectedFileData[0]?.uuid,
      }).unwrap();
      successToast({ message: `Renamed to ${data.name}` });
      const updatedDocuments = entityFileAndFolder.map((doc) =>
        doc.uuid === data.uuid ? data : doc
      );
      setEntityFileAndFolder(updatedDocuments);
      setNewFolderName(data.name);
      setSelectedFiles([]);
      setRenameModalShow(false);
    } catch (error) {
      alertToast({ message: error?.response?.data?.error?.message });
    }
  };

  const onCrossClick = () => {
    successToast({ title: "Cancelled", message: "Move cancelled" });
    setMoveFileModalShow(false);
  };

  const exportAllDocuments = async () => {
    try {
      const { download_url } = await exportFolder({
        groupId,
      }).unwrap();
      successToast({
        title: "Export Completed",
      });
      openLink(download_url);
    } catch (error) {
      alertToast({ message: error?.data?.error?.message });
    }
  };

  const isAnyFileOrFolderSelected = selectedFileData.length > 0;

  return (
    <span
      className="viewChangeButtonContainer t-flex gap-2"
      onClick={(e) => e.stopPropagation()}
    >
      {isAnyFileOrFolderSelected ? (
        <span className="t-flex t-gap-2 t-items-center">
          {selectedFileData.length > 1 && !searchTerm && (
            <Button size="small" onClick={selectAllToggle}>
              {isAllSelected ? "Unselect all" : "Select all"}
            </Button>
          )}
          <Actions
            onRename={() => setRenameModalShow(true)}
            onCopy={() => copyToClipboard(linkText)}
            onMove={() => setMoveFileModalShow(true)}
            onDownload={multipleDownloads}
            onDelete={() => setDeleteModalShow(true)}
            goToFolder={() => {
              handleFolderClick(selectedFileData[0]?.parent_uuid);
              setSelectedFiles([]);
            }}
          />
          <span className="t-text-subtitle-sm t-text-text-60">
            {selectedFileCount} selected
          </span>
        </span>
      ) : (
        <>
          <Button onClick={changeViewType} size="small">
            <img
              src={listView ? Gridview : Listview}
              alt="view"
              className="t-mr-1"
            />
            {listView ? "Grid View" : "List View"}
          </Button>
          <ConditionalToolTip condition="Export all documents">
            <sapn>
              <Button
                size="small"
                customType="icon"
                onClick={exportAllDocuments}
                disabled={isExporting}
                isLoading={isExporting}
              >
                <Export />
              </Button>
            </sapn>
          </ConditionalToolTip>
          <AddActionDropdown
            setLoadingFileUploadStart={setLoadingFileUploadStart}
            groupId={groupId}
            setLoad={setLoad}
            openAddFolderModal={openAddFolderModal}
            ParentId={ParentId}
            uploadPayload={uploadPayload}
            setUploadPayload={setUploadPayload}
            uploadLoad={uploadLoad}
            setUploadLoad={setUploadLoad}
            open={open}
          />
        </>
      )}
      {deleteModalShow && (
        <DeleteModal
          text={`Delete ${selectedFileCount} ${selectedFileData[0]?.type?.toLowerCase()}`}
          show={deleteModalShow}
          closeModal={() => setDeleteModalShow(false)}
          onClick={multipleDelete}
        />
      )}
      {moveFileModalShow && (
        <MoveFileModal
          groupId={groupId}
          onCrossClick={onCrossClick}
          moveFileModalShow={moveFileModalShow}
          closeMoveFileModal={() => setMoveFileModalShow(false)}
          fileId={selectedFileIds}
          setNewFolderName={setNewFolderName}
          setLoad={setLoad}
          setMovedFolderName={setMovedFolderName}
          groupDocID={groupDocID}
          ParentId={ParentId}
          isMultipleMove
        />
      )}
      {renameModalShow && (
        <RenameFileFolderModal
          renameModalShow={renameModalShow}
          closeRenameModal={() => setRenameModalShow(false)}
          itemType={selectedFileData[0]?.type}
          folderName={selectedFileData[0]?.name}
          renameFolder={renameFolder}
        />
      )}

      <LoadingToast loading={isExporting} title="Preparing Export">
        This may take upto one minute
      </LoadingToast>
    </span>
  );
};
