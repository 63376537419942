import { Divider } from "components/design/Divider";
import { Checkbox } from "components/DesignSystem/Checkbox/Checkbox";
import { HighlightSearchTerm } from "components/DesignSystem/HighlightText";
import { FOLDER_TYPE, LOCKED_FOLDER } from "constants/documents";
import React, { useContext } from "react";
import { FileObject } from "types/Models/fileObject";
import { DocumentContext } from "./documentContext";
import FileFolderActionDropDown from "./fileFolderActionDropdown";
import { FileFolderCard } from "./folderAndFileSection";

export type FileObjectWithTypeOverride = Omit<FileObject, "type"> & {
  type: "FILE" | "FOLDER";
  isChecked: boolean;
};

type RecentDocumentProps = {
  searchTerm: string;
  doubleClick: ({
    e,
    fileType,
    uuid,
    name,
    type,
    parent_uuid,
    is_locked,
    isRecentDocument,
  }: {
    e: React.MouseEvent<HTMLDivElement, MouseEvent>;
    fileType: string;
    uuid: string;
    name: string;
    type: string;
    parent_uuid: string;
    is_locked: boolean;
    isRecentDocument?: boolean;
  }) => void;
  handleCheckBox: (
    uuid: string,
    e: React.ChangeEvent<HTMLInputElement>,
    isRecentDocument?: boolean
  ) => void;

  userGroupId: string;
  groupId: string;
  setLoad: React.Dispatch<React.SetStateAction<boolean>>;
  setNewFolderName: React.Dispatch<React.SetStateAction<string>>;
  setMovedFolderName: React.Dispatch<React.SetStateAction<string>>;
  groupDocID: string;
  ParentId: string;
  listView: boolean;
  recentDocuments: FileObjectWithTypeOverride[];
};

export const RecentDocument = ({
  searchTerm,
  doubleClick,
  handleCheckBox,
  userGroupId,
  groupId,
  setLoad,
  setNewFolderName,
  setMovedFolderName,
  groupDocID,
  ParentId,
  listView,
  recentDocuments,
}: RecentDocumentProps) => {
  const { selectedFiles, selectedRecentDocs } = useContext(DocumentContext);

  return (
    <div>
      <div className="t-w-full">
        <div className="t-text-subtext">Recently Opened</div>
        <div className="t-w-full t-flex t-gap-6 t-flex-wrap t-mt-4">
          {recentDocuments?.map((file, i) => {
            const {
              uuid,
              name,
              is_previewable,
              file_type,
              type,
              isChecked,
              parent_uuid,
              is_locked,
              thumbnail_file_id,
            } = file;
            const fileType =
              is_locked && type === FOLDER_TYPE ? LOCKED_FOLDER : file_type;
            const disableCheckBox = selectedFiles.some(
              ({ uuid: selectedFileId }: { uuid: string }) =>
                selectedFileId === uuid
            );
            return (
              <FileFolderCard
                key={uuid}
                isChecked={isChecked}
                isAlreadySelected={disableCheckBox}
                thumbnailFileId={thumbnail_file_id}
                name={
                  <span className="t-line-clamp-1">
                    <HighlightSearchTerm text={name} searchTerm={searchTerm} />
                  </span>
                }
                onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) =>
                  doubleClick({
                    e,
                    fileType,
                    uuid,
                    name,
                    type,
                    parent_uuid,
                    is_locked,
                    isRecentDocument: true,
                  })
                }
                isSelectionAllowed={false}
                actionDropDown={
                  <FileFolderActionDropDown
                    fileType={fileType}
                    userGroupId={userGroupId}
                    itemType={type}
                    isPreviewable={is_previewable}
                    folderId={uuid}
                    groupId={groupId}
                    setLoad={setLoad}
                    folderName={name}
                    setNewFolderName={setNewFolderName}
                    setMovedFolderName={setMovedFolderName}
                    groupDocID={groupDocID}
                    ParentId={ParentId}
                    parentUuid={parent_uuid}
                    isLocked={is_locked}
                    listView={listView}
                  />
                }
                fileType={fileType}
                selected={isChecked || uuid === selectedRecentDocs[0]?.uuid}
                dropActive={undefined}
              />
            );
          })}
        </div>
      </div>
      <div className="t-my-6">
        <Divider />
      </div>
    </div>
  );
};
