import SeatPlanManage from "components/billing/SeatPlanManage";
import ConditionalToolTip from "components/design/conditionalToolTip";
import { Button } from "components/DesignSystem/Button/Button";
import DropDown from "components/DesignSystem/Dropdown/Dropdown";
import { OptionDropdown } from "components/icons/optionDropdown";
import { FOREIGN_ENTITY_ACCOUNTANT, INVITED } from "constants/groupRequest";
import { useModal } from "hooks/useModal";
import { useRoleBasedView } from "hooks/useRoleBasedView";
import { useState } from "react";
import { AddOrEditTeamMemberModal } from "./AddEditTeamMemberModal";
import ArchiveMemberModal from "./ArchiveMemberModal";
import { ManageProfile } from "./ManageProfile";
import { ResendInvite } from "./ResendInvite";
import { RevokeInvite } from "./RevokeInvite";

export const TeamMemberOptions = ({
  profileId,
  isArchived,
  updateArchiveToast,
  isSuperAdmin,
  ownAccount,
  multipleOwnerOfGroupExist,
  user,
  availableSeats,
  options,
  groupName,
  groupId,
  openInviteUserSeatManageModal,
  seatData,
}: {
  profileId: string;
  isArchived: boolean;
  updateArchiveToast: (v: string) => void;
  isSuperAdmin: boolean;
  ownAccount: boolean;
  multipleOwnerOfGroupExist: boolean;
  user: {
    uuid: string;
    email: string;
    status: string;
    profile: string;
    role_name: string;
    role_uuid: string;
    group_role_id: string;
    allowed_access: boolean;
    name: string;
    mobile: string;
    linkedIn_url: string;
    current_timezone: string;
    secondary_mobile: string;
    profile_id: string;
  };
  options: { name: string; uuid: string }[];
  groupName: string;
  groupId: string;
  availableSeats: number;
  openInviteUserSeatManageModal?: () => void;
  seatData: {
    active_group_users_count: number;
    available_seats: number;
    extra_seat_amount: number;
    free_seats: number;
  };
}) => {
  const { isAdmin, isCustomer } = useRoleBasedView();
  const [show, setShow] = useState(false);
  const superAdminTooltip = " Primary owner cannot be archived";
  const {
    open: openUserEditModal,
    close: closeUserEditModal,
    isOpen: showUserEditModal,
  } = useModal();
  const {
    open: openSeatManageModal,
    close: closeSeatManageModal,
    isOpen: showSeatManageModal,
  } = useModal();
  const {
    open: openRevokeInviteModal,
    close: closeRevokeInviteModal,
    isOpen: showRevokeInviteModal,
  } = useModal();
  const {
    open: openResendInviteModal,
    close: closeResendInviteModal,
    isOpen: showResendInviteModal,
  } = useModal();
  const manageProfileModal = useModal();
  const isInvitedUser = user.status === INVITED;
  const isAccountant = user?.role_name === FOREIGN_ENTITY_ACCOUNTANT;

  const closeSeatManage = () => {
    closeSeatManageModal();
    openUserEditModal();
  };

  return (
    <>
      <DropDown.Root modal={false}>
        <DropDown.Trigger className="all:unset">
          <Button customType="ghost_icon" size="small">
            <OptionDropdown color="currentColor" />
          </Button>
        </DropDown.Trigger>
        <DropDown.Portal>
          <DropDown.Content
            sideOffset={8}
            className="t-rounded-lg t-bg-surface t-p-2 t-drop-shadow-dropdown sm:t-w-[94vw]"
            align="end"
          >
            {isInvitedUser && (
              <>
                <DropDown.Item
                  className="disable:t-text-gray t-flex t-items-center t-gap-2 t-rounded hover:t-bg-i-surface-light-purple"
                  onSelect={openRevokeInviteModal}
                >
                  <span>Revoke invite</span>
                </DropDown.Item>
                <DropDown.Item
                  className="disable:t-text-gray t-flex t-items-center t-gap-2 t-rounded hover:t-bg-i-surface-light-purple"
                  onSelect={openResendInviteModal}
                >
                  <span>Resend invite</span>
                </DropDown.Item>
              </>
            )}
            {!isInvitedUser && (
              <>
                {isAdmin && (
                  <DropDown.Item
                    className="disable:t-text-gray t-flex t-items-center t-gap-2 t-rounded hover:t-bg-i-surface-light-purple"
                    onSelect={manageProfileModal.open}
                  >
                    <span>Manage profile</span>
                  </DropDown.Item>
                )}
                {!isArchived && ((isAdmin && isAccountant) || isCustomer) && (
                  <DropDown.Item
                    className="disable:t-text-gray t-flex t-items-center t-gap-2 t-rounded hover:t-bg-i-surface-light-purple"
                    onSelect={openUserEditModal}
                  >
                    <span>Manage {isAccountant ? "access" : "user"}</span>
                  </DropDown.Item>
                )}
                {!ownAccount && (
                  <DropDown.Item
                    disabled={isSuperAdmin}
                    className="disable:t-text-gray t-flex t-items-center t-gap-2 t-rounded hover:t-bg-i-surface-light-purple"
                    onSelect={() => setShow(true)}
                  >
                    <ConditionalToolTip
                      condition={isSuperAdmin && superAdminTooltip}
                    >
                      <span>
                        {isArchived ? "Unarchive user" : "Archive user"}
                      </span>
                    </ConditionalToolTip>
                  </DropDown.Item>
                )}
              </>
            )}
          </DropDown.Content>
        </DropDown.Portal>
      </DropDown.Root>
      {show && (
        <ArchiveMemberModal
          profileId={profileId}
          show={show}
          closeModal={() => setShow(false)}
          isArchived={isArchived}
          updateArchiveToast={updateArchiveToast}
        />
      )}
      {showUserEditModal && (
        <AddOrEditTeamMemberModal
          show={showUserEditModal}
          closeModal={() => closeUserEditModal()}
          options={options}
          groupName={groupName}
          groupId={groupId}
          availableSeats={availableSeats}
          user={user}
          openInviteUserSeatManageModal={openInviteUserSeatManageModal}
          openSeatManageModal={openSeatManageModal}
          manageUserFlow
        />
      )}
      <RevokeInvite
        show={showRevokeInviteModal}
        closeModal={closeRevokeInviteModal}
        groupId={groupId}
        email={user.email}
      />
      <ResendInvite
        show={showResendInviteModal}
        closeModal={closeResendInviteModal}
        groupId={groupId}
        email={user.email}
        groupRoleId={user.group_role_id}
      />
      <SeatPlanManage
        show={showSeatManageModal}
        closeModal={closeSeatManage}
        seatData={seatData}
        handlePlanManageSuccess={closeSeatManage}
      />
      <ManageProfile
        show={manageProfileModal.isOpen}
        closeModal={manageProfileModal.close}
        user={user}
      />
    </>
  );
};
