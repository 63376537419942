import Modal from "components/DesignSystem/Modal/Modal";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useQuery, useUpdateQuery } from "hooks/useQuery";
import { useRoleBasedView } from "hooks/useRoleBasedView";
import { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useGetIndividualPerkQuery } from "store/apis/rewards";
import { INTRO_EMAIL } from "../../constants/perks";
import LinkedInBtn from "../../static/images/LinkedInSquareIcon.png";
import "../../static/styles/components/deals.css";
import { openLink } from "../../utils/openLink";
import Optional from "../../utils/optionalRender";
import PerkBtn from "./PerkBtn";
import PerksAccessDetails from "./perksAccessDetails";
import PerksTag from "./perksTag";

const PerksModal = ({ parentUrl }) => {
  const { uuid: groupId } = useCurrentGroupContext();
  const { perkSlug } = useParams();
  const { data: perk } = useGetIndividualPerkQuery(
    { groupId: groupId, perkSlug: perkSlug },
    { skip: !perkSlug || !groupId }
  );

  const {
    description,
    website,
    name,
    linkedin_url,
    heading,
    perk_type,
    is_redeemed,
    icon,
    offer_description,
    perk_id,
    icon_bg_color,
    redeem_details,
    tags,
  } = perk || {};

  const [emailRedeem, setEmailRedeem] = useState(false);

  const history = useHistory();
  const { isCustomer } = useRoleBasedView();
  const { update } = useUpdateQuery();
  const queryParam = useQuery();
  const currentSort = queryParam.get("sort") || "";

  const handleClose = () => {
    history.push(isCustomer ? parentUrl : `/crm/${groupId}/perks`);
    update({ query: "sort", value: currentSort });
    setEmailRedeem(false);
  };

  const goToLinkedin = () => {
    openLink(linkedin_url, "_blank");
  };

  return (
    <Modal.Root open onOpenChange={handleClose}>
      <Modal.Content className="perksModal" size="large">
        <Modal.Header>
          <div></div>
          <Modal.Close />
        </Modal.Header>
        <Modal.Body>
          <div className="perksModalTopContainer">
            <div className="leftDiv">
              <div className="webSiteContainer">
                {icon && (
                  <div
                    className="perksLogo"
                    style={{ backgroundColor: icon_bg_color }}
                  >
                    <img src={icon} alt="perks icon" className="perksIcon" />
                  </div>
                )}
                <div>
                  {linkedin_url && (
                    <span className="linkedInBtn" onClick={goToLinkedin}>
                      <img
                        src={LinkedInBtn}
                        alt="LinkedinBtn"
                        className="linkedinIcon"
                      />
                      <span className="linkedinText">Follow</span>
                    </span>
                  )}
                  <div className="perksModalTitle">{name}</div>
                  <Optional optionalRender="-">
                    {website && (
                      <div className="perkModalValue">
                        <a
                          className="website"
                          href={website}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {website}
                        </a>
                      </div>
                    )}
                  </Optional>
                </div>
              </div>
            </div>
            {offer_description && (
              <div className="offerDetailsContainer">{offer_description}</div>
            )}
          </div>
          <PerksTag tags={tags} />
          <div className="perksModalMiddleContainer">
            <div className="leftDiv">
              <div className="perkPlaceHolder">{heading}</div>
              <div
                className="perkDescription"
                dangerouslySetInnerHTML={{
                  __html: description,
                }}
              />
            </div>
            <div className="rightDiv">
              {perk && (
                <PerkBtn
                  isRedeemed={is_redeemed}
                  perkType={perk_type}
                  perkId={perk_id}
                  groupId={groupId}
                  redeemDetails={redeem_details}
                  emailRedeem={emailRedeem}
                  setEmailRedeem={setEmailRedeem}
                />
              )}
              {perk_type === INTRO_EMAIL && emailRedeem ? (
                <>
                  <PerksAccessDetails perk={perk} />
                </>
              ) : (
                <>{is_redeemed && <PerksAccessDetails perk={perk} />}</>
              )}
            </div>
          </div>
        </Modal.Body>
      </Modal.Content>
    </Modal.Root>
  );
};

export default PerksModal;
