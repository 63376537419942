import { CSSProperties } from "react";
import { Column } from "react-table-8.10.7";

export const getCommonPinningStyles = <T>(column: Column<T>): CSSProperties => {
  const isPinned = column.getIsPinned();

  return {
    backgroundColor: isPinned ? "white" : "transparent",
    left: isPinned === "left" ? `${column.getStart("left")}px` : undefined,
    position: isPinned ? "sticky" : "relative",
    zIndex: isPinned ? 1 : 0,
  };
};
