import React from "react";
import RegisteredStateIcon from "static/images/RegisteredStateIcon.svg";
import NotRegisteredStateIcon from "static/images/NotRegisteredStateIcon.svg";
import { RegistrationStatus } from "store/apis/registrations";
import ProcessingHourGlassIcon from "static/images/ProcessingHourGlassIcon.svg";
import classNames from "classnames";
import TriangleWarning from "static/images/TriangleWarning.svg";

export const RegisteredStateTags = ({
  status,
  statusText,
  withBackground = true,
}: {
  status: RegistrationStatus;
  statusText?: string;
  withBackground?: boolean;
}) => {
  switch (status) {
    case "NOT_REGISTERED": {
      return (
        <div
          className={classNames(
            "t-py-1 t-rounded t-flex t-gap-1 t-items-center t-w-fit",
            {
              "t-px-2 t-bg-neutral-0": withBackground,
            }
          )}
        >
          <img src={NotRegisteredStateIcon} alt="NotRegisteredStateIcon" />
          <div className="t-text-text-60 t-text-body-sm">
            {statusText || "Not registered"}
          </div>
        </div>
      );
    }
    case "REGISTERED":
    case "INCORPORATED":
    case "FOREIGN_QUALIFIED": {
      return (
        <div
          className={classNames(
            "t-py-1 t-rounded t-flex t-gap-1 t-items-center t-w-fit",
            {
              "t-px-2 t-bg-green-10": withBackground,
            }
          )}
        >
          <img src={RegisteredStateIcon} alt="RegisteredStateIcon" />
          <div className="t-text-text-60 t-text-body-sm">
            {statusText || "Registered"}
          </div>
        </div>
      );
    }
    case "PROCESSING": {
      return (
        <div
          className={classNames(
            "t-py-1 t-rounded t-flex t-gap-1 t-items-center t-w-fit",
            {
              "t-px-2 t-bg-yellow-10": withBackground,
            }
          )}
        >
          <img src={ProcessingHourGlassIcon} alt="ProcessingHourGlassIcon" />
          <div className="t-text-text-60 t-text-body-sm">
            {statusText || "Processing"}
          </div>
        </div>
      );
    }
    case "REQUIRED":
    case "FOREIGN_QUALIFICATION_REQUIRED": {
      return (
        <div className="t-py-1 t-rounded t-flex t-gap-1 t-items-center t-w-fit">
          <img src={TriangleWarning} alt="TriangleWarning" />
          <div className="t-text-text-60 t-text-body-sm">
            {statusText || "Required"}
          </div>
        </div>
      );
    }
    default: {
      return null;
    }
  }
};
