import { Button } from "components/DesignSystem/Button/Button";
import {
  ADD_ENTITY_WITH_PLAID_ACCOUNT_FAILURE,
  ADD_ENTITY_WITH_PLAID_ACCOUNT_SUCCESS,
  CONNECT_NEW_PLAID_ACCOUNT_CLICKED,
  CONNECT_NEW_PLAID_ACCOUNT_SUCCESS,
  RECONNECT_PLAID_ACCOUNT_SUCCESS,
  SYNC_PLAID_TRANSACTIONS_FAILURE,
  SYNC_PLAID_TRANSACTIONS_SUCCESS,
} from "constants/analyticsEvents";
import { useAnalytics } from "hooks/useAnalytics";
import { useToast } from "hooks/useToast";
import { MouseEvent, ReactNode, useEffect, useState } from "react";
import { PlaidLinkOnSuccessMetadata, usePlaidLink } from "react-plaid-link";
import {
  useExchangeTokenMutation,
  useGenerateLinkTokenMutation,
  useSyncPlaidTransactionsMutation,
} from "store/apis/bankConnections";
import { PlusIcon } from "./icons/PlusIcon";
import Modal from "./DesignSystem/Modal/Modal";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { Form, Formik } from "formik";
import { useModal } from "hooks/useModal";
import { Loader } from "./DesignSystem/Loader/Loader";
import { TextInput } from "./DesignSystem/TextInput/TextInput";
import ToolTip from "./design/toolTip";
import { useHasSubscriptionPermission } from "hooks/useHasSubscriptionPermission";
import { CONNECT_BANK_ACCOUNT } from "constants/subscriptionPermissionFeatures";

export const LinkBankAccount = ({
  groupId,
  connectBankText,
  onConnect,
  getAllTransactions,
  reconnectBankId,
  children,
  onBankReconnect,
  onComplete,
  entityId,
}: {
  groupId: string;
  connectBankText?: string | ReactNode;
  onConnect?: () => void;
  getAllTransactions?: any;
  reconnectBankId?: string;
  children?: ({
    ready,
    connectPlaid,
  }: {
    ready: boolean;
    connectPlaid: (e: MouseEvent<HTMLButtonElement>) => void;
  }) => ReactNode;
  onBankReconnect?: () => void;
  onComplete?: (a: any) => void;
  entityId: string;
}) => {
  const { alertToast, successToast } = useToast();
  const [generateLinkToken] = useGenerateLinkTokenMutation();
  const [syncPlaidTransactions] = useSyncPlaidTransactionsMutation();
  const [exchangeToken] = useExchangeTokenMutation();
  const [linkToken, setLinkToken] = useState<string | null>(null);
  // const [entityModal, setEntityModal] = useState(false);
  const { trackEvent } = useAnalytics();
  const group = useCurrentGroupContext();
  const entityModal = useModal();
  const transactionsSyncLoadingModal = useModal();

  const permission = useHasSubscriptionPermission({
    feature: CONNECT_BANK_ACCOUNT,
  });

  const syncTransaction = async (entity_item_id: string) => {
    try {
      await syncPlaidTransactions({
        groupId,
        entity_item_id,
      }).unwrap();
      trackEvent(SYNC_PLAID_TRANSACTIONS_SUCCESS, {
        screen: "Bookkeeping - Entity Map Modal",
        entity_item_id,
      });
      onConnect?.();
      await getAllTransactions?.().unwrap();
    } catch (error: any) {
      trackEvent(SYNC_PLAID_TRANSACTIONS_FAILURE, {
        screen: "Bookkeeping - Entity Map Modal",
        entity_item_id,
        error_message: error?.response?.data?.error?.message,
      });
      alertToast({ message: error?.data?.error?.message });
    }
  };

  const handleSubmit = async (
    connectedBanks: PlaidLinkOnSuccessMetadata,
    publicToken: string
  ) => {
    const { institution, accounts = [] } = connectedBanks || {};

    const { institution_id } = institution || {};

    const accountsForProp = accounts.map(
      ({ mask, name }: { name: string; mask: string }) => ({
        name,
        mask,
      })
    );

    if (entityId && institution_id) {
      try {
        transactionsSyncLoadingModal.open();
        const {
          item_data: { uuid },
        } = await exchangeToken({
          groupId,
          public_token: publicToken,
          entity_id: entityId,
          accounts: accountsForProp,
          institution_id: institution_id,
        }).unwrap();
        trackEvent(ADD_ENTITY_WITH_PLAID_ACCOUNT_SUCCESS, {
          screen: "Bookkeeping - Entity Map Modal",
          entity_id: entityId,
        });
        await syncTransaction(uuid);
        transactionsSyncLoadingModal.close();
        successToast({ message: "Account connected" });
        onComplete?.(connectedBanks);
      } catch (error: any) {
        trackEvent(ADD_ENTITY_WITH_PLAID_ACCOUNT_FAILURE, {
          screen: "Bookkeeping - Entity Map Modal",
          entity_id: entityId,
          error_message: error?.response?.data?.error?.message,
        });
        alertToast({ message: error?.data?.error?.message });
        transactionsSyncLoadingModal.close();
      }
    }
  };

  useEffect(() => {
    const init = async () => {
      const response = await generateLinkToken({
        groupId,
        entityItemId: reconnectBankId,
        entityId,
      }).unwrap();
      setLinkToken(response.link_token);
    };

    const permitted = !permission?.blocked && !permission?.isLoading;

    if (groupId && (reconnectBankId || permitted)) {
      init();
    }
  }, [
    entityId,
    groupId,
    reconnectBankId,
    permission?.blocked,
    permission?.isLoading,
  ]);

  const { open, ready } = usePlaidLink({
    token: linkToken,
    env: process.env.REACT_APP_PLAID_ENV,
    onSuccess: (publicToken, metadata) => {
      const { institution } = metadata || {};
      const { name, institution_id } = institution || {};

      if (!reconnectBankId) {
        handleSubmit(metadata, publicToken);

        // @ts-ignore
        trackEvent(CONNECT_NEW_PLAID_ACCOUNT_SUCCESS, {
          screen: "Bookkeeping - Plaid",
          account_name: name,
          institution_id: institution_id,
        });
        return;
      }

      onBankReconnect?.();

      // @ts-ignore
      trackEvent(RECONNECT_PLAID_ACCOUNT_SUCCESS, {
        screen: "Bookkeeping - Plaid",
        account_name: name,
        institution_id: institution_id,
      });
    },
  });

  const onSubmit = async () => {
    entityModal.close();
    open();
  };

  const connectPlaid = (e: MouseEvent<HTMLButtonElement>) => {
    trackEvent(CONNECT_NEW_PLAID_ACCOUNT_CLICKED, {
      screen: "Bookkeeping",
    });
    if (reconnectBankId) {
      return open();
    }
    entityModal.open();
  };

  const entityName = group.entities.find((e) => e.uuid === entityId)?.name;

  return (
    <>
      {children ? (
        children({ ready, connectPlaid })
      ) : (
        <Button
          type="button"
          customType="primary"
          size="small"
          disabled={!ready}
          onClick={connectPlaid}
        >
          <span className="t-flex t-items-center t-gap-2">
            <PlusIcon /> {connectBankText}
          </span>
        </Button>
      )}
      <Modal.Root open={entityModal.isOpen} onOpenChange={entityModal.close}>
        <Modal.Content>
          <Formik
            initialValues={{ entity_id: entityId || "" }}
            onSubmit={onSubmit}
          >
            {({ submitForm, values }) => (
              <>
                <Modal.Header>
                  <div>
                    <Modal.Title>Select Entity</Modal.Title>
                    <Modal.Subtitle>
                      Choose the entity associated with the account.
                    </Modal.Subtitle>
                  </div>
                  <Modal.Close />
                </Modal.Header>
                <Modal.Body>
                  <Form className="t-m-0">
                    <ToolTip text="You can switch entities from the top bar">
                      <div>
                        <TextInput
                          disabled
                          label="Entity"
                          name="entity_id"
                          value={entityName}
                        />
                      </div>
                    </ToolTip>
                  </Form>
                </Modal.Body>
                <Modal.FooterButtonGroup>
                  <Modal.RawClose asChild>
                    <Button>Go back</Button>
                  </Modal.RawClose>
                  <Button customType="primary" onClick={submitForm}>
                    Continue
                  </Button>
                </Modal.FooterButtonGroup>
              </>
            )}
          </Formik>
        </Modal.Content>
      </Modal.Root>

      <Modal.Root open={transactionsSyncLoadingModal.isOpen}>
        <Modal.Content>
          <Modal.Header>
            <p className="t-m-0">&nbsp;</p>
          </Modal.Header>
          <Modal.Body>
            <div className="t-flex t-justify-center t-items-center t-gap-6 t-flex-col">
              <Loader />
              <div className="t-flex t-gap-2 t-flex-col t-justify-center">
                <p className="t-m-0 t-text-subtitle">
                  Syncing your account transactions
                </p>
                <p className="t-m-0 t-text-body t-text-center">
                  This may take sometime
                </p>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <p className="t-m-0">&nbsp;</p>
          </Modal.Footer>
        </Modal.Content>
      </Modal.Root>
    </>
  );
};
