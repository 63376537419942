import { Ticket } from "components/icons/LeftNav/Ticket";
import { Documents as DocumentsIcon } from "components/icons/LeftNav/Documents";
import { LeftBarItem } from "components/Sidebar/LeftBar";
import { ChatLeftNav } from "components/ChatLeftNav/ChatLeftNav";

export const NavAcrossApps = () => {
  return (
    <>
      <ChatLeftNav />
      <LeftBarItem to="/action-items" icon={Ticket}>
        Action Items
      </LeftBarItem>
      <LeftBarItem to="/documents" icon={DocumentsIcon}>
        Documents
      </LeftBarItem>
    </>
  );
};
