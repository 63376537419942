import DashboardContainer from "components/dashboard/DashboardContainer";
import { DashboardLayout } from "components/DashboardLayout";
import { Button } from "components/DesignSystem/Button/Button";
import { Search } from "components/DesignSystem/Search/Search";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { usePageTitle } from "hooks/usePageTitle";
import { useQuery, useUpdateQuery } from "hooks/useQuery";
import { useRoleBasedView } from "hooks/useRoleBasedView";
import { useToast } from "hooks/useToast";
import { useDispatch } from "react-redux";
import ArrowsClockwise from "static/images/ArrowsClockwise.svg";
import { useLazyGetVendorsQuery, vendorsApi } from "store/apis/vendors";
import { openAddOrEditModal } from "store/slices/vendors";
import { debounce } from "utils/debouncing";
import { Header } from "../../../components/DesignSystem/Header/Header";
import { MerchantsPanel } from "../../../components/Vendors/merchantsPanel";

export const Vendors = () => {
  usePageTitle("Vendors");
  const { uuid: groupId } = useCurrentGroupContext();
  const dispatch = useDispatch();
  const query = useQuery();
  const selectedSeason = query.get("season") || "2023";
  const page = Number(query.get("page")) || 1;
  const vendorsTypeFromQuery = query.get("vendorsType");
  const searchTerm = query.get("search_term");
  const { updateMultiple } = useUpdateQuery();
  const { alertToast, successToast } = useToast();
  const searchQuery = query.get("search_term");
  let entityId = useCurrentEntityId();
  const [getVendors] = useLazyGetVendorsQuery();
  const { isCustomer } = useRoleBasedView();

  const handleChange = debounce((e) => {
    const { value } = e.target;
    if (value) {
      updateMultiple([
        { query: "search_term", value },
        { query: "page", value: 1 },
      ]);
    } else {
      updateMultiple([
        { query: "search_term", value: null },
        { query: "page", value: 1 },
      ]);
    }
  }, 200);

  const refreshMerchants = async () => {
    try {
      const queries = {
        groupId: groupId,
        search_term: searchTerm || null,
        type: vendorsTypeFromQuery === "wForm" ? "VENDORS" : null,
        page_num: page,
        season: selectedSeason,
        entityId,
        refresh_merchants: true,
      };
      const data = await getVendors(queries).unwrap();
      successToast({
        message: "Vendors refreshed successfully",
      });

      dispatch(
        // @ts-ignore
        vendorsApi.util.updateQueryData(
          "getVendors",
          { ...queries, refresh_merchants: false },
          (draft) => {
            return data;
          }
        )
      );
    } catch (e: any) {
      alertToast({ message: e?.data?.error?.message });
    }
  };

  return (
    <div className=" t-flex t-flex-col t-gap-5">
      <div className="t-bg-surface t-flex t-gap-4 t-w-full t-items-center t-sticky t-top-0 t-z-header">
        <div className="t-w-1/2">
          <Search
            placeholder="Search..."
            defaultValue={searchQuery || ""}
            onChange={handleChange}
            block
          />
        </div>

        <div className="t-flex t-gap-2 t-ml-auto">
          <Button size="small" onClick={refreshMerchants}>
            <img
              src={ArrowsClockwise}
              alt="ArrowsClockwise"
              className="t-mr-1"
            />
            Refresh
          </Button>
          {!isCustomer && (
            <Button
              size="small"
              customType="primary"
              onClick={() => dispatch(openAddOrEditModal())}
            >
              <span className="t-flex t-gap-2">Add Vendor</span>
            </Button>
          )}
        </div>
      </div>
      <MerchantsPanel />
    </div>
  );
};
