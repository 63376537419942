import { ConditionalLink } from "components/conditionalLink";
import { AmountSuperScript } from "components/design/AmountSuperScript";
import ToolTip from "components/design/toolTip";
import { Button } from "components/DesignSystem/Button/Button";
import { Switch } from "components/DesignSystem/Switch/Switch";
import { SwitchField } from "components/DesignSystem/Switch/SwitchField";
import { Padlock } from "components/icons/Padlock";
import { PermissionBasedUI } from "components/PermissionBasedUI/PermissionBasedUI";
import { RECURRING_INVOICE } from "constants/subscriptionPermissionFeatures";
import { useFormikContext } from "formik";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useModal } from "hooks/useModal";
import { useToast } from "hooks/useToast";
import { RecurringInvoiceSetting } from "pages/Books/Invoicing/Recurring/RecurringInvoiceSetting";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  useGetEntityInvoiceQuery,
  useGetInvoiceSettingsQuery,
  useUpdateInvoiceRecurringMutation,
} from "store/apis/invoices";
import { currency } from "utils/Currency";
import { formatDate } from "utils/formatDate";
import { pluralize } from "utils/pluralize";

export const InvoiceConfiguration = () => {
  const { invoiceId } = useParams<{
    invoiceId: string;
  }>();
  const [configureRecurring, setConfigureRecurring] = useState(false);
  const group = useCurrentGroupContext();
  const entityId = useCurrentEntityId();

  const { data: invoiceSettings } = useGetInvoiceSettingsQuery(
    {
      groupId: group?.uuid!,
      entityId,
    },
    { skip: !group?.uuid || !entityId }
  );

  const [updateInvoiceRecurring] = useUpdateInvoiceRecurringMutation();
  const invoiceRecurringModal = useModal();
  const { alertToast, successToast } = useToast();

  const { data: invoice, isLoading } = useGetEntityInvoiceQuery(
    {
      groupId: group?.uuid!,
      entityId: entityId!,
      invoiceId,
    },
    { skip: !group?.uuid || !entityId }
  );

  const { validateForm } = useFormikContext();

  useEffect(() => {
    setConfigureRecurring(
      Boolean(invoice?.invoice_settings?.is_recurring_enabled)
    );
  }, [invoice?.invoice_settings]);

  const onRecurringInvoiceAdd = async () => {
    if (group?.uuid && entityId && invoice?.invoice_settings?.uuid) {
      try {
        const payload = {
          is_recurring_enabled: false,
        };
        await updateInvoiceRecurring({
          entityId,
          groupId: group.uuid,
          invoiceId,
          payload,
          invoiceSettingId: invoice?.invoice_settings.uuid,
        }).unwrap();
        successToast({
          message: "Invoice recurring disabled",
        });
      } catch (error: any) {
        alertToast({ message: error?.data?.error?.message });
      }
    }
  };

  const handleToggleUpdate = (value: boolean) => {
    setConfigureRecurring(value);
    if (value) invoiceRecurringModal.open();
    else if (invoice?.invoice_settings?.is_recurring_enabled) {
      onRecurringInvoiceAdd();
    }
  };

  if (!invoice) {
    return null;
  }

  const handleRecurringSettingClose = () => {
    invoiceRecurringModal.close();
    if (!invoice.invoice_settings?.is_recurring_enabled) {
      setConfigureRecurring(false);
    }
  };

  return (
    <div className="t-flex t-flex-col t-gap-2.5">
      <div className="t-flex t-gap-2 t-justify-between t-items-start t-bg-surface-lighter-grey t-px-3 t-py-4 t-rounded-lg">
        <div>
          <span className="t-text-subtitle-sm t-text-text-100">
            Make invoice recurring
          </span>
          {invoice.invoice_settings?.next_recurring_date && (
            <div className="t-text-subtext-sm t-text-text-30">
              Next invoice will be dated{" "}
              {formatDate(invoice.invoice_settings?.next_recurring_date)}
            </div>
          )}
        </div>
        <div className="t-flex t-gap-2 t-items-center">
          {invoice.invoice_settings?.is_recurring_enabled && (
            <Button
              customType="link"
              size="small"
              onClick={invoiceRecurringModal.open}
            >
              Configure
            </Button>
          )}
          <PermissionBasedUI
            align="end"
            side="bottom"
            feature={RECURRING_INVOICE}
            blockedUI={
              <SwitchField
                size="small"
                disabled={!invoice?.invoice_settings?.is_recurring_enabled}
                name="recurringInvoice"
                label={<Padlock />}
                onCheckedChange={handleToggleUpdate}
                checked={configureRecurring}
              />
            }
          >
            <span className="t-mt-1 t-flex">
              <Switch
                checked={configureRecurring}
                size="small"
                name="recurringInvoice"
                onCheckedChange={async (value: boolean) => {
                  const errors = await validateForm();
                  if (Object.entries(errors).length > 0) {
                    return alertToast({
                      message: "Please fill all the required fields.",
                    });
                  }
                  handleToggleUpdate(value);
                }}
              />
            </span>
          </PermissionBasedUI>
        </div>
        {invoiceRecurringModal.isOpen && entityId && (
          <RecurringInvoiceSetting
            isOpen={invoiceRecurringModal.isOpen}
            close={handleRecurringSettingClose}
            invoice={invoice}
            entityId={entityId}
          />
        )}
      </div>

      {invoice.associated_txns && invoice.associated_txns.length > 0 && (
        <div className="t-flex t-gap-2 t-justify-between t-items-start t-bg-surface-lighter-grey t-px-3 t-py-4 t-rounded-lg">
          <span className="t-text-subtitle-sm t-text-text-100">
            Associated transactions
          </span>

          <ToolTip
            text={
              <div
                className="t-min-w-64 t-text-left t-flex t-flex-col t-gap-0.5"
                onClick={(e) => e.stopPropagation()}
              >
                {invoice.associated_txns.map((txn) => (
                  <div className="t-flex t-justify-between" key={txn.uuid}>
                    <div className="t-flex t-justify-between t-items-center">
                      <div>{formatDate(txn.date)}</div>
                    </div>
                    <ConditionalLink
                      target="_blank"
                      to={`/books/transactions/?entity=${invoiceSettings?.entity_id}&company=${group.uuid}&transaction_id=${txn.uuid}`}
                    >
                      <Button customType="link" size="small">
                        <span className="t-text-white t-underline">
                          <AmountSuperScript amount={Number(txn.amount)} />
                        </span>
                      </Button>
                    </ConditionalLink>
                  </div>
                ))}
              </div>
            }
          >
            <Button customType="link" size="small">
              {pluralize(
                invoice.associated_txns.length,
                "transaction",
                "transactions"
              )}
            </Button>
          </ToolTip>
        </div>
      )}
    </div>
  );
};
