import { Button } from "components/DesignSystem/Button/Button";
import Modal from "components/DesignSystem/Modal/Modal";
import {
  FILE_FOLDER_MOVED,
  FILE_FOLDER_MOVE_FAILED,
} from "constants/analyticsEvents";
import { useAnalytics } from "hooks/useAnalytics";
import { useQuery } from "hooks/useQuery";
import { useToast } from "hooks/useToast";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useLazyGetSearchContentQuery } from "store/apis/documents";
import {
  getListAnyFolderContent,
  moveFileLocation,
  multipleFileMove,
} from "../../../apis/documents";
import Prevfolder from "../../../static/images/Prevfolder.svg";
import { DocumentContext } from "../documentContext";

export const MoveFileModal = ({
  moveFileModalShow,
  closeMoveFileModal,
  groupId,
  fileId,
  setNewFolderName,
  setLoad,
  setMovedFolderName,
  onCrossClick,
  groupDocID,
  ParentId,
  isMultipleMove = false,
}) => {
  const { trackEvent } = useAnalytics();
  const query = useQuery();
  const { alertToast, successToast } = useToast();
  const searchTerm = query.get("search");
  const { setSelectedFiles, setCurrentPageNo, setEntityFileAndFolder } =
    useContext(DocumentContext);
  const { docId } = useParams();
  const [folders, setFolders] = useState([]);
  const [payload, setPayload] = useState({});
  const [prevFolderId, setPrevFolderId] = useState("");
  const [prevFolderData, setPrevFolderData] = useState({});
  const [parentId, setParentId] = useState(docId || ParentId);
  const [getSearchContent] = useLazyGetSearchContentQuery();

  const checkDisable = () => {
    if (parentId === docId) return false;
    else return true;
  };

  useEffect(() => {
    setPayload({ destination_folder_id: prevFolderData?.uuid });
  }, [prevFolderData]);

  useEffect(() => {
    getFolders();
  }, [parentId]);

  const getFolders = async () => {
    try {
      const response = await getListAnyFolderContent({
        groupId,
        folderId: parentId,
      });
      setFolders(response.data.data.subfolders);
      setPrevFolderId(
        response.data.data?.parents_list[
          response.data.data?.parents_list?.length - 2
        ]?.uuid
      );
      setPrevFolderData(
        response.data.data?.parents_list[
          response.data.data?.parents_list?.length - 1
        ]
      );
    } catch (error) {
      alertToast({ message: error?.response?.data?.error?.message });
    }
  };

  const folderClicked = (e, uuid) => {
    if (e.detail == 2) {
      setParentId(uuid);
    }
  };

  const moveFile = async () => {
    try {
      const response = await moveFileLocation({ groupId, payload, fileId });
      trackEvent(FILE_FOLDER_MOVED, {
        file_or_folder_ids: [fileId],
        destination_folder_id: payload.destination_folder_id,
      });
      successToast({ message: `Moved to ${response.data.data.parent_name}` });
      setNewFolderName(response.data.data.name);
      if (searchTerm) {
        const {
          files = [],
          folders = [],
          current_page,
        } = await getSearchContent({
          searchTerm,
          groupId,
          pageNum: 1,
        }).unwrap();
        setCurrentPageNo(current_page);

        let updatedMapData = [...folders, ...files].map((ele) => {
          return {
            ...ele,
            isChecked: false,
          };
        });
        setEntityFileAndFolder(updatedMapData);
      } else {
        setLoad((prev) => !prev);
      }
      closeMoveFileModal();
      setSelectedFiles([]);
    } catch (error) {
      trackEvent(FILE_FOLDER_MOVE_FAILED, {
        file_or_folder_ids: [fileId],
        destination_folder_id: payload.destination_folder_id,
        error: error?.response?.data?.error?.message,
      });
      alertToast({ message: error?.response?.data?.error?.message });
    }
  };

  const moveMultiple = async () => {
    try {
      const response = await multipleFileMove({
        groupId,
        destinationFolderId: payload,
        fileIds: fileId,
      });
      trackEvent(FILE_FOLDER_MOVED, {
        file_or_folder_ids: [fileId],
        destination_folder_id: payload.destination_folder_id,
      });
      closeMoveFileModal();
      successToast({ message: `Moved to ${prevFolderData?.name}` });
      if (searchTerm) {
        const { files = [], folders = [] } = await getSearchContent({
          searchTerm,
          groupId,
        }).unwrap();

        let updatedMapData = [...folders, ...files].map((ele) => {
          return {
            ...ele,
            isChecked: false,
          };
        });
        setEntityFileAndFolder(updatedMapData);
      } else {
        let updatedMapData = [
          ...response.data.data.subfolders,
          ...response.data.data.files,
        ].map((ele) => {
          return {
            ...ele,
            isChecked: false,
          };
        });
        setEntityFileAndFolder(updatedMapData);
      }
      setMovedFolderName(prevFolderData?.name);
      setSelectedFiles([]);
    } catch (error) {
      trackEvent(FILE_FOLDER_MOVE_FAILED, {
        file_or_folder_ids: [fileId],
        destination_folder_id: payload.destination_folder_id,
        error: error?.response?.data?.error?.message,
      });
      alertToast({ message: error?.response?.data?.error?.message });
    }
  };

  const goToPrevFolder = () => {
    setParentId(prevFolderId);
  };

  const checkIsBaseFolder = () => {
    if (groupDocID) return groupDocID != parentId;
    else return localStorage.getItem("docId") != parentId;
  };

  return (
    <Modal.Root open={moveFileModalShow} onOpenChange={onCrossClick}>
      <Modal.Content className="moveFolderModal" size="small">
        <Modal.Header>
          <Modal.Title>
            {checkIsBaseFolder() && (
              <img
                src={Prevfolder}
                className="prevFolder"
                onClick={goToPrevFolder}
                alt="back"
              />
            )}
            <span className="currentFolderName">{prevFolderData?.name}</span>
          </Modal.Title>
          <Modal.Close />
        </Modal.Header>
        <Modal.Body>
          {folders.length === 0 ? (
            <span className="emptyMoveFolder">This folder is empty</span>
          ) : (
            folders.map(({ uuid, name }) => (
              <div
                key={uuid}
                onClick={(e) => folderClicked(e, uuid)}
                className="moveFolderList"
              >
                <span className="moveFolder" />
                <span
                  className="eleContent moveToFolderName t-text-body"
                  content={name}
                />
              </div>
            ))
          )}
        </Modal.Body>
        <Modal.FooterButtonGroup>
          <Button
            title="Move Here"
            customType="primary"
            onClick={isMultipleMove ? moveMultiple : moveFile}
            disabled={!checkDisable()}
          >
            Move Here
          </Button>
        </Modal.FooterButtonGroup>
      </Modal.Content>
    </Modal.Root>
  );
};
