import { DashboardLayout } from "components/DashboardLayout";
import { AmountSuperScript } from "components/design/AmountSuperScript";
import { Badge } from "components/design/badge";
import { TableUI } from "components/design/TableUI";
import ToolTip from "components/design/toolTip";
import { Button } from "components/DesignSystem/Button/Button";
import { Header } from "components/DesignSystem/Header/Header";
import { InfoItem } from "components/DesignSystem/InfoItem/InfoItem";
import { Loader } from "components/DesignSystem/Loader/Loader";
import ProgressBar from "components/DesignSystem/ProgressBar/ProgressBar";
import Slider from "components/DesignSystem/Slider/Slider";
import { Tag } from "components/DesignSystem/Tag/Tag";
import { PdfFile } from "components/icons/PdfFile";
import SmallPencil from "components/icons/SmallPencil";
import { EmptyInvoiceList } from "components/Illustrations/EmptyInvoiceList";
import { InvoiceActions } from "components/InvoiceAction/InvoiceAction";
import { InvoiceCustomerModal } from "components/InvoiceCustomerModal/InvoiceCustomerModal";
import { SettingsRedirectConfirmation } from "components/InvoiceList/InvoiceList";
import { InvoiceSlider } from "components/InvoiceList/InvoiceSlider";
import { DocumentPreviewModal } from "components/PreviewModal";
import {
  CLICKED_EDIT_PENCIL_ICON_ON_CUSTOMER_DETAILS_CARD,
  INVOICE_ADD_CLICKED_WITH_CUSTOMER,
} from "constants/analyticsEvents";
import { DD_MMM_YYYY } from "constants/date";
import * as INVOICE_STATUSES from "constants/invoiceStatuses";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { useAnalytics } from "hooks/useAnalytics";
import { useConstructInternalLink } from "hooks/useConstructInternalLink";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useDocPreview } from "hooks/useDocPreview";
import { useModal } from "hooks/useModal";
import {
  ComponentProps,
  MouseEvent,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  Route,
  Switch,
  useHistory,
  useLocation,
  useParams,
  useRouteMatch,
} from "react-router-dom";
import {
  CellContext,
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
} from "react-table-8.10.7";
import RecurringIcon from "static/images/Recurring.svg";
import {
  Invoice,
  useCreateEntityInvoiceMutation,
  useGetAllInvoiceCustomersQuery,
  useGetInvoiceCustomerQuery,
  useGetInvoiceSettingsQuery,
  useUpdateInvoiceMutation,
} from "store/apis/invoices";
import { formatDate } from "utils/formatDate";
import { AddInvoice } from "../Invoicing/AddInvoice";
import { parse, stringify } from "qs";
import * as Progress from "@radix-ui/react-progress";
import classNames from "classnames";
import ConditionalToolTip from "components/design/conditionalToolTip";

dayjs.extend(relativeTime);

const AddInvoiceWithBreadCrumbs = ({
  breadcrumbs,
  url,
}: {
  breadcrumbs: ComponentProps<typeof Header>["breadcrumbs"];
  url: string;
}) => {
  const { customerId } = useParams<{ customerId: string; invoiceId: string }>();
  const group = useCurrentGroupContext();
  const { search } = useLocation();
  const entityId = useCurrentEntityId();

  const { data: allCustomers, isLoading } = useGetAllInvoiceCustomersQuery({
    groupId: group?.uuid!,
    entityId: entityId!,
  });

  const customer = allCustomers?.find((r) => r.uuid === customerId);

  if (isLoading || !customer) {
    return (
      <div className="t-flex t-w-full t-h-full t-justify-center t-items-center">
        <Loader />
      </div>
    );
  }

  return (
    <AddInvoice
      breadcrumbs={[
        ...(breadcrumbs || []),
        {
          name: customer.company_name,
          link: `${url}${search}`,
        },
      ]}
    />
  );
};

const createColumn = createColumnHelper<Invoice>();

const InvoiceNumber = (
  props: CellContext<Invoice, Invoice["invoice_number"]>
) => {
  const openPreview = useDocPreview();
  const { url } = useRouteMatch();
  const docId = props.row.original.document?.file_id;
  const invoiceNumber = props.getValue();
  const history = useHistory();
  const { link } = useConstructInternalLink();

  const onClick = (e: MouseEvent<HTMLButtonElement>) => {
    history.push(link(`${url}/invoice/${props.row.original.uuid}`));
    e.stopPropagation();
  };

  return (
    <>
      <div className="t-flex t-items-center t-gap-1">
        <Button
          customType={invoiceNumber ? "text" : "transparent"}
          onClick={onClick}
        >
          {invoiceNumber || "-"}
        </Button>
        {docId && (
          <Button
            size="small"
            customType="ghost_icon"
            onClick={(e) => {
              e.stopPropagation();
              openPreview(docId);
            }}
          >
            <span className="t-text-text-30">
              <PdfFile />
            </span>
          </Button>
        )}
      </div>
    </>
  );
};

export const InvoiceColumns = [
  createColumn.accessor("invoice_number", {
    header: "Invoice Number",
    id: "CREATED_AT",
    size: 20,
    cell: InvoiceNumber,
    enableSorting: true,
  }),

  createColumn.accessor("invoice_date", {
    header: "Invoice date",
    id: "INVOICE_DATE",
    size: 20,
    cell: (info) => {
      const value = info.getValue();
      const invoiceRecurring = info.row.original.invoice_settings;

      return (
        <div>
          {value ? (
            <div className="t-flex t-gap-2 t-items-center">
              <ToolTip text={dayjs(value).format("MMM DD, YYYY, hh:mm A")}>
                <span className="t-text-body t-font-medium">
                  {dayjs(value).format(DD_MMM_YYYY)}
                </span>
              </ToolTip>
              {invoiceRecurring?.is_recurring_enabled && (
                <ToolTip
                  text={`Next invoice on ${formatDate(
                    invoiceRecurring.next_recurring_date
                  )}`}
                >
                  <span>
                    <img src={RecurringIcon} alt="RecurringIcon" />
                  </span>
                </ToolTip>
              )}
            </div>
          ) : (
            "-"
          )}
        </div>
      );
    },
    enableSorting: true,
  }),

  createColumn.accessor("invoice_amount", {
    header: () => <p className="t-text-right t-m-0">Amount</p>,
    id: "AMOUNT",
    size: 20,
    cell: (info) => (
      <div className="t-text-body t-font-medium">
        <AmountSuperScript amount={Number(info.getValue() || 0)} />
      </div>
    ),
    enableSorting: true,
  }),

  createColumn.accessor("status", {
    header: "Status",
    size: 5,
    cell: (info) => (
      <span className="t-flex t-text-body-sm">
        <Badge
          color={
            {
              [INVOICE_STATUSES.DRAFT]: "neutral",
              [INVOICE_STATUSES.SENT]: "light-purple",
              [INVOICE_STATUSES.PAID]: "light-green",
            }[info.getValue()]
          }
        >
          <span>{info.getValue()}</span>
        </Badge>
      </span>
    ),
    enableSorting: false,
  }),

  createColumn.display({
    id: "Actions",
    header: "",
    size: 5,
    cell: (info) => <InvoiceActions invoice={info.row.original} />,
  }),
];

export const InvoiceCustomerInfo = ({
  breadcrumbs,
}: {
  breadcrumbs: ComponentProps<typeof Header>["breadcrumbs"];
}) => {
  const { url, path } = useRouteMatch();
  const group = useCurrentGroupContext();
  const { customerId: id } = useParams<{ customerId: string }>();
  const [customerId, setCustomerId] = useState<string | null>(null);
  const goToSettings = useModal();
  const { trackEvent } = useAnalytics();
  const entityId = useCurrentEntityId();
  const [invoiceId, setInvoiceId] = useState<string>("");

  const { search } = useLocation();

  const {
    open: openSlider,
    isOpen: isOpenSlider,
    close: closeSlider,
  } = useModal();

  const [createInvoice, { isLoading: creatingInvoice }] =
    useCreateEntityInvoiceMutation();

  const { data: invoiceSetting } = useGetInvoiceSettingsQuery(
    {
      groupId: group?.uuid!,
      entityId,
    },
    { skip: !group?.uuid || !entityId }
  );

  const [sorting, setSorting] = useState<
    {
      desc: boolean;
      id: "CREATED_AT" | "INVOICE_DATE" | "AMOUNT";
    }[]
  >([]);

  const sortCol = sorting[0]?.id;
  const sortOrder = sorting[0]?.desc;
  const { data: customer } = useGetInvoiceCustomerQuery(
    {
      customerId: id,
      groupId: group?.uuid!,
      entityId: entityId!,
      sortCol: sortCol,
      sortOrder: sortOrder ? ("DSC" as const) : ("ASC" as const),
    },
    {
      skip: !group?.uuid || !entityId || !id,
    }
  );

  const tableData = useMemo(() => customer?.invoices, [customer?.invoices]);
  const [updateInvoice, { isLoading: updatingInvoice }] =
    useUpdateInvoiceMutation();

  const table = useReactTable({
    data: tableData || [],
    columns: InvoiceColumns,
    getCoreRowModel: getCoreRowModel(),
    defaultColumn: {
      minSize: 5,
    },
    manualSorting: true,
    enableMultiSort: false,
    enableSortingRemoval: false,
    // @ts-ignore
    onSortingChange: setSorting,
    state: {
      sorting,
    },
  });

  const history = useHistory();
  const { link } = useConstructInternalLink();

  const onCreateInvoice = async () => {
    // trackEvent(CLICKED_NEW_INVOICE_INSIDE_CUSTOMER_VIEW);
    if (!invoiceSetting) {
      goToSettings.open();
      return;
    }

    trackEvent(INVOICE_ADD_CLICKED_WITH_CUSTOMER);

    if (group.uuid && invoiceSetting?.entity_id) {
      try {
        const invoice = await createInvoice({
          groupId: group?.uuid,
          entityId: invoiceSetting?.entity_id,
        }).unwrap();

        const updatedInvoice = await updateInvoice({
          groupId: group.uuid,
          entityId: invoiceSetting.entity_id,
          invoiceId: invoice.uuid,
          payload: {
            customer_id: customer?.uuid,
          },
        }).unwrap();

        history.push(link(`/books/invoicing/${updatedInvoice.uuid}`));
      } catch (error) {}
    }
  };

  const handleCustomerEdit = () => {
    setCustomerId(id);
    trackEvent(CLICKED_EDIT_PENCIL_ICON_ON_CUSTOMER_DETAILS_CARD);
  };

  const isEditable = customer?.source === "MANUAL";
  const isStripeCustomer = customer?.source === "STRIPE";

  if (!customer) {
    return (
      <div className="t-flex t-h-full t-w-full t-justify-center t-items-center">
        <Loader />
      </div>
    );
  }

  return (
    <Switch>
      <Route exact path={`${path}/invoice/:invoiceId`}>
        <AddInvoiceWithBreadCrumbs url={url} breadcrumbs={breadcrumbs} />
      </Route>
      <Route path={path}>
        <DashboardLayout
          header={
            <Header
              title={customer.name}
              v2
              breadcrumbs={[
                ...(breadcrumbs || []),
                { name: customer.name, link: "" },
              ]}
            />
          }
        >
          <div className="t-flex t-flex-col t-gap-6 t-relative">
            <div className="t-flex t-gap-2">
              <div className="t-border t-border-solid t-border-neutral-0 t-p-5 t-flex-1 t-w-1/2 t-relative t-rounded-lg">
                <div className="t-flex t-flex-col t-gap-4">
                  <div className="t-flex t-gap-2 t-justify-between">
                    <InfoItem label="Email">{customer.email}</InfoItem>
                    <div className="t-absolute t-top-0 t-right-0">
                      <ConditionalToolTip
                        condition={!isEditable && "Cannot edit Stripe customer"}
                      >
                        <span>
                          <Button
                            customType="ghost_icon"
                            onClick={handleCustomerEdit}
                            disabled={!isEditable}
                          >
                            <span
                              className={classNames("t-flex", {
                                "t-text-blue-70": isEditable,
                                "t-text-neutral-20": !isEditable,
                              })}
                            >
                              <SmallPencil color="currentColor" />
                            </span>
                          </Button>
                        </span>
                      </ConditionalToolTip>
                    </div>
                  </div>
                  <InfoItem label="Address">
                    {customer.billing_address ? (
                      <span>{customer.billing_address}</span>
                    ) : (
                      "-"
                    )}
                  </InfoItem>
                  <InfoItem label="Reminders">
                    {customer.is_reminders_enabled ? "On" : "Off"}
                  </InfoItem>
                </div>
              </div>
              {!isStripeCustomer && (
                <div className="t-border t-border-solid t-border-neutral-0 t-p-5 t-flex-1 t-w-1/2 t-rounded-lg">
                  <div className="t-gap-y-6 t-flex-col t-flex">
                    <InfoItem label="Total invoice amount">
                      <span className="t-text-h6">
                        <AmountSuperScript amount={customer.total_amount} />
                      </span>
                    </InfoItem>

                    <ProgressBar.Root
                      type="green"
                      disabled={customer.total_amount <= 0}
                      className="t-h-4 t-flex t-items-center t-rounded-full"
                    >
                      <ProgressBar.Indicator
                        progress={customer.percent_paid}
                        className="t-rounded-full t-flex t-items-center"
                        style={{ width: `${customer.percent_paid}%` }}
                      >
                        <div className=" t-w-full t-flex t-justify-center t-text-body-xs t-items-center t-text-neutral-0">
                          <span>{customer.percent_paid.toFixed(0)}%</span>
                        </div>
                      </ProgressBar.Indicator>
                      <div className=" t-flex t-justify-center t-w-full t-text-body-xs t-text-neutral-40">
                        <span>{(100 - customer.percent_paid).toFixed(0)}%</span>
                      </div>
                    </ProgressBar.Root>

                    {customer.total_amount > 0 && (
                      <div className="t-flex t-gap-9">
                        <div className="t-flex t-gap-1 t-flex-col">
                          <Tag transparent tagType="green">
                            <span className="t-text-text-30">Paid</span>
                          </Tag>
                          <div className="t-text-subtext t-text-text-60 t-ml-3.5">
                            <AmountSuperScript amount={customer.paid_amount} />
                          </div>
                        </div>

                        <div className="t-flex t-gap-1 t-flex-col">
                          <Tag transparent tagType="light_grey">
                            <span className="t-text-text-30">Due</span>
                          </Tag>
                          <div className="t-text-subtext t-text-text-60 t-ml-3.5">
                            <AmountSuperScript amount={customer.due_amount} />
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>

            <div className="t-flex t-flex-col t-gap-3">
              <div className="t-flex t-justify-between">
                <p className="t-m-0 t-text-subtitle">Invoices</p>
                {!isStripeCustomer && (
                  <Button
                    customType="primary"
                    onClick={onCreateInvoice}
                    size="small"
                    isLoading={creatingInvoice || updatingInvoice}
                    disabled={creatingInvoice || updatingInvoice}
                  >
                    New invoice
                  </Button>
                )}
              </div>
              {isStripeCustomer ? (
                <div className="t-flex t-flex-col t-items-center t-justify-center t-h-80 t-text-text-60 t-gap-3">
                  <EmptyInvoiceList />
                  <div className="t-flex t-flex-col t-gap-2 t-justify-center t-items-center">
                    <p className="t-m-0 t-text-subtitle-sm">Stripe Invoices</p>
                    <p className="t-m-0 t-text-body-sm">Coming soon...</p>
                  </div>
                </div>
              ) : (
                <>
                  <div className="t-text-text-60">
                    <TableUI
                      table={table}
                      onRowClick={(row) => {
                        setInvoiceId(row.original.uuid);
                        openSlider();
                      }}
                      enableSort
                    />
                    <Slider.Root open={isOpenSlider} onOpenChange={closeSlider}>
                      <Slider.Content open={isOpenSlider}>
                        <InvoiceSlider
                          invoiceId={invoiceId}
                          redirectLink={`${url}/invoice/${invoiceId}${search}`}
                        />
                      </Slider.Content>
                    </Slider.Root>
                  </div>
                  {customer.invoices.length === 0 && customer && (
                    <div className="t-flex t-flex-col t-items-center t-justify-center t-h-72 t-text-text-60 t-gap-3">
                      <EmptyInvoiceList />
                      <div className="t-flex t-flex-col t-gap-2 t-justify-center t-items-center">
                        <p className="t-m-0 t-text-subtitle-sm">
                          Create your first invoice for {customer.name}
                        </p>
                        <p className="t-m-0 t-text-body-sm">
                          Send customised invoices to {customer.name}.
                        </p>
                      </div>
                      <Button
                        onClick={onCreateInvoice}
                        isLoading={creatingInvoice || updatingInvoice}
                        disabled={creatingInvoice || updatingInvoice}
                        size="small"
                        customType="primary"
                      >
                        Create invoice
                      </Button>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
          <InvoiceCustomerModal
            customerId={customerId}
            setCustomerId={setCustomerId}
          />
          <SettingsRedirectConfirmation
            isOpen={goToSettings.isOpen}
            close={goToSettings.close}
            url={`/books/invoicing/settings${search}`}
          />
        </DashboardLayout>
        <DocumentPreviewModal groupId={group.uuid} />
      </Route>
    </Switch>
  );
};
