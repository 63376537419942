import { CrmTableDropdown } from "components/CrmTableDropdown/CrmTableDropdown";
import Loader from "components/design/loader";
import { SearchBox } from "components/design/searchBox";
import { Button } from "components/DesignSystem/Button/Button";
import Table from "components/DesignSystem/Table/Table";
import PaginatedItems from "components/pagination/paginate";
import { useQuery, useUpdateQuery } from "hooks/useQuery";
import { useToast } from "hooks/useToast";
import { useMemo, useState } from "react";
import { useTable } from "react-table";
import CrossRed from "static/images/CrossRed.svg";
import GreenTick from "static/images/GreenTick.svg";
import {
  Profile,
  useApproveUserProfileMutation,
  useAssignSalesPersonMutation,
  useGetAllPreGroupProfilesQuery,
  useRejectUserProfileMutation,
} from "store/apis/salesCrmProfile";
import { debounce } from "utils/debouncing";
import AddCrmEntityTableModal from "./AddCrmEntityTableModal";
import NewGroupCreationModal from "./NewGroupEntityModal";
import { ApproveModal, RejectModal } from "./StatusModal";
import {
  Combobox,
  OptionData,
} from "components/DesignSystem/Combobox/Combobox";
import { useGetAssignAgentsQuery } from "store/apis/agents";
import { agentsHandlers } from "store/__mockHandlers__/agents";
import { Agents } from "types/Models/agents";
import { MultiValue, SingleValue } from "react-select";
import {
  CellContext,
  createColumn,
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
} from "react-table-8.10.7";
import { TableUI } from "components/design/TableUI";
import ConditionalToolTip from "components/design/conditionalToolTip";

const SalesCrmProfiles = () => {
  const { alertToast, successToast } = useToast();
  const { update } = useUpdateQuery();
  const query = useQuery();
  const searchTerm = query.get("search_term") || null;
  const [pageNumber, setPageNumber] = useState(1);
  const [showModal, setShowModal] = useState(false);
  const [showRejectModal, setShowRejectModal] = useState(false);
  const [approveUserProfileId, setApproveUserProfileId] = useState("");
  const [showGroupCreationModal, setShowGroupCreationModal] = useState(false);
  const [showEntityModal, setShowEntityModal] = useState(false);
  const [userDataPayload, setUserDataPayLoad] = useState<any>();
  const [groupName, setGroupName] = useState<string>("");

  const {
    data: preGroupProfiles,
    isSuccess,
    isFetching,
  } = useGetAllPreGroupProfilesQuery({
    pageNumber,
    searchTerm,
  });

  const { profiles, total_pages } = preGroupProfiles || {};

  const [approveuserProfile, { isLoading: isApproveBtnLoading }] =
    useApproveUserProfileMutation();

  const [rejectUserProfile, { isLoading: isRejectBtnLoading }] =
    useRejectUserProfileMutation();

  const [assignSalesPerson, { isLoading: isSalesPersonLoading }] =
    useAssignSalesPersonMutation();

  const search = debounce((e: any) => {
    const { value } = e.target;
    if (value) {
      update({ query: "search_term", value: value });
    } else {
      update({ query: "search_term", value: null });
    }
  });

  const approveHandler = (profileId: string) => {
    setShowModal(true);
    setApproveUserProfileId(profileId);
  };

  const rejectHandler = (profileId: string) => {
    setShowRejectModal(true);
    setApproveUserProfileId(profileId);
  };

  const handleApprove = async () => {
    try {
      await approveuserProfile(approveUserProfileId).unwrap();
    } catch (e: any) {
      alertToast({ message: e?.data?.error?.message });
    }
    setShowModal(false);
  };

  const rejectUser = async () => {
    try {
      await rejectUserProfile(approveUserProfileId).unwrap();
    } catch (e: any) {
      alertToast({ message: e?.data?.error?.message });
    }
    setShowRejectModal(false);
  };

  const newGroupCreationHandler = ({
    first_name,
    last_name,
    email,
    linkedin,
  }: {
    first_name: string;
    last_name: string;
    email: string;
    linkedin?: string;
  }) => {
    setUserDataPayLoad({
      first_name,
      last_name,
      email,
      linkedin,
    });
    setShowGroupCreationModal(true);
  };

  const SalesPersonDropdown = ({
    agent,
    profileId,
  }: {
    agent: Agents | null;
    profileId: string | undefined;
  }) => {
    const onAgentChange = async (
      values: SingleValue<OptionData> | MultiValue<OptionData>
    ) => {
      // implement api for assigning and then refetch
      if (values && !(values instanceof Array) && profileId) {
        const { value: agentProfileId } = values;
        try {
          await assignSalesPerson({
            profileId,
            agent_profile_id: agentProfileId,
          }).unwrap();
          successToast({ message: "Salesperson has been assigned" });
        } catch (error: any) {
          alertToast({ message: error?.data?.error?.message });
        }
      }
    };
    const { data: agentOptions = [] } = useGetAssignAgentsQuery({
      agentType: "SALES_EXECUTIVE",
    });
    const selectedSalesPerson = agentOptions.find(
      (agentOption) => agentOption.profile_id == agent?.profile_id
    );
    return (
      <>
        <Combobox
          components={{
            ClearIndicator: () => null,
            LoadingIndicator: () => <Loader size="small" />,
          }}
          menuPortalTarget={document.body}
          onChange={onAgentChange}
          options={agentOptions.map((agent) => ({
            label: agent.name,
            value: agent.profile_id,
          }))}
          value={
            selectedSalesPerson
              ? {
                  label: selectedSalesPerson.name,
                  value: selectedSalesPerson.profile_id,
                }
              : null
          }
          isLoading={isSalesPersonLoading}
        />
      </>
    );
  };
  const createColumn = createColumnHelper<Profile>();
  const columns = useMemo(
    () => [
      createColumn.accessor("first_name", {
        header: "Name",
        size: 18,
        cell: ({ row }: any) =>
          `${row.original?.first_name + " " + row.original?.last_name}`,
      }),
      createColumn.accessor("email", {
        header: "Email",
        size: 14,
      }),
      createColumn.accessor("is_email_verified", {
        header: "Email OTP",
        size: 8,
        cell: (info) => (
          <>
            {info.getValue() ? (
              <img src={GreenTick} alt="GreenTick" />
            ) : (
              <img src={CrossRed} alt="CrossRed" />
            )}
          </>
        ),
      }),
      createColumn.accessor("is_mobile_verified", {
        header: "Mobile OTP",
        size: 10,
        cell: (info) => (
          <>
            {info.getValue() ? (
              <img src={GreenTick} alt="GreenTick" />
            ) : (
              <img src={CrossRed} alt="CrossRed" />
            )}
          </>
        ),
      }),
      createColumn.accessor("salesperson", {
        header: "Sales person",
        size: 10,
        cell: (info: CellContext<Profile, Agents>) => {
          return (
            <SalesPersonDropdown
              agent={info.row.original.salesperson}
              profileId={info.row.original.uuid}
            />
          );
        },
      }),
      createColumn.accessor("is_restricted", {
        header: "Status",
        size: 19,
        cell: ({ row }: any) => {
          if (
            !row.original?.is_email_verified &&
            !row.original?.is_mobile_verified
          ) {
            return <div>-</div>;
          }
          if (row.original?.is_rejected) {
            return (
              <div
                className="t-flex t-h-10 t-w-24 t-items-center t-justify-center
        t-rounded t-bg-red-10 t-p-2 t-text-button t-text-red"
              >
                Rejected
              </div>
            );
          }
          if (row.original?.is_restricted) {
            return (
              <ConditionalToolTip
                condition={
                  !row.original?.salesperson &&
                  "Please assign a salesperson first"
                }
              >
                <div className="t-flex t-gap-2">
                  <Button
                    onClick={() => approveHandler(row.original?.uuid)}
                    disabled={!row.original.salesperson}
                  >
                    Approve
                  </Button>
                  <Button
                    customType="secondary"
                    onClick={() => {
                      rejectHandler(row.original?.uuid);
                    }}
                    disabled={!row.original.salesperson}
                  >
                    Reject
                  </Button>
                </div>
              </ConditionalToolTip>
            );
          }
          if (!row.original?.is_restricted && !row.original?.is_rejected) {
            return (
              <div
                className="t-flex t-h-10 t-w-24 t-items-center t-justify-center
                t-rounded t-bg-green-10 t-p-2 t-text-button t-text-dark_green"
              >
                Approved
              </div>
            );
          }
        },
      }),
      createColumn.accessor("group", {
        header: "Group",
        size: 19,
        cell: ({ row }: any) => {
          const { first_name, last_name, email, linkedin } = row.original;
          return (
            <>
              {row.original?.group?.name ? (
                <div>{row.original?.group?.name}</div>
              ) : (
                <div>
                  {row.original?.is_rejected ? (
                    "-"
                  ) : (
                    <Button
                      customType="primary"
                      size="small"
                      onClick={() =>
                        newGroupCreationHandler({
                          first_name,
                          last_name,
                          email,
                          linkedin,
                        })
                      }
                    >
                      + New group
                    </Button>
                  )}
                </div>
              )}
            </>
          );
        },
      }),
      createColumn.accessor("uuid", {
        header: "",
        id: "threedots",
        size: 2,
        cell: ({ row }: any) => {
          const { uuid, name } = row.original;
          return <CrmTableDropdown actionOn="profile" uuid={uuid} />;
        },
      }),
    ],
    []
  );

  const data = useMemo(() => profiles || [], [profiles]);

  const table = useReactTable({
    data: data || [],
    columns: columns,
    getCoreRowModel: getCoreRowModel(),
    defaultColumn: {
      minSize: 1,
      maxSize: 100,
    },
  });

  return (
    <>
      <div className="t-w-full">
        <div className="t-flex t-py-3 t-pl-0 t-pr-5">
          <span className="searchTable">
            <SearchBox
              value={searchTerm}
              onChange={search}
              placeholder="Search user"
            />
          </span>
        </div>
        {isFetching && !isSuccess ? (
          <div className="t-w-full">
            <Loader />
          </div>
        ) : (
          <>
            <ApproveModal
              showModal={showModal}
              setShowModal={setShowModal}
              approveUserProfileId={approveUserProfileId}
            />
            <RejectModal
              showRejectModal={showRejectModal}
              setShowRejectModal={setShowRejectModal}
              approveUserProfileId={approveUserProfileId}
            />
            <TableUI table={table} />
            <NewGroupCreationModal
              showModal={showGroupCreationModal}
              setShowModal={setShowGroupCreationModal}
              setShowEntityModal={setShowEntityModal}
              setUserDataPayLoad={setUserDataPayLoad}
              userDataPayload={userDataPayload}
              setGroupName={setGroupName}
              fromProfiles
            />
            <AddCrmEntityTableModal
              setShowGroupModal={setShowGroupCreationModal}
              showModal={showEntityModal}
              setModalShow={setShowEntityModal}
              userDataPayload={userDataPayload}
              groupName={groupName}
            />
          </>
        )}
      </div>
    </>
  );
};

export default SalesCrmProfiles;
