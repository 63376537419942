import { Button } from "components/DesignSystem/Button/Button";
import Modal from "components/DesignSystem/Modal/Modal";
import { Form, Formik } from "formik";
import { splitTransactionsSchema } from "formValidations/splitTransactionSchema";
import { useToast } from "hooks/useToast";
import { useMemo } from "react";
import { InvoiceType, Transactions } from "types/Models/books";
import { FileObject } from "types/Models/fileObject";
import { BackendError } from "types/utils/error";
import { SplitTransactionTable } from "./SplitTransactionTable";
import ConditionalToolTip from "components/design/conditionalToolTip";
import {
  DELETE_SPLIT_TRANSACTION,
  EDIT_SPLIT_TRANSACTION,
} from "constants/transactionStates";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/store";
import {
  closeSplitTransactionModal,
  setActionOnSplitTxns,
} from "store/slices/splitTransaction";
import {
  useDeleteSplitTransactionsMutation,
  useGetAllSplitTransactionsQuery,
  useSplitTransactionsMutation,
  useUpdateSplitTransactionsMutation,
} from "store/apis/transactions";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import Loader from "components/design/loader";
import { roundToFixedDecimal } from "utils/roundtoFixedDecimal";

export type TransactionForSplit = {
  id: number;
  merchant?: string | null;
  category?: string;
  description: string;
  amount: number;
  invoice: InvoiceType | null;
  uuid: string;
};

export type SplitTransactionActionType =
  | typeof EDIT_SPLIT_TRANSACTION
  | typeof DELETE_SPLIT_TRANSACTION
  | "";

type SplitTransactionProps = {
  isOpen: boolean;
};

export const SplitTransaction = ({ isOpen }: SplitTransactionProps) => {
  const { alertToast, successToast } = useToast();
  const { uuid: groupId } = useCurrentGroupContext();
  const entityId = useCurrentEntityId();
  const { actionOnSplitTxns, transaction } = useSelector(
    (state: RootState) => state.splitTransaction
  );
  const transactionId = (transaction as Transactions).transaction.uuid;
  const [splitTransactions, { isLoading: isSplitting }] =
    useSplitTransactionsMutation();
  const [deleteSplitTransaction, { isLoading: isDeleting }] =
    useDeleteSplitTransactionsMutation();
  const [updateSplitTransaction, { isLoading: isUpdating }] =
    useUpdateSplitTransactionsMutation();
  const { data: splitTransactionData, isLoading } =
    useGetAllSplitTransactionsQuery(
      {
        groupId,
        entityId,
        transactionId,
      },
      {
        skip: !groupId || !entityId || !transactionId,
        refetchOnMountOrArgChange: true,
      }
    );

  const dispatch = useDispatch();
  const actionToTitleMap = {
    [EDIT_SPLIT_TRANSACTION]: "Edit Split Transaction",
    [DELETE_SPLIT_TRANSACTION]: "Delete Split Transaction",
    "": "Split Transaction",
  };
  const modalTitle = actionToTitleMap[actionOnSplitTxns];
  const isDeleteSplitTxnsFlow =
    actionOnSplitTxns === "DELETE_SPLIT_TRANSACTION";
  const isEditSplitTxnsFlow = actionOnSplitTxns === "EDIT_SPLIT_TRANSACTION";

  const handleSubmit = ({
    transactions,
  }: {
    transactions: TransactionForSplit[];
  }) => {
    if (isDeleteSplitTxnsFlow) {
      deleteTxns();
      return;
    }
    if (isEditSplitTxnsFlow) {
      updateTransaction({ transactions });
      return;
    }
    addTransaction({ transactions });
  };

  const addTransaction = async ({
    transactions,
  }: {
    transactions: TransactionForSplit[];
  }) => {
    try {
      const transactionList = transactions
        .filter(({ description }) => Boolean(description))
        .map(({ amount, category, description, invoice, merchant }) => ({
          amount,
          description,
          invoice_id: invoice?.uuid || null,
          merchant_id: merchant,
          category_id: category!, // ! safe because of form validation
        }));

      await splitTransactions({
        groupId,
        entityId,
        transactionId,
        transactions: transactionList,
      }).unwrap();

      successToast({ message: "Transaction split successfully." });
      dispatch(closeSplitTransactionModal());
    } catch (error) {
      alertToast({
        message: (error as BackendError)?.data?.error?.message,
      });
    }
  };

  const updateTransaction = async ({
    transactions,
  }: {
    transactions: TransactionForSplit[];
  }) => {
    try {
      const transactionList = transactions
        .filter(({ description }) => Boolean(description))
        .map(({ amount, category, description, invoice, merchant, uuid }) => ({
          amount,
          description,
          invoice_id: invoice?.uuid || null,
          merchant_id: merchant,
          category_id: category!, // ! safe because of form validation
          uuid,
        }));

      await updateSplitTransaction({
        groupId,
        entityId,
        transactionId,
        transactions: transactionList,
      }).unwrap();
      successToast({ message: "Split transaction has been updated!" });
      dispatch(closeSplitTransactionModal());
    } catch (error) {
      alertToast({
        message: (error as BackendError)?.data?.error?.message,
      });
    }
  };

  const deleteTxns = async () => {
    try {
      await deleteSplitTransaction({
        groupId,
        entityId,
        transactionId,
      }).unwrap();
      successToast({ message: "Split transaction has been deleted!" });
      dispatch(closeSplitTransactionModal());
    } catch (error) {
      alertToast({
        message: (error as BackendError)?.data?.error?.message,
      });
    }
  };

  const initialTransactions = useMemo(
    () =>
      splitTransactionData && splitTransactionData.length > 0
        ? splitTransactionData.map((transaction, i) => ({
            id: i + 1,
            merchant: transaction.merchant_uuid,
            category: transaction.category?.uuid,
            description: transaction.description,
            amount: transaction.amount,
            invoice: transaction.invoice,
            uuid: transaction.uuid,
          }))
        : Array.from({ length: 2 }).map((arr, i) => ({
            id: i + 1,
            merchant: "",
            category: "",
            description: "",
            amount: 0,
            invoice: null,
            uuid: "",
          })),
    [splitTransactionData]
  );

  return (
    <Modal.Root
      open={isOpen}
      onOpenChange={() => dispatch(closeSplitTransactionModal())}
    >
      <Formik
        initialValues={{
          transactions: initialTransactions,
        }}
        onSubmit={handleSubmit}
        enableReinitialize
        validateOnChange
        validationSchema={splitTransactionsSchema}
        validateOnMount
      >
        {({
          submitForm,
          values: { transactions },
          isValid,
          resetForm,
          dirty,
        }) => {
          const totalAmount = transactions.reduce(
            (total, transaction) => total + transaction.amount,
            0
          );
          const amountDifference = roundToFixedDecimal({
            numberToRound:
              (transaction as Transactions).transaction?.amount - totalAmount,
          });
          return (
            <Form className="t-m-0 t-w-full">
              <Modal.Content
                size="xxxl"
                className="t-z-tooltip!"
                useCustomOverlay
              >
                <Modal.Header>
                  <Modal.Title>{modalTitle}</Modal.Title>
                  <Modal.Close />
                </Modal.Header>
                <Modal.Body className="t-pb-0">
                  {isLoading ? (
                    <Loader />
                  ) : (
                    <SplitTransactionTable
                      transaction={transaction}
                      isDeleteSplitTxnsFlow={isDeleteSplitTxnsFlow}
                      totalAmount={totalAmount}
                      amountDifference={amountDifference}
                    />
                  )}
                </Modal.Body>
                {isDeleteSplitTxnsFlow ? (
                  <Modal.FooterButtonGroup>
                    <Button
                      onClick={() =>
                        dispatch(setActionOnSplitTxns(EDIT_SPLIT_TRANSACTION))
                      }
                      type="reset"
                    >
                      Edit
                    </Button>
                    <Button
                      customType="danger"
                      type="submit"
                      onClick={submitForm}
                      isLoading={isDeleting}
                      disabled={isDeleting}
                    >
                      Confirm delete
                    </Button>
                  </Modal.FooterButtonGroup>
                ) : (
                  <Modal.FooterButtonGroup>
                    <Button
                      onClick={() => {
                        resetForm();
                        dispatch(closeSplitTransactionModal());
                      }}
                      type="reset"
                    >
                      Cancel
                    </Button>
                    <ConditionalToolTip
                      condition={
                        isValid
                          ? ""
                          : "Please enter all mandatory details to continue"
                      }
                    >
                      <span>
                        <Button
                          customType="primary"
                          type="submit"
                          onClick={submitForm}
                          disabled={
                            isSplitting ||
                            isUpdating ||
                            !isValid ||
                            amountDifference !== 0 ||
                            !dirty
                          }
                          isLoading={isSplitting || isUpdating}
                        >
                          Confirm
                        </Button>
                      </span>
                    </ConditionalToolTip>
                  </Modal.FooterButtonGroup>
                )}
              </Modal.Content>
            </Form>
          );
        }}
      </Formik>
    </Modal.Root>
  );
};
