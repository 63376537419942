import * as BILLING_CYCLES from "constants/billingCycles";
import {
  ADMIN_APPROVED,
  INITIATED,
  NOT_INITIATED,
  REJECTED_REQUEST,
} from "./constants/groupRequest";

import AmEx from "static/images/Amex.svg";
import DefaultBankLogo from "static/images/DefaultBankLogo.svg";
import Discover from "static/images/Discover.svg";
import JCB from "static/images/JCB.svg";
import MasterCard from "static/images/Mastercard.svg";
import UnionPay from "static/images/UnionPay.svg";
import Visa from "static/images/Visa.svg";
import * as INVOICE_STATUSES from "constants/invoiceStatuses";

import {
  FILING_IN_PROGRESS_TYPE,
  FORM_FILING_TYPE,
  REVIEW_TYPE,
  SUBMITTED_TYPE,
  UPLOAD_DOCUMENTS_TYPE,
} from "./constants/taskStates";
import { NOT_STARTED } from "./constants/taskStatus";

import {
  ASSET,
  BALANCE_SHEET,
  CASH_FLOW_STATEMENT,
  EQUITY,
  EXPENSE,
  INCOME_STATEMENT,
  LIABILITY,
  REVENUE,
  SAVED_REPORTS,
} from "constants/bookkeeping";
import * as BOOKKEEPIN_SUMMAY_STATUSES from "constants/bookkeepingMonthlySummaryStatus";
import {
  REPLY_REQUEST_TXN_INVOICE,
  REPLY_TXN_CLARIFICATION_CARD,
  REQUEST_TXN_INVOICE,
  TXN_CLARIFICATION_CARD,
} from "constants/messageTypes";
import {
  INVITED,
  PENDING,
  SENT,
  SIGNED_UP,
  SUBSCRIBED,
} from "constants/referral";
import {
  BYOA,
  FREE_PLAN,
  PAYG,
  PRO,
  PRO_PLAN,
  STANDARD,
  STANDARD_PLAN,
  FREE,
  UNSUBSCRIBED,
} from "constants/subscriptionType";
import { ALL, ARCHIVED, COMPLETED, ONGOING } from "constants/task";
import * as TAX_STATUSES from "constants/tax";
import { OVERDUE } from "constants/complianceMonthStatuses";
import { HOLIDAY, SLA, WEEKEND } from "constants/chatSettingBannerTypes";

type CardType = {
  [key: string]: string;
  discover: string;
  mastercard: string;
  amex: string;
  jcb: string;
  unionpay: string;
  visa: string;
  default: string;
};

const NOT_STARTED_CONST = NOT_STARTED;

export let TASK_STATE_TO_COLOR = {
  [UPLOAD_DOCUMENTS_TYPE]: "yellow",
  [SUBMITTED_TYPE]: "green",
  [FORM_FILING_TYPE]: "red",
  [FILING_IN_PROGRESS_TYPE]: "yellow",
  [REVIEW_TYPE]: "yellow",
  [NOT_STARTED_CONST]: "red",
  [ARCHIVED]: "neutral",
};

export let GROUP_REQUEST_STATE_TEXT = {
  [INITIATED]: "Requested!",
  [REJECTED_REQUEST]: "Admin Rejected!",
  [NOT_INITIATED]: "Join",
  [ADMIN_APPROVED]: "Joined!",
};

export const REPLY_MESSAGE_TYPE = {
  [TXN_CLARIFICATION_CARD]: REPLY_TXN_CLARIFICATION_CARD,
  [REQUEST_TXN_INVOICE]: REPLY_REQUEST_TXN_INVOICE,
  [REPLY_TXN_CLARIFICATION_CARD]: REPLY_TXN_CLARIFICATION_CARD,
  [REPLY_REQUEST_TXN_INVOICE]: REPLY_REQUEST_TXN_INVOICE,
};

export const SUBSCRIPTION_TYPE = {
  [STANDARD_PLAN]: "purple",
  [PRO_PLAN]: "orange",
  [FREE_PLAN]: "neutral",
};

export const CRM_SUBSCRIPTION_TYPE = {
  [STANDARD]: "purple",
  [PRO]: "orange",
  [PAYG]: "light-green",
  [BYOA]: "blue70",
};

export const REFERRAL_STATUS = {
  [INVITED]: "ongoing",
  [SIGNED_UP]: "ongoing",
  [SUBSCRIBED]: "completed",
};

export const REWARD_STATUS = {
  [PENDING]: "ongoing",
  [SENT]: "completed",
};

export const BOOKS_STATUS = {
  [BOOKKEEPIN_SUMMAY_STATUSES.NOT_STARTED]: "Upcoming",
  [BOOKKEEPIN_SUMMAY_STATUSES.COMPLETED]: "Done",
  [BOOKKEEPIN_SUMMAY_STATUSES.IN_PROGRESS]: "Ongoing",
  [BOOKKEEPIN_SUMMAY_STATUSES.IN_CLIENT_REVIEW]: "Review Pending",
  [BOOKKEEPIN_SUMMAY_STATUSES.IN_CPA_REVIEW]: "Review Pending",
  [BOOKKEEPIN_SUMMAY_STATUSES.BANK_CONNECT]: "No accounts connected",
  [BOOKKEEPIN_SUMMAY_STATUSES.NOT_APPLICABLE]: "Not applicable",
  [BOOKKEEPIN_SUMMAY_STATUSES.SEASON]:
    "Choose what timeline you'd like us to follow for your books",
  [BOOKKEEPIN_SUMMAY_STATUSES.PLATFORM]:
    "Choose and add our bookkeepers to your preferred platform",
};

export const BOOKS_SUBSCRIPTION = {
  ["STANDARD"]: "Standard",
  ["PRO"]: "Pro",
  ["PAYG"]: "PAYG",
  ["BYOA"]: "BYOA",
};
export const BOOKS_SUBSCRIPTION_VALUES = {
  ["STANDARD"]: "Standard",
  ["PRO"]: "Pro",
  ["PAYG"]: "PAYG",
  ["BYOA"]: "BYOA",
};

export const BOOKS_STATUS_TYPE = {
  [BOOKKEEPIN_SUMMAY_STATUSES.NOT_STARTED]: "PROCESSING",
  [BOOKKEEPIN_SUMMAY_STATUSES.COMPLETED]: "COMPLETED",
  [BOOKKEEPIN_SUMMAY_STATUSES.IN_PROGRESS]: "FORM_FILING",
  [BOOKKEEPIN_SUMMAY_STATUSES.IN_CLIENT_REVIEW]: "ACTION_REQUIRED",
  [BOOKKEEPIN_SUMMAY_STATUSES.IN_CPA_REVIEW]: "ACTION_REQUIRED",
  [BOOKKEEPIN_SUMMAY_STATUSES.NOT_APPLICABLE]: "PROCESSING",
};

export const TASK_LIST_FILTER_TYPES = {
  [ONGOING]: { showOngoing: true },
  [COMPLETED]: { showFinished: true },
  [ARCHIVED]: { showArchive: true },
  [ALL]: {
    showArchive: true,
    showOngoing: true,
    showFinished: true,
  },
};

export const SERVICE_LIST_FILTER_TYPES = {
  [ONGOING]: { showOngoing: true },
  [COMPLETED]: { showFinished: true },
  [ALL]: {
    showOngoing: true,
    showFinished: true,
  },
};

export const BOOKS_SEASON_SUMMARY_STATUS = {
  [BOOKKEEPIN_SUMMAY_STATUSES.NOT_STARTED]: "neutral",
  [BOOKKEEPIN_SUMMAY_STATUSES.IN_PROGRESS]: "yellow",
  [BOOKKEEPIN_SUMMAY_STATUSES.IN_CPA_REVIEW]: "red",
  [BOOKKEEPIN_SUMMAY_STATUSES.COMPLETED]: "green",
};

export const FILING_STATES = {
  [TAX_STATUSES.ACTION_REQUIRED]: "Action Required",
  [TAX_STATUSES.WITH_INKLE]: "With Inkle",
  [TAX_STATUSES.COMPLETED]: "Completed",
  [TAX_STATUSES.ACTIVE]: "Active",
};

export const BILLING_INFO_PLAN_DESCRIPTION = {
  [PRO]: "Monthly expense based slabs",
  [PAYG]: "$100/ year (covers upto 100 txn and $1/txn thereafter)",
  [BYOA]: "Monthly review of financials",
};

export const OPEN_ITEM_STATUS = {
  REQUESTED: "PROCESSING",
  SUBMITTED: "COMPLETED",
};

export const ESIGN_ITEM_TAG_TYPE = {
  REQUESTED: "gray",
  SUBMITTED: "green",
  PROCESSING: "yellow",
};

export const ESIGN_ITEM_STATUS = {
  REQUESTED: "REQUESTED",
  SUBMITTED: "COMPLETED",
  PROCESSING: "PROCESSING",
};

export const BankLogos: CardType = {
  discover: Discover,
  mastercard: MasterCard,
  amex: AmEx,
  jcb: JCB,
  unionpay: UnionPay,
  visa: Visa,
  default: DefaultBankLogo,
};

export const TxnSource = {
  CSV: "CSV",
  INKLE_BOOKS: "Inkle Books",
  MANUAL: "Manual",
};

export const BILLING_CYCLE = {
  [BILLING_CYCLES.MONTHLY]: "month",
  [BILLING_CYCLES.YEARLY]: "year",
  [BILLING_CYCLES.QUARTERLY]: "quarter",
};

export const REPORT_INFO = {
  [BALANCE_SHEET]:
    "A balance sheet is a financial statement that summarizes a company's assets, liabilities, and equity at a specific point in time, providing a snapshot of its financial position.",

  [INCOME_STATEMENT]:
    "An income statement, also known as a profit and loss statement, is a financial report that presents a summary of a company's revenues, expenses, and net income over a specific period, reflecting its profitability.",

  [CASH_FLOW_STATEMENT]: "",
};

export const REPORT_TYPE: { [key: string]: string } = {
  [BALANCE_SHEET]: "Balance Sheet",
  [INCOME_STATEMENT]: "Income Statement",
  [CASH_FLOW_STATEMENT]: "Cash Flow Statement",
  [SAVED_REPORTS]: "Saved Reports",
};

export const REPORT_FILE_TYPE = {
  [BALANCE_SHEET]: "balance_sheet",
  [INCOME_STATEMENT]: "profit_loss_statement",
  [CASH_FLOW_STATEMENT]: "cash_flow_statement",
};

export const ASSOCIATED_TYPE = {
  [ASSET]: "Asset",
  [LIABILITY]: "Liability",
  [EQUITY]: "Equity",
  [REVENUE]: "Revenue",
  [EXPENSE]: "Expense",
};

export const TRANSACTION_SOURCE = {
  JOURNAL_ENTRY: "Manual Entry",
  PLAID: "Plaid",
  BANK_STATEMENT: "Bank Statement",
  DIRECT_CONNECT: "Direct Connect",
  LEDGER_ENTRY: "Journal Entry",
};

export const CONNECTION_TYPE = {
  PLAID: " (Via Plaid)",
  MANUAL: " (Manually added)",
  DIRECT: "",
};

export const CRM_SUBSCRIPTION_BADGE_MAP = {
  [PRO]: "P",
  [STANDARD]: "S",
  [PAYG]: "PAYG",
  [BYOA]: "BYOA",
};

export const CRM_SUBSCRIPTION_TYPES = [
  PRO,
  STANDARD,
  FREE,
  UNSUBSCRIBED,
  OVERDUE,
  PAYG,
  BYOA,
];

export const ACCOUNTING_METHOD = {
  CASH: "Cash method",
  ACCRUAL: "Accrual method",
};

export const INVOICE_STATUS_TITLE = {
  [INVOICE_STATUSES.DRAFT]: "Draft",
  [INVOICE_STATUSES.PAID]: "Paid",
  [INVOICE_STATUSES.SENT]: "Sent",
};

export const CHAT_SETTING_BANNER_TYPE_DICT = {
  [SLA]: "SLA",
  [WEEKEND]: "Weekends",
  [HOLIDAY]: "Holidays",
};

export const REGISTRATION_TYPE = {
  HOME_STATE: "Home State",
  FOREIGN_QUALIFICATION: "Foreign Qualification",
  NOT_SPECIFIED: "",
};

export const REGISTRATION_TYPE_BADGE = {
  HOME_STATE: "purple",
  FOREIGN_QUALIFICATION: "light_grey",
  NOT_SPECIFIED: "",
};

export const TEAM_SETTINGS_INVITE_STATUS = {
  NOT_VERIFIED: "Invited",
  INVITED: "Invited",
  JOINED: "Active",
  ADMIN_APPROVED: "Active",
  ARCHIVED: "Archived",
  ADMIN_REJECTED: "Inactive",
};

export const TEAM_SETTINGS_INVITE_STATUS_COLOR = {
  NOT_VERIFIED: "neutral",
  INVITED: "gray",
  JOINED: "green",
  ADMIN_APPROVED: "green",
  ARCHIVED: "transparent",
  ADMIN_REJECTED: "transparent",
};

export const BILLING_INVOICE_STATUS = {
  PENDING: "Unpaid",
  PAID: "Paid",
};

export const PRODUCT_SERVICE_ITEM_TYPE = {
  PRODUCT: "Product",
  SERVICE: "Service",
};

export const PRODUCT_SERVICE_SOURCE_TYPE = {
  MANUAL: "Manually added",
  STRIPE: "Stripe",
};
