import classNames from "classnames";
import { ReactNode } from "react";

export type TagTypeProps = {
  children: ReactNode;
  icon?: boolean;
  rounded?: boolean;
  size?: "small" | "medium";
  bordered?: boolean;
  transparent?: boolean;
  tagType?:
    | "purple"
    | "red"
    | "green"
    | "orange"
    | "gray"
    | "transparent"
    | "yellow"
    | "blue"
    | "light_grey";
};

const TAG_TYPE_CLASSES = {
  /* @tw */
  purple: "t-text-purple",
  /* @tw */
  orange: "t-text-orange-80",
  /* @tw */
  red: "t-text-red",
  /* @tw */
  green: "t-text-dark_green-70",
  /* @tw */
  gray: "t-text-neutral-70",
  /* @tw */
  light_grey: "t-text-text-60 t-border-solid t-border-neutral-10 t-border",
  /* @tw */
  transparent: "t-text-neutral-70 t-border t-border-solid t-border-neutral-0",
  /* @tw */
  yellow: "t-text-yellow-90",
  /* @tw */
  blue: "",
};

const TAG_BACKGROUND_CLASSES = {
  /* @tw */
  purple: "t-bg-purple-0",
  /* @tw */
  orange: "t-bg-orange-10",
  /* @tw */
  red: "t-bg-red-10",
  /* @tw */
  green: "t-bg-dark_green-10",
  /* @tw */
  gray: "t-bg-neutral-0",
  /* @tw */
  light_grey: "",
  /* @tw */
  transparent: "",
  /* @tw */
  yellow: "t-bg-yellow-10 hover:t-bg-yellow-20",
  /* @tw */
  blue: "",
};

const TAG_DOT_TYPE_CLASSES = {
  /* @tw */
  purple: "t-bg-purple",
  /* @tw */
  orange: "t-bg-orange-70",
  /* @tw */
  red: "t-bg-red-50",
  /* @tw */
  green: "t-bg-dark_green",
  /* @tw */
  gray: "t-bg-neutral-70",
  /* @tw */
  transparent: "t-bg-neutral-20",
  /* @tw */
  yellow: "t-bg-yellow-70",
  /* @tw */
  blue: "t-bg-blue",
  /* @tw */
  light_grey: "t-bg-neutral-10",
};

export const Tag = ({
  children,
  tagType = "purple",
  icon = true,
  size = "medium",
  rounded,
  bordered,
  transparent,
}: TagTypeProps) => {
  return (
    <div
      className={classNames(
        `t-inline-flex t-items-center t-max-w-full ${TAG_TYPE_CLASSES[tagType]} t-text-body-sm t-truncate t-w-max t-max-w-[200px]`,
        {
          [TAG_BACKGROUND_CLASSES[tagType]]: !transparent,
          "t-rounded-full": rounded,
          "t-rounded-md": !rounded,
          "t-py-1 t-px-3": size === "medium" && !transparent,
          "t-px-2 t-py-0.5": size === "small" && !transparent,
          "t-border t-border-solid t-border-neutral-0 t-rounded-full t-flex":
            bordered,
        }
      )}
    >
      {icon && (
        <span
          className={`t-w-2 t-inline-block t-h-2 t-rounded-full t-mr-1.5 t-shrink-0 ${TAG_DOT_TYPE_CLASSES[tagType]}`}
        />
      )}
      {children}
    </div>
  );
};
