import Async from "components/DesignSystem/AsyncComponents/Async";
import { Button } from "components/DesignSystem/Button/Button";
import { Combobox } from "components/DesignSystem/Combobox/Combobox";
import Modal from "components/DesignSystem/Modal/Modal";
import { TextInput } from "components/DesignSystem/TextInput/TextInput";
import { PriceInput } from "components/PriceInput/PriceInput";
import { coaOptions } from "components/Transaction/TransactionColumn";
import { itemTypesOptions } from "constants/productAndServices";
import { PRODUCT_SERVICE_ITEM_TYPE } from "dictionaries";
import { Form, Formik, useFormikContext } from "formik";
import { addProductAndServiceSchema } from "formValidations/productAndServiceSchema";
import { useChartOfAccounts } from "hooks/useChartOfAccounts";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useToast } from "hooks/useToast";
import {
  useGetProductAndServiceByIdQuery,
  useGetProductAndServicesQuery,
  useGetProductCategoriesQuery,
  useUpdateProductAndServiceMutation,
} from "store/apis/productAndServices";
import { BackendError } from "types/utils/error";
import { ModalProps } from "types/utils/modal";

type EditProductAndServiceModalProps = { itemId: string } & ModalProps;

const EditForm = ({ isStripeProduct }: { isStripeProduct: boolean }) => {
  const { data: categories = [], isLoading } = useGetProductCategoriesQuery();
  const entityId = useCurrentEntityId();
  const { uuid: groupId } = useCurrentGroupContext();
  const { data: productAndSerivce } = useGetProductAndServicesQuery(
    {
      groupId,
      entityId,
    },
    {
      skip: !entityId || !groupId,
    }
  );
  const { revenue_category_id } = productAndSerivce || {};

  const { chartOfAccounts, ...coaAPIStates } = useChartOfAccounts({
    root_category_id: revenue_category_id,
  });

  const { values } = useFormikContext<{
    name: string;
    type: "PRODUCT" | "SERVICE";
    product_category: string;
    price: number;
    transaction_category_id: string;
    transaction_category_name: string;
  }>();

  const {
    type,
    transaction_category_id,
    product_category,
    transaction_category_name,
  } = values;

  const defaultType = itemTypesOptions.find((item) => item.value === type);

  const categoriesOptions = categories.map(({ name, uuid }) => ({
    label: name,
    value: uuid,
  }));

  const defaultCategory = categoriesOptions.find(
    ({ value }) => value === product_category
  );
  return (
    <>
      <TextInput
        required
        name="name"
        placeholder="Enter product name"
        label="Name"
        disabled={isStripeProduct}
      />
      <Combobox
        required
        label="Type"
        options={itemTypesOptions}
        name="type"
        withForm
        placeholder="Select type"
        defaultValue={defaultType || null}
        isClearable={false}
        isDisabled={isStripeProduct}
      />
      <PriceInput
        required
        label="Price"
        name="price"
        disabled={isStripeProduct}
      />
      <Combobox
        required
        label="Category"
        isDisabled={isLoading}
        isLoading={isLoading}
        options={categoriesOptions}
        name="product_category"
        creatable
        withForm
        placeholder="Select or create category"
        defaultValue={defaultCategory || null}
        isClearable={false}
        menuPortalTarget={document.body}
      />
      <Combobox
        label="Income Account"
        menuPlacement="top"
        isLoading={coaAPIStates.isLoading}
        isDisabled={coaAPIStates.isLoading}
        placeholder="Select income account"
        menuPortalTarget={document.body}
        withForm
        name="transaction_category_id"
        options={coaOptions(chartOfAccounts)}
        filterOption={(v, i) =>
          v.data.data?.toLocaleLowerCase().includes(i.toLocaleLowerCase()) ||
          false
        }
        isClearable={false}
        defaultValue={
          transaction_category_id
            ? {
                label: transaction_category_name,
                value: transaction_category_id,
              }
            : null
        }
      />
    </>
  );
};

export const EditProductAndServiceModal = ({
  isOpen,
  close,
  itemId,
}: EditProductAndServiceModalProps) => {
  const [updateProductAndService, { isLoading: isUpdating }] =
    useUpdateProductAndServiceMutation();

  const { alertToast, successToast } = useToast();
  const entityId = useCurrentEntityId();
  const { uuid: groupId } = useCurrentGroupContext();

  const {
    data: productOrService,
    isSuccess,
    isLoading,
  } = useGetProductAndServiceByIdQuery(
    {
      productId: itemId,
      entityId,
      groupId,
    },
    {
      skip: !itemId || !entityId || !groupId || !isOpen,
      refetchOnMountOrArgChange: true,
    }
  );

  const onSubmit = async (value: {
    name: string;
    type: "PRODUCT" | "SERVICE";
    product_category: string;
    price: number;
    transaction_category_id: string;
    transaction_category_name: string;
  }) => {
    try {
      const { type } = await updateProductAndService({
        groupId,
        entityId,
        productId: itemId,
        payload: {
          name: value.name,
          price: value.price,
          product_category: value.product_category,
          transaction_category_id: value.transaction_category_id,
          type: value.type,
        },
      }).unwrap();
      successToast({ message: `${PRODUCT_SERVICE_ITEM_TYPE[type]} updated!` });
      close();
    } catch (error) {
      alertToast(
        {
          message: (error as BackendError).data?.error?.message,
        },
        error as Error
      );
    }
  };

  const {
    name = "",
    price = 0,
    product_category_id = "",
    transaction_category_id = "",
    transaction_category_name = "",
    type = "PRODUCT",
    source,
  } = productOrService || {};

  const isStripeProduct = source === "STRIPE";

  return (
    <Modal.Root open={isOpen} onOpenChange={close}>
      <Modal.Content useCustomOverlay>
        <Async.Root
          isLoading={isLoading}
          isSuccess={isSuccess}
          isEmpty={!productOrService}
        >
          <Async.Empty>
            <></>
          </Async.Empty>
          <Async.Success>
            <Formik
              initialValues={{
                name,
                type,
                product_category: product_category_id,
                price: price || 0,
                transaction_category_id,
                transaction_category_name,
              }}
              onSubmit={onSubmit}
              validateOnChange
              validationSchema={addProductAndServiceSchema}
            >
              <Form>
                <fieldset disabled={isUpdating}>
                  <Modal.Header>
                    <Modal.Title>
                      Edit {`${PRODUCT_SERVICE_ITEM_TYPE[type]}`}
                    </Modal.Title>
                    <Modal.Close />
                  </Modal.Header>
                  <Modal.Body className="t-flex t-gap-4 t-flex-col">
                    <EditForm isStripeProduct={isStripeProduct} />
                  </Modal.Body>
                  <Modal.FooterButtonGroup>
                    <Modal.RawClose asChild>
                      <Button>Cancel</Button>
                    </Modal.RawClose>
                    <Button customType="primary" isLoading={isUpdating}>
                      Save
                    </Button>
                  </Modal.FooterButtonGroup>
                </fieldset>
              </Form>
            </Formik>
          </Async.Success>
        </Async.Root>
      </Modal.Content>
    </Modal.Root>
  );
};
