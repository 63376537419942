import { getGroupData } from "apis/getGroupData";
import { updatePublicUser } from "apis/profileData";
import "bootstrap/dist/css/bootstrap.min.css";
import classNames from "classnames";
import { Button } from "components/DesignSystem/Button/Button";
import TaskDetailArchive from "components/icons/TaskDetailArchive";
import TaskDetailUnarchive from "components/icons/TaskDetailUnarchive";
import { CETACEAN_BLUE, SHADE_10_PURPLE } from "constants/assetRegister";
import {
  ARCHIVED,
  SUBMITTED,
  TASK_ARCHIVED,
  TASK_UNARCHIVED,
} from "constants/task";
import { useAnalytics } from "hooks/useAnalytics";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { usePageTitle } from "hooks/usePageTitle";
import { useRoleBasedView } from "hooks/useRoleBasedView";
import { useContext, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useGetServiceTeamQuery } from "store/apis/serviceTeam";
import { useGetTaskQuery, useGetTaskTagsQuery } from "store/apis/task";
import { setDefaultChannel, setEntityAndTaskId } from "store/slices/chat";
import { updateTaskArchival } from "../../apis/getTaskData";
import authContext from "../../jwt_context&axios/authContext";
import "../../static/styles/components/taxPage.css";
import Loader from "../design/loader";
import ToolTip from "../design/toolTip";
import { Deadline } from "./editDeadline";
import GetIndex from "./getIndex";
import StepBar from "./stepBar";
// import { CustomChannel } from "components/chat/CustomChannel";
import { Channel } from "components/chat/Channel";
import DashboardContainer from "components/dashboard/DashboardContainer";
import Breadcrumb from "components/DesignSystem/Breadcrumb/Breadcrumb";
import Modal from "components/DesignSystem/Modal/Modal";
import { Folder } from "components/icons/Folder";
import { PriceLedger } from "components/icons/PriceLedger";
import { TaskPriceLedger } from "components/tasks/taskPriceLedger/TaskPriceLedger";
import { TASK_AMENDMENT_COMPLETED } from "constants/chatType";
import { useToast } from "hooks/useToast";
import ReactCountryFlag from "react-country-flag";
import { useInView } from "react-intersection-observer";
import RedClock from "static/images/Clock.svg";
import {
  collapseDashboardSidebar,
  setIsSidebarExpandable,
} from "store/slices/dashboard";
import { setShowPricingModal } from "store/slices/task";
import { setShowTaskReviewStep } from "store/slices/TaskReview";
import { formatDate } from "utils/formatDate";
import { openLink } from "utils/openLink";
import { ChatToggleHeader } from "./ChatToggleHeader";
import { ChatPane } from "components/ChatPane/ChatPane";
import { Tag } from "components/DesignSystem/Tag/Tag";
import { TASK_STATE_TO_COLOR } from "dictionaries";
import {
  FILING_IN_PROGRESS_TYPE,
  FORM_FILING_TYPE,
} from "constants/taskStates";
import { FILING_IN_PROGRESS } from "constants/taskStatus";
import Dropdown from "components/DesignSystem/Dropdown/Dropdown";
import { ArrowDown } from "components/icons/ArrowDown";
import { ArrowRight } from "components/icons/ArrowRight";
import { BundleDropdown } from "components/icons/BundleDropdown";

const DeadlineInfo = ({ task, authtoken, setModalShow, deadline }) => {
  return (
    <>
      {formatDate(task?.deadline)}
      {authtoken.is_service_user && setModalShow && (
        <>
          &nbsp;&nbsp;&nbsp;
          <i
            className="fas fa-pen penDeadline"
            aria-hidden={true}
            onClick={() => setModalShow(true)}
          />
        </>
      )}
      <br />
      {deadline < 0 ? deadline * -1 : deadline} &nbsp;days
      {deadline < 0 ? " overdue " : " left "}
    </>
  );
};

export default function TaxPage({
  isFromServices = false,
  parentRoute = "",
  addEntityInUrl = false,
}) {
  const { alertToast, successToast } = useToast();
  const [channelId, setChannelId] = useState();

  const { isCustomer, isCpa, isAdmin } = useRoleBasedView();
  const { dispatch: dispatchCurrentGroup } = useCurrentGroupContext();
  const isPricingStep = useSelector((state) => state.taskSlice.isPricingStep);

  const { taskId } = useParams();
  const { authtoken, updateUser: updateAuthUser } = useContext(authContext);
  const { trackEvent } = useAnalytics();

  const {
    data: task,
    isLoading,
    refetch,
    isSuccess,
    isError,
    error,
  } = useGetTaskQuery({ taskId });
  const [modalShow, setModalShow] = useState(false);
  usePageTitle(`${task?.title || "Filing"}`);
  const [load, setLoad] = useState(true);
  const [hovered, setHovered] = useState(false);
  const [showArchive, setShowArchive] = useState(false);
  const [currentUuid, setCurrentUuid] = useState(
    !Boolean(task?.state?.uuid) ? task?.task_states?.[0].uuid : task?.state.uuid
  );
  const [taskStepName, setTaskStepName] = useState("");
  const dispatch = useDispatch();
  const taskChannel = task?.channel?.channel_url;
  const parentRef = useRef(null);
  const [showPriceLedger, setShowPriceLedger] = useState(false);
  const { ref: taskDetailBarRef, inView: taskDetailBarInView } = useInView({
    threshold: 0,
  });
  const showTaskReviewStep = task?.state?.show_review_modal;
  const taskStateType = task?.is_archived
    ? ARCHIVED
    : task?.state?.type || "Not Started";

  useEffect(() => {
    dispatch(setShowTaskReviewStep(showTaskReviewStep));
  }, [dispatch, showTaskReviewStep]);

  useEffect(() => {
    dispatch(setIsSidebarExpandable(false));
    dispatch(collapseDashboardSidebar());
    return () => dispatch(setIsSidebarExpandable(true));
  }, []);

  useEffect(() => {
    const updateUser = async () => {
      try {
        const res = await updatePublicUser({
          isPublicUser: false,
        });
        updateAuthUser(res.data.data);
      } catch (e) {
        alertToast({ message: error?.response?.data?.error?.message });
      }
    };
    if (authtoken.is_public_user && !isLoading && isSuccess) {
      updateUser();
    }
  }, [authtoken.is_public_user, isLoading, isSuccess]);

  useEffect(() => {
    if (!isLoading) {
      dispatch(setDefaultChannel(taskChannel));
      dispatch(
        setEntityAndTaskId({
          channelTaskId: task?.uuid,
          channelEntity: task?.entity,
        })
      );
    }
  }, [dispatch, taskChannel]);

  useEffect(() => {
    if ((isSuccess, task)) {
      trackEvent("TASK_OPENED", {
        task_id: task.uuid,
        task_name: task.title,
        screen: `${task?.title || "Task"}`,
        comapny_group: task.group_name,
        task_type: task.template_type,
      });
    }

    if (isError) {
      trackEvent("TASK_OPEN_FAILED", {
        task_id: taskId,
        error_message: error?.data?.error?.message,
      });
    }
  }, [
    error?.data?.error?.message,
    isError,
    isSuccess,
    task,
    taskId,
    trackEvent,
  ]);

  useEffect(() => {
    refetch();
  }, [load]);

  const isArchived = task?.is_archived;
  const inkleDocId = task?.documents_group_id;

  const groupName = task?.group_name;
  const groupId = task?.group_uuid;

  useEffect(() => {
    if (!isCustomer && groupId) {
      getGroupData({ group_id: groupId }).then((response) => {
        if (response.status === 200) {
          dispatchCurrentGroup({
            type: "SET_GROUP",
            payload: response.data.data,
          });
        }
      });
    }
  }, [groupId]);

  useEffect(() => {
    if (task?.channel?.channel_url) {
      setChannelId(task.channel.channel_url);
    }
  }, [task?.channel?.channel_url]);

  const selectedState = !Boolean(task?.state?.uuid)
    ? task?.task_states?.[0].uuid
    : task?.state.uuid;

  const mainId = !Boolean(task?.state?.uuid)
    ? task?.task_states?.[0].uuid
    : task?.state.uuid;

  const isBundledTask = task?.bundled_tasks?.length > 0;

  const updateAssignee = async () => {
    await refetch().unwrap();
  };

  const updateFormFile = async () => {
    await refetch().unwrap();
  };

  var curDate = new Date();
  var deadline = new Date(task?.deadline);
  deadline = Math.floor((deadline - curDate) / (24 * 60 * 60 * 1000));

  const root = document.documentElement;
  root.addEventListener("mousemove", (e) => {
    root.style.setProperty("--posX", e.clientX + 10 + "px");
    root.style.setProperty("--posY", e.clientY + 10 + "px");
  });

  let privateChannelUrl;
  if (authtoken.is_service_user) {
    privateChannelUrl = task?.cpa_private_channel?.channel_url;
  } else {
    privateChannelUrl = task?.customer_private_channel?.channel_url;
  }

  const handleArchive = async () => {
    try {
      const {
        data: {
          data: { is_archived },
        },
      } = await updateTaskArchival({ taskId });
      successToast({ message: is_archived ? TASK_ARCHIVED : TASK_UNARCHIVED });
      setShowArchive(false);
      refetch().unwrap();
    } catch (e) {
      setShowArchive(false);
      alertToast({ message: error?.response?.data?.error?.message });
    }
  };

  const entityFlagSize = {
    width: "1.25em",
    height: "1.25em",
  };

  let breadcrumbItem = isAdmin ? `/crm/${groupId}/filings` : "/filings";
  if (isFromServices) {
    breadcrumbItem = parentRoute;
  }
  if (addEntityInUrl) {
    breadcrumbItem += `?entity=${task?.entity?.uuid}`;
  }
  let breadcrumbTitle = isFromServices ? "Services" : "Filings";

  if (isLoading) {
    return (
      <div className="t-w-full">
        <Loader />
      </div>
    );
  }

  return (
    <DashboardContainer className="t-h-full">
      <DashboardContainer.Header>
        <div className="t-pl-6">
          <Breadcrumb.Root>
            <Breadcrumb.Item link={breadcrumbItem}>
              {breadcrumbTitle}
            </Breadcrumb.Item>
            <Breadcrumb.Item>{task?.title}</Breadcrumb.Item>
          </Breadcrumb.Root>
          {task && (
            <div className="t-flex t-h-8 t-h-max t-w-full md:t-w-[calc(100%-360px)] t-flex-col t-items-stretch t-pb-4 t-border t-border-solid t-border-neutral-10 t-border-t-0 t-border-l-0 t-border-r-0">
              <div className="t-flex t-flex-col t-justify-between t-pt-1">
                <Deadline
                  deadline={task?.deadline}
                  modalShow={modalShow}
                  setModalShow={setModalShow}
                />
                <div className="leftHeadBack md:t-flex-wrap-none t-flex-col t-flex-wrap t-justify-between md:t-flex-row">
                  <div className="md:t-flex-wrap-none t-flex t-flex-wrap t-items-center">
                    <span className="t-text-subtitle">{task?.title}</span>
                    {isBundledTask && (
                      <Dropdown.Root>
                        <Dropdown.Trigger
                          asChild
                          className="t-group t-select-none"
                        >
                          <div className="t-ml-2">
                            <Button customType="icon" size="small">
                              <BundleDropdown />
                            </Button>
                          </div>
                        </Dropdown.Trigger>
                        <Dropdown.Portal>
                          <Dropdown.Content
                            sideOffset={4}
                            align="start"
                            side="bottom"
                            className="t-max-h-64 t-overflow-auto t-max-w-md"
                          >
                            {task.bundled_tasks?.map(
                              ({ name, state, uuid, type }) => (
                                <Dropdown.Item
                                  key={uuid}
                                  onSelect={() =>
                                    openLink(`/tax/filings/${uuid}`)
                                  }
                                >
                                  <div className="t-flex t-justify-between t-items-center t-gap-8 t-w-full">
                                    <Button size="small" customType="link">
                                      {name}
                                    </Button>
                                    <Tag
                                      tagType={
                                        TASK_STATE_TO_COLOR[
                                          type || "Not Started"
                                        ]
                                      }
                                      icon={false}
                                      size="small"
                                    >
                                      {state}
                                    </Tag>
                                  </div>
                                </Dropdown.Item>
                              )
                            )}
                          </Dropdown.Content>
                        </Dropdown.Portal>
                      </Dropdown.Root>
                    )}
                    {authtoken.is_service_user && (
                      <>
                        <div className="me-2 t-mx-2 t-font-medium t-text-neutral-10">
                          {" "}
                          |
                        </div>
                        <div className="t-flex t-gap-4 t-text-body t-text-neutral">
                          <span
                            className={classNames(
                              "!t-text-body !t-font-medium t-text-text-60",
                              {
                                groupName: task?.has_group_perm,
                              }
                            )}
                            onClick={() =>
                              task?.has_group_perm &&
                              openLink(`/crm/${groupId}`, "_blank")
                            }
                          >
                            {groupName}
                          </span>
                        </div>
                      </>
                    )}
                    <div className="me-2 t-ml-2 t-font-medium t-text-neutral-10">
                      {" "}
                      |
                    </div>
                    <span className="t-flex t-items-center">
                      <ToolTip text={task?.entity?.name}>
                        <div className="t-flex t-items-center t-gap-1">
                          <ReactCountryFlag
                            countryCode={task.entity.code_alpha_2}
                            svg
                            title={task.entity.country}
                            style={{
                              width: "1.25em",
                              height: "1.25em",
                            }}
                            className="t-rounded"
                          />
                        </div>
                      </ToolTip>
                      <div className="t-ml-2 t-font-medium t-text-neutral-10">
                        {" "}
                        |
                      </div>
                    </span>
                    <Dropdown.Root>
                      <Dropdown.Trigger
                        asChild
                        disabled={!isAdmin}
                        className={classNames({
                          "!t-cursor-default": !isAdmin,
                        })}
                      >
                        <span className="t-ml-2">
                          <Tag
                            tagType={TASK_STATE_TO_COLOR[taskStateType]}
                            icon={false}
                            size="small"
                          >
                            {taskStepName || task.state?.name || "Not started"}
                            {isAdmin && (
                              <div className="t-rotate-90 t-group-data-state-open:-t-rotate-90">
                                <ArrowRight stroke="1.5" color="currentColor" />
                              </div>
                            )}
                          </Tag>
                        </span>
                      </Dropdown.Trigger>
                      <Dropdown.Portal>
                        <Dropdown.Content>
                          {task.task_states
                            .filter(({ name }) => name !== "Pricing")
                            .map(({ uuid, name, type, order }) => (
                              <Dropdown.Item
                                key={uuid}
                                onSelect={() => {
                                  setCurrentUuid(uuid);
                                  setTaskStepName(name);
                                }}
                                disabled={order > task.state?.order}
                              >
                                {name}
                              </Dropdown.Item>
                            ))}
                        </Dropdown.Content>
                      </Dropdown.Portal>
                    </Dropdown.Root>
                    {isArchived && (
                      <>
                        <div className="me-2 t-mx-2 t-font-medium t-text-neutral-10">
                          {" "}
                          |
                        </div>
                        <div className="t-rounded t-bg-neutral-10 t-px-2 t-py-1 t-text-caption">
                          <div>Archived</div>
                        </div>
                      </>
                    )}
                  </div>
                  <div className="t-flex t-gap-2 t-items-center sm:t-self-baseline">
                    {task?.deadline && !isFromServices && (
                      <ToolTip
                        text={
                          <div className="t-text-body-sm t-text-white">
                            <DeadlineInfo
                              task={task}
                              authtoken={authtoken}
                              deadline={deadline}
                            />
                          </div>
                        }
                      >
                        <div>
                          <Button customType="ghost_icon" size="small" disabled>
                            <img height="20px" src={RedClock} alt="redClock" />
                          </Button>
                        </div>
                      </ToolTip>
                    )}
                    {!isCustomer && (
                      <div className="t-cursor-pointer">
                        <ToolTip text={isArchived ? "Unarchive" : "Archive"}>
                          <span>
                            <Button customType="icon" size="small">
                              <div
                                className="archiveBox t-text-text-30"
                                onClick={() => setShowArchive(true)}
                                onMouseEnter={() => setHovered(true)}
                                onMouseLeave={() => setHovered(false)}
                              >
                                {isArchived ? (
                                  <TaskDetailUnarchive color="currentColor" />
                                ) : (
                                  <TaskDetailArchive color="currentColor" />
                                )}
                              </div>
                            </Button>
                          </span>
                        </ToolTip>
                      </div>
                    )}
                    {isCpa && (
                      <ToolTip text="Document">
                        <a
                          target="_blank"
                          className="t-flex t-items-center t-text-text-black hover:t-text-purple"
                          rel="noreferrer"
                          href={`/crm/${groupId}/documents/${inkleDocId}`}
                        >
                          <Button customType="icon" size="small">
                            <Folder color="currentColor" />
                          </Button>
                        </a>
                      </ToolTip>
                    )}

                    {task?.task_change_logs && (
                      <ToolTip text="Pricing Ledger">
                        <span>
                          <Button
                            customType="icon"
                            size="small"
                            onClick={() => setShowPriceLedger(true)}
                          >
                            <PriceLedger color="currentColor" />
                          </Button>
                        </span>
                      </ToolTip>
                    )}
                    <div className="t-w-max">
                      <Button
                        size="small"
                        onClick={() => dispatch(setShowPricingModal(true))}
                        block
                      >
                        View details
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </DashboardContainer.Header>
      <DashboardContainer.Content className="t-overflow-hidden">
        <div className="t-flex sm:t-flex-col t-h-full">
          <div className="t-w-full md:t-w-[calc(100%-360px)] t-h-full">
            {task && (
              <div className="t-overflow-scroll t-relative t-h-[calc(100%-60px)] t-flex t-flex-col">
                <div
                  className={classNames("taxPage t-flex t-flex-col t-h-full", {
                    "t-sticky t-top-0": !taskDetailBarInView,
                  })}
                >
                  <div
                    className={classNames(
                      "t-w-full t-flex t-relative rightTax t-pt-2 t-h-full",
                      {
                        cr: isLoading,
                        disabledForm: isPricingStep && !isAdmin,
                      }
                    )}
                    ref={parentRef}
                  >
                    <GetIndex
                      updatedTaskFormFile={updateFormFile}
                      data={task?.task_states}
                      inkleDocId={inkleDocId}
                      currentStateStep={currentUuid || selectedState}
                      setCurrentStateStep={setCurrentUuid}
                      setTaskStepName={setTaskStepName}
                      selectedState={selectedState}
                      chk={task?.doc_data?.data}
                      form_data={task?.form_data}
                      groupName={groupName}
                      docId={task?.doc_data?.uuid}
                      description={task?.description}
                      pricingData={task?.subtitle}
                      pricingAmount={task?.payment_amount}
                      title={task?.title}
                      uid={task?.state}
                      mainId={mainId}
                      task={task}
                      setLoad={setLoad}
                      groupId={groupId}
                      isArchived={isArchived}
                      parentRef={parentRef}
                      updateAssignee={updateAssignee}
                      refetchTask={refetch}
                      isFromServices={isFromServices}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
          <div
            className={classNames(
              "taskChat t-min-w-full md:t-w-[360px] md:t-min-w-[360px] t-h-full t-border-solid t-border t-border-neutral-10 t-overflow-scroll"
            )}
          >
            <ChatPane
              channelId={channelId}
              setChannelId={setChannelId}
              headerWithOnlySwitch
            />
          </div>
          {showArchive && (
            <Modal.Root open={showArchive} onOpenChange={setShowArchive}>
              <Modal.Content size="small">
                <Modal.Header>
                  <Modal.Title>
                    {isArchived
                      ? "Unarchive this filing?"
                      : "Archive this filing?"}
                  </Modal.Title>
                  <Modal.Close />
                </Modal.Header>
                <Modal.Body>
                  Are you sure you want to archive this filing?
                </Modal.Body>
                <Modal.FooterButtonGroup>
                  <Button onClick={() => setShowArchive(false)}>Cancel</Button>
                  <Button customType="danger" onClick={handleArchive}>
                    {isArchived ? "Unarchive" : "Archive"}
                  </Button>
                </Modal.FooterButtonGroup>
              </Modal.Content>
            </Modal.Root>
          )}
          {showPriceLedger && (
            <TaskPriceLedger
              show={showPriceLedger}
              closeModal={() => setShowPriceLedger(false)}
              taskChangeLogs={task.task_change_logs}
              finalPrice={task.payment_amount}
            />
          )}
        </div>
      </DashboardContainer.Content>
    </DashboardContainer>
  );
}
