import { AppLayout } from "components/AppLayout/AppLayout";
import GroupSelect from "components/dashboard/sideBar/groupSelect";
import { DashboardLayout } from "components/DashboardLayout";
import { NoPermission } from "components/design/noPermission";
import { Header } from "components/DesignSystem/Header/Header";
import { Home } from "components/icons/LeftNav/Home";
import { Intray } from "components/icons/LeftNav/Mailroom/Intray";
import { RegAgent } from "components/icons/LeftNav/Mailroom/RegAgent";
import { InkleMailroom } from "components/icons/Logos/InkleMailroom";
import { InkleMailroomSymbol } from "components/icons/Logos/InkleMailroomSymbol";
import { Mailbox } from "components/mailbox/mailbox";
import { MailRoomHomeCard } from "components/MaillRoomHomeCard/MailRoomHomeCard";
import { NavAcrossApps } from "components/NavAcrossApps/NavAcrossApps";
import { RAHomeCard } from "components/RAHomeCard/RAHomeCard";
import { RegisterAgent } from "components/RegisteredAgent/RegisterAgent";
import { RoutesAccrossApps } from "components/RoutesAcrossApps/RoutesAcrossApps";
import {
  LeftBar,
  LeftBarApps,
  LeftBarItem,
  LeftBarLogo,
  LeftBarSeparator,
} from "components/Sidebar/LeftBar";
import { TopBar } from "components/TopBar/TopBar";
import { MAILROOM, REGISTERED_AGENT } from "constants/addons";
import { FOREIGN_CA } from "constants/onBoarding";
import { useAuth } from "hooks/useAuth";
import { useRoleBasedView } from "hooks/useRoleBasedView";
import authContext from "jwt_context&axios/authContext";
import { useContext } from "react";
import { Redirect, Switch, useRouteMatch } from "react-router-dom";
import { RestrictedRoute } from "RestrictedRoute";
import { DashboardBanner } from "signInSignUp/DashboardBanner";
import "static/styles/containers/dashboard.css";
import { Subscription } from "types/Models/subscription";
import { MailroomActionItems } from "./MailroomForward/MailroomActionItems";
import { EntitySelector } from "components/EntitySelector/EntitySelector";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useGetSubscriptionsQuery } from "store/apis/subscriptions";
import { useConstructInternalLink } from "hooks/useConstructInternalLink";
import { ChatLeftNav } from "components/ChatLeftNav/ChatLeftNav";
import { Ticket } from "components/icons/LeftNav/Ticket";
import { Documents as DocumentsIcon } from "components/icons/LeftNav/Documents";
import { UpgradeTaxPlan } from "./Tax/UpgradeTaxPlan";

const Navbar = () => {
  const { url } = useRouteMatch();
  const { type } = useAuth();
  const { link } = useConstructInternalLink();

  return (
    <>
      {type === FOREIGN_CA && (
        <div className="t-m-2">
          <GroupSelect />
        </div>
      )}
      <LeftBarItem exact to={link("")} icon={Home}>
        Home
      </LeftBarItem>

      <LeftBarItem to={link("/inbox")} icon={Intray}>
        Inbox
      </LeftBarItem>
      <LeftBarItem to={link("/registered-agent")} icon={RegAgent}>
        Registered Agent
      </LeftBarItem>

      <div className="t-py-3">
        <LeftBarSeparator />
      </div>

      <ChatLeftNav to={link("/chat")} />
      <LeftBarItem to={link("/action-items")} icon={Ticket}>
        Action Items
      </LeftBarItem>
      <LeftBarItem to={link("/documents")} icon={DocumentsIcon}>
        Documents
      </LeftBarItem>

      <div className="t-mt-auto">
        <div className="t-py-3">
          <LeftBarSeparator name="Other Apps" />
        </div>
        <LeftBarApps search={link("")} current={url} useExistingSearch />
      </div>
    </>
  );
};

export const MailroomDashboard = () => {
  const { isCustomer } = useRoleBasedView();
  const { first_name } = useAuth();

  const { path } = useRouteMatch();
  const entityId = useCurrentEntityId();
  const { uuid: groupId } = useCurrentGroupContext();

  const { data: subscriptions, isSuccess } = useGetSubscriptionsQuery(
    {
      groupId,
      entityId,
    },
    {
      skip: !entityId || !groupId,
    }
  );

  const isMailroomSubscribed = subscriptions
    ?.filter((s) => s.group_subscription)
    .some((subscription) => subscription.subscription_type === MAILROOM);

  const isRASubscribed = subscriptions
    ?.filter((s) => s.group_subscription)
    .some(
      (subscription) => subscription.subscription_type === REGISTERED_AGENT
    );

  const MailComponent = isCustomer ? Mailbox : NoPermission;

  return (
    <AppLayout
      sidebar={
        <LeftBar
          useInternalLink
          logo={
            <LeftBarLogo logo={InkleMailroom} symbol={InkleMailroomSymbol} />
          }
        >
          <Navbar />
        </LeftBar>
      }
      topbar={
        <TopBar
          upgradeButton={<UpgradeTaxPlan />}
          logo={
            <LeftBarLogo logo={InkleMailroom} symbol={InkleMailroomSymbol} />
          }
          nav={
            <LeftBar>
              <Navbar />
            </LeftBar>
          }
        />
      }
      banner={<DashboardBanner />}
    >
      <Switch>
        <RestrictedRoute exact path={`${path}/inbox/:mailId`}>
          <Redirect to={`${path}/inbox`} />
        </RestrictedRoute>
        <RestrictedRoute exact path={`${path}/tickets`}>
          <Redirect to={`${path}/action-items`} />
        </RestrictedRoute>
        <RestrictedRoute path={`${path}/inbox`}>
          <DashboardLayout
            header={
              <Header
                v2
                title="Inbox"
                right={
                  <>
                    <EntitySelector />
                  </>
                }
              />
            }
          >
            <MailComponent userGroupId={null} hasMailroomSubscription={null} />
          </DashboardLayout>
        </RestrictedRoute>

        <RestrictedRoute path={`${path}/registered-agent`}>
          <RegisterAgent />
        </RestrictedRoute>

        <RestrictedRoute exact path={path}>
          <DashboardLayout
            header={
              <Header
                right={
                  <>
                    <EntitySelector />
                  </>
                }
                v2
                title={`Welcome ${first_name}`}
              />
            }
          >
            <div className="t-flex t-flex-wrap t-gap-8 t-pt-6">
              <MailRoomHomeCard
                isMailroomSubscribed={
                  isSuccess ? Boolean(isMailroomSubscribed) : true
                }
              />
              <RAHomeCard
                isRASubscribed={isSuccess ? Boolean(isRASubscribed) : true}
              />
              {isMailroomSubscribed && <MailroomActionItems />}
            </div>
          </DashboardLayout>
        </RestrictedRoute>

        <RoutesAccrossApps />
      </Switch>
    </AppLayout>
  );
};
