export const HelpCircle = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
  >
    <g clip-path="url(#clip0_818_7911)">
      <path
        d="M6.05998 6.00016C6.21672 5.55461 6.52608 5.1789 6.93328 4.93958C7.34048 4.70027 7.81924 4.61279 8.28476 4.69264C8.75028 4.77249 9.17252 5.01451 9.4767 5.37585C9.78087 5.73718 9.94735 6.19451 9.94665 6.66683C9.94665 8.00016 7.94665 8.66683 7.94665 8.66683M7.99998 11.3335H8.00665M14.6666 8.00016C14.6666 11.6821 11.6819 14.6668 7.99998 14.6668C4.31808 14.6668 1.33331 11.6821 1.33331 8.00016C1.33331 4.31826 4.31808 1.3335 7.99998 1.3335C11.6819 1.3335 14.6666 4.31826 14.6666 8.00016Z"
        stroke="#98A2B3"
        stroke-width="1.33333"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_818_7911">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
