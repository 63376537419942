import { AddressFormFields } from "components/AddressFormFields/AddressFormFields";
import ConditionalToolTip from "components/design/conditionalToolTip";
import { Button } from "components/DesignSystem/Button/Button";
import { MobileInput } from "components/DesignSystem/MobileInput/MobileInput";
import Modal from "components/DesignSystem/Modal/Modal";
import { Switch } from "components/DesignSystem/Switch/Switch";
import { TextInput } from "components/DesignSystem/TextInput/TextInput";
import {
  CLICKED_SAVE_IN_ADD_CUSTOMER_MODAL,
  CLICKED_SAVE_IN_UPDATE_CUSTOMER_MODAL,
} from "constants/analyticsEvents";
import { Form, Formik } from "formik";
import { invoiceCustomerValidation } from "formValidations/invoiceCustomerValidation";
import { useAnalytics } from "hooks/useAnalytics";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import { useCurrentGroup } from "hooks/useCurrentGroup";
import { useToast } from "hooks/useToast";
import { useHistory } from "react-router-dom";
import {
  InvoiceCustomer,
  useCreateInvoiceCustomerMutation,
  useGetAllInvoiceCustomersQuery,
  useGetInvoiceSettingsQuery,
  useUpdateInvoiceCustomerMutation,
} from "store/apis/invoices";

const initialCustomerValues = {
  company_name: "",
  billing_address: {
    street_address: "",
    city: "",
    country: "",
    zipcode: "",
    state: "",
    uuid: "",
  },
  phone: "",
  email: "",
  is_reminders_enabled: false,
};

export const InvoiceCustomerModal = ({
  customerId,
  setCustomerId,
}: {
  customerId: string | null;
  setCustomerId: (v: null | string) => void;
}) => {
  const group = useCurrentGroup();
  const entityId = useCurrentEntityId();
  const { data: invoiceSettings } = useGetInvoiceSettingsQuery(
    {
      groupId: group?.uuid!,
      entityId,
    },
    { skip: !group?.uuid || !entityId }
  );
  const { trackEvent } = useAnalytics();
  const history = useHistory();
  const [createInvoiceCustomer, { isLoading: creatingInvoiceCustomer }] =
    useCreateInvoiceCustomerMutation();

  const [updateInvoiceCustomer, { isLoading: updatingInvoiceCustomer }] =
    useUpdateInvoiceCustomerMutation();

  const { alertToast, successToast } = useToast();

  const { data: invoiceCustomers } = useGetAllInvoiceCustomersQuery(
    {
      groupId: group?.uuid!,
      entityId: entityId!,
    },
    { skip: !group?.uuid || !entityId }
  );
  const editingCustomer = invoiceCustomers?.find((r) => r.uuid === customerId);
  const isReminderSetup = Boolean(invoiceSettings?.reminder_settings);

  const onCustomerSubmitUpdate = async (
    values: Omit<InvoiceCustomer, "customer_id">
  ) => {
    trackEvent(CLICKED_SAVE_IN_UPDATE_CUSTOMER_MODAL);
    let payload = { ...values };

    if (payload.billing_address) {
      payload = {
        ...payload,
        billing_address: {
          ...editingCustomer?.billing_address,
          ...payload.billing_address,
        },
      };
    }
    try {
      if (group?.uuid && entityId && customerId) {
        await updateInvoiceCustomer({
          groupId: group?.uuid,
          entityId: entityId,
          payload: payload,
          customerId: customerId,
        }).unwrap();
        setCustomerId(null);
        successToast({ message: "Customer details has been updated!" });
      }
    } catch (error: any) {
      alertToast({ message: error?.data?.error?.message });
    }
  };

  const onCustomerSubmitCreate = async (
    values: Omit<InvoiceCustomer, "customer_id">
  ) => {
    trackEvent(CLICKED_SAVE_IN_ADD_CUSTOMER_MODAL);
    let payload = values;

    try {
      if (group?.uuid && entityId) {
        await createInvoiceCustomer({
          groupId: group?.uuid,
          entityId: entityId,
          payload: payload,
        }).unwrap();
        setCustomerId(null);
        successToast({ message: "New Customer has been created!" });
      }
    } catch (error: any) {
      alertToast({ message: error?.data?.error?.message });
    }
  };

  return (
    <Modal.Root
      open={Boolean(customerId)}
      onOpenChange={() => setCustomerId(null)}
    >
      {Boolean(customerId) && (
        <Formik
          validateOnBlur
          validateOnChange={false}
          onSubmit={
            editingCustomer ? onCustomerSubmitUpdate : onCustomerSubmitCreate
          }
          validationSchema={invoiceCustomerValidation}
          initialValues={editingCustomer || initialCustomerValues}
        >
          {({ submitForm, values, errors, setFieldValue }) => (
            <Form>
              <Modal.Content size="large">
                <Modal.Header>
                  <Modal.Title>
                    {editingCustomer ? "Edit" : "Add"} Customer
                  </Modal.Title>
                  <Modal.Close />
                </Modal.Header>

                <Modal.Body>
                  <div className="t-flex t-flex-col t-gap-4">
                    <p className="t-m-0 t-text-subtitle">Customer Info</p>
                    <TextInput
                      required
                      name="company_name"
                      label="Customer Name"
                    />
                    <div>
                      <MobileInput
                        label="Phone"
                        name="phone"
                        value={values?.phone}
                      />
                    </div>
                    <TextInput required name="email" label="Recipient Email" />

                    <p className="t-m-0 t-text-subtitle">Bill to</p>

                    <AddressFormFields
                      required={false}
                      formPrefix="billing_address."
                    />

                    <p className="t-m-0 t-text-subtitle">Email Reminders</p>
                    <div className="t-flex t-gap-2 t-items-center t-min-h-fit">
                      <ConditionalToolTip
                        side="right"
                        condition={
                          !isReminderSetup && (
                            <div className="t-p-2 t-rounded t-text-left t-w-64">
                              <div>
                                Please configure email reminders inside
                                invoicing settings to send reminders
                              </div>
                              <div
                                className="t-subtext-sm t-underline t-mt-4 t-w-fit"
                                onClick={() =>
                                  history.push(
                                    `/books/invoicing/settings?entity=${entityId}&openconfigure=true`
                                  )
                                }
                                role="button"
                              >
                                Go to settings
                              </div>
                            </div>
                          )
                        }
                      >
                        <span>
                          <Switch
                            checked={values.is_reminders_enabled}
                            size="small"
                            name="is_reminders_enabled"
                            onCheckedChange={(v) => {
                              setFieldValue("is_reminders_enabled", v);
                            }}
                            disabled={!isReminderSetup}
                          />
                        </span>
                      </ConditionalToolTip>
                      <span className="t-text-body">
                        Send email reminders for unpaid invoices around due date
                      </span>
                    </div>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <div className="t-flex t-justify-end">
                    <Button
                      onClick={submitForm}
                      customType="primary"
                      isLoading={
                        creatingInvoiceCustomer || updatingInvoiceCustomer
                      }
                      disabled={
                        creatingInvoiceCustomer || updatingInvoiceCustomer
                      }
                    >
                      Save
                    </Button>
                  </div>
                </Modal.Footer>
              </Modal.Content>
            </Form>
          )}
        </Formik>
      )}
    </Modal.Root>
  );
};
