import ConditionalToolTip from "components/design/conditionalToolTip";
import { DropdownItemProps } from "components/DesignSystem/Dropdown/Dropdown";
import { useRoleBasedView } from "hooks/useRoleBasedView";

type ActionDropdownProps = {
  isManualTransaction: boolean;
  isSplitTransaction: boolean;
  isExcluedTransaction: boolean;
  Action: {
    RequestInfo: (props: DropdownItemProps) => JSX.Element;
    SendToChat: (props: DropdownItemProps) => JSX.Element;
    AddComment: (props: DropdownItemProps) => JSX.Element;
    AddInvoice: (props: DropdownItemProps) => JSX.Element;
    Edit: (props: DropdownItemProps) => JSX.Element;
    Delete: (props: DropdownItemProps) => JSX.Element;
    SplitTransaction: (props: DropdownItemProps) => JSX.Element;
    EditSplitTransaction: (props: DropdownItemProps) => JSX.Element;
    DeleteSplitTransaction: (props: DropdownItemProps) => JSX.Element;
    ExcludeTransaction: (props: DropdownItemProps) => JSX.Element;
    RestoreTransaction: (props: DropdownItemProps) => JSX.Element;
  };
};

export const ActionDropdown = ({
  isManualTransaction,
  isSplitTransaction,
  isExcluedTransaction,
  Action,
}: ActionDropdownProps) => {
  const { isCpa } = useRoleBasedView();

  const CommonActions = () => {
    return (
      <>
        {isCpa && <Action.RequestInfo />}
        <Action.SendToChat />
        <Action.AddComment />
        <Action.AddInvoice />
      </>
    );
  };

  switch (true) {
    case isExcluedTransaction:
      return (
        <>
          <Action.RestoreTransaction />
          {isManualTransaction && <Action.Delete />}
        </>
      );

    case isManualTransaction:
      return (
        <>
          <CommonActions />
          {isSplitTransaction && (
            <>
              <Action.EditSplitTransaction />
              <Action.DeleteSplitTransaction />
            </>
          )}
          {!isSplitTransaction && <Action.SplitTransaction />}
          <ConditionalToolTip
            condition={
              isSplitTransaction &&
              "Cannot edit transaction that has been split"
            }
          >
            <span>
              <Action.Edit disabled={isSplitTransaction} />
            </span>
          </ConditionalToolTip>
          <Action.ExcludeTransaction />
          <ConditionalToolTip
            condition={
              isSplitTransaction &&
              "Cannot delete transaction that has been split"
            }
          >
            <span>
              <Action.Delete disabled={isSplitTransaction} />
            </span>
          </ConditionalToolTip>
        </>
      );

    case !isManualTransaction:
      return (
        <>
          <CommonActions />
          {isSplitTransaction && (
            <>
              <Action.EditSplitTransaction />
              <Action.DeleteSplitTransaction />
            </>
          )}
          {!isSplitTransaction && <Action.SplitTransaction />}
          <Action.ExcludeTransaction />
        </>
      );
  }
};
