import ConditionalToolTip from "components/design/conditionalToolTip";
import DropDown from "components/DesignSystem/Dropdown/Dropdown";
import { Goto } from "components/icons/Goto";
import {
  FILE_FOLDER_DELETED,
  FILE_FOLDER_DELETE_FAILED,
  FILE_FOLDER_DOWNLOADED,
  FILE_FOLDER_DOWNLOAD_FAILED,
} from "constants/analyticsEvents";
import { useAnalytics } from "hooks/useAnalytics";
import { useCopyToClipboard } from "hooks/useCopyToClipboard";
import { useDocPreview } from "hooks/useDocPreview";
import { useQuery } from "hooks/useQuery";
import { useRoleBasedView } from "hooks/useRoleBasedView";
import { useToast } from "hooks/useToast";
import { useContext, useState } from "react";
import {
  useExportFolderMutation,
  useLazyGetSearchContentQuery,
} from "store/apis/documents";
import { getFileDownloadUrl } from "../../apis/documents";
import {
  COPY_LINK,
  DELETE_ACTION,
  DOWNLOAD_ACTION,
  FILE_DELETE_MESSAGE,
  FOLDER_DELETE_MESSAGE,
  FOLDER_TYPE,
  GO_TO_FOLDER_ACTION,
  ICON_DEFAULT_COLOR,
  MOVE_ACTION,
  PREVIEW_ACTION,
  RENAME_ACTION,
} from "../../constants/documents";
import { openLink } from "../../utils/openLink";
import { DeleteModal } from "../design/deleteModal";
import { DeleteIcon } from "../icons/delete";
import { DownloadIcon } from "../icons/Download";
import { Link } from "../icons/Link";
import { MoveFile } from "../icons/moveFile";
import { OptionDropdown } from "../icons/optionDropdown";
import { PreviewIcon } from "../icons/Preview";
import { RenameIcon } from "../icons/rename";
import { DocumentContext } from "./documentContext";
import { MoveFileModal } from "./documentsModal/moveFileModal";
import { RenameFileFolderModal } from "./documentsModal/renameFileFolderModal";
import {
  useRenameFileFolderMutation,
  useDeleteFileFolderMutation,
} from "store/apis/documents";
import { Button } from "components/DesignSystem/Button/Button";

const FileFolderActionDropDown = ({
  userGroupId,
  folderId,
  groupId,
  setLoad,
  folderName,
  setNewFolderName,
  itemType,
  isPreviewable,
  setMovedFolderName,
  groupDocID,
  ParentId,
  parentUuid,
  isLocked,
  listView,
  fileType,
}) => {
  const { trackEvent } = useAnalytics();
  const { alertToast, successToast } = useToast();
  const { setCurrentPageNo, setEntityFileAndFolder, handleFolderClick } =
    useContext(DocumentContext);
  const query = useQuery();
  const openPreview = useDocPreview();
  const { isAdmin } = useRoleBasedView();
  const searchTerm = query.get("search");
  const [renameModalShow, setRenameModalShow] = useState(false);
  const [payload, setPayload] = useState({ file_name: folderName });
  const [deleteModalShow, setDeleteModalShow] = useState(false);
  const [moveFileModalShow, setMoveFileModalShow] = useState(false);
  const [getSearchContent] = useLazyGetSearchContentQuery();
  const [exportFolder, { reset }] = useExportFolderMutation();
  const { copyToClipboard } = useCopyToClipboard();
  const [renameFileAndFolder] = useRenameFileFolderMutation();
  const [deleteFileAndFolder] = useDeleteFileFolderMutation();

  const linkText = `${process.env.REACT_APP_URL}docs/${groupId}/${parentUuid}`;

  const openDeleteModal = () => setDeleteModalShow(true);

  const closeDeleteFolderModal = () => setDeleteModalShow(false);

  const closeMoveFileModal = () => setMoveFileModalShow(false);

  const openRenameModal = () => setRenameModalShow(true);

  const closeRenameModal = () => setRenameModalShow(false);

  const openMoveFileModal = () => setMoveFileModalShow(true);

  const onCrossClick = () => {
    successToast({ title: "Cancelled", message: "Move cancelled" });
    setMoveFileModalShow(false);
  };

  const deleteFolder = async () => {
    try {
      await deleteFileAndFolder({
        groupId,
        folderId,
      }).unwrap();
      trackEvent(FILE_FOLDER_DELETED, {
        file_or_folder_ids: [folderId],
      });
      if (itemType === FOLDER_TYPE) {
        successToast({ message: FOLDER_DELETE_MESSAGE });
      } else {
        successToast({ message: FILE_DELETE_MESSAGE });
      }

      if (searchTerm) {
        const {
          files = [],
          folders = [],
          current_page,
        } = await getSearchContent({
          searchTerm,
          groupId,
          pageNum: 1,
        }).unwrap();
        setCurrentPageNo(current_page);

        let updatedMapData = [...folders, ...files].map((ele) => {
          return {
            ...ele,
            isChecked: false,
          };
        });
        setEntityFileAndFolder(updatedMapData);
      } else {
        setLoad((prev) => !prev);
      }
    } catch (error) {
      trackEvent(FILE_FOLDER_DELETE_FAILED, {
        file_or_folder_ids: folderId,
        error: error?.response?.data?.error?.message,
      });
      alertToast({ message: error?.response?.data?.error?.message });
    }
  };

  const renameFolder = async ({ file_name }) => {
    try {
      const data = await renameFileAndFolder({
        groupId,
        payload: { file_name },
        fileId: folderId,
      }).unwrap();
      successToast({ message: `Renamed to ${data.name}` });
      setNewFolderName(data.name);
      setLoad((prev) => !prev);
      closeRenameModal();
    } catch (error) {
      alertToast({ message: error?.response?.data?.error?.message });
    }
  };

  const openPreviewAndDownloadLink = async (preview) => {
    if (preview) {
      openPreview(folderId);
    } else {
      try {
        if (itemType === FOLDER_TYPE) {
          const { download_url } = await exportFolder({
            groupId,
            fileId: [folderId],
          }).unwrap();
          openLink(download_url);
          reset();
        } else {
          const response = await getFileDownloadUrl({
            groupId,
            fileId: folderId,
          });
          openLink(response.data.data.download_url);
        }
        trackEvent(FILE_FOLDER_DOWNLOADED, { file_or_folder_ids: [folderId] });
      } catch (error) {
        trackEvent(FILE_FOLDER_DOWNLOAD_FAILED, {
          file_or_folder_ids: [folderId],
          error: error?.response?.data?.error?.message,
        });

        alertToast({ message: error?.response?.data?.error?.message });
      }
    }
  };

  const handleActionType = (action) => {
    switch (action) {
      case DELETE_ACTION:
        openDeleteModal();
        break;
      case RENAME_ACTION:
        openRenameModal();
        break;
      case PREVIEW_ACTION:
        openPreviewAndDownloadLink(true);
        break;
      case DOWNLOAD_ACTION:
        openPreviewAndDownloadLink();
        break;
      case MOVE_ACTION:
        openMoveFileModal();
        break;
      case COPY_LINK:
        copyToClipboard(linkText);
        break;
      case GO_TO_FOLDER_ACTION:
        handleFolderClick(parentUuid);
        break;
      default:
        return null;
    }
  };

  const location = searchTerm
    ? [
        {
          Icon: Goto,
          title: "Go to folder",
          id: 7,
        },
      ]
    : [];

  const folderDelete = isAdmin
    ? [
        {
          Icon: DeleteIcon,
          title: "Delete",
          id: 4,
          isLocked,
        },
      ]
    : [];

  const isPrev = isPreviewable
    ? [
        {
          Icon: PreviewIcon,
          title: "Preview",
          id: 1,
        },
      ]
    : [];

  let folderDropdownData;
  if (itemType === FOLDER_TYPE) {
    folderDropdownData = [
      {
        Icon: RenameIcon,
        title: "Rename",
        id: 1,
        isLocked,
      },
      {
        Icon: Link,
        title: "Copy link",
        id: 2,
      },
      {
        Icon: DownloadIcon,
        title: "Download",
        id: 3,
      },
      ...folderDelete,
      ...location,
    ];
  } else {
    folderDropdownData = [
      ...isPrev,
      {
        Icon: DownloadIcon,
        title: "Download",
        id: 2,
      },
      {
        Icon: DeleteIcon,
        title: "Delete",
        id: 3,
        isLocked,
      },
      {
        Icon: RenameIcon,
        title: "Rename",
        id: 4,
        isLocked,
      },
      {
        Icon: MoveFile,
        title: "Move to",
        id: 5,
        isLocked: isAdmin ? false : isLocked,
      },
      {
        Icon: Link,
        title: "Copy link",
        id: 6,
      },
      ...location,
    ];
  }

  return (
    <span onClick={(e) => e.stopPropagation()}>
      <DropDown.Root>
        <DropDown.Trigger asChild>
          <button
            className="all:unset t-w-6 t-rounded hover:t-bg-purple-20 data-state-open:t-bg-purple-20"
            onClick={(e) => e.stopPropagation()}
          >
            <OptionDropdown color={ICON_DEFAULT_COLOR} />
          </button>
        </DropDown.Trigger>
        <DropDown.Portal>
          <DropDown.Content sideOffset={8} align={listView ? "end" : "start"}>
            {folderDropdownData.map(({ id, Icon, title, isLocked }) => (
              <ConditionalToolTip
                condition={
                  isLocked &&
                  !isAdmin && (
                    <div className="t-max-w-32 t-text-center">
                      Please raise a request in Chat to make a change
                    </div>
                  )
                }
                placement={listView ? "left" : "right"}
                key={id}
              >
                <span>
                  <DropDown.Item
                    className="t-flex t-items-center t-gap-2 hover:t-text-purple data-[disabled]:t-text-neutral-30"
                    disabled={isLocked}
                    onSelect={() => handleActionType(title)}
                  >
                    <Icon color="currentColor" />
                    {title}
                  </DropDown.Item>
                </span>
              </ConditionalToolTip>
            ))}
          </DropDown.Content>
        </DropDown.Portal>
      </DropDown.Root>

      {renameModalShow && (
        <RenameFileFolderModal
          renameModalShow={renameModalShow}
          closeRenameModal={closeRenameModal}
          itemType={itemType}
          folderName={folderName}
          renameFolder={renameFolder}
        />
      )}
      {deleteModalShow && (
        <DeleteModal
          text={`Delete this ${itemType.toLowerCase()}`}
          show={deleteModalShow}
          closeModal={closeDeleteFolderModal}
          onClick={deleteFolder}
        />
      )}
      {moveFileModalShow && (
        <MoveFileModal
          groupId={groupId}
          onCrossClick={onCrossClick}
          moveFileModalShow={moveFileModalShow}
          closeMoveFileModal={closeMoveFileModal}
          fileId={folderId}
          setNewFolderName={setNewFolderName}
          setLoad={setLoad}
          setMovedFolderName={setMovedFolderName}
          groupDocID={groupDocID}
          ParentId={ParentId}
        />
      )}
    </span>
  );
};

export default FileFolderActionDropDown;
