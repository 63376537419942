import { AmountSuperScript } from "components/design/AmountSuperScript";
import { Button } from "components/DesignSystem/Button/Button";
import { InfoItem } from "components/DesignSystem/InfoItem/InfoItem";
import { Link } from "components/DesignSystem/Link/Link";
import { Loader } from "components/DesignSystem/Loader/Loader";
import Slider from "components/DesignSystem/Slider/Slider";
import { SliderAccordion } from "components/DesignSystem/SliderAccordion/SliderAccordion";
import { FilePreviewTag } from "components/FilePreviewTag/FilePreviewTag";
import { DownloadIcon } from "components/icons/Download";
import { PAID } from "constants/billing";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useParams } from "react-router-dom";
import { useGetBillingInvoiceQuery } from "store/apis/billingInvoice";
import { BillingInvoice } from "types/Models/billing";
import { formatDate } from "utils/formatDate";

const invoiceTypeMap = {
  RECURRING: "Subscription",
  ONE_TIME: "One time",
  BALANCING_PAYMENT: "Balancing payment",
  AMENDMENT: "Amendment",
  CART: "Cart",
};

export const BillingSlider = ({
  openCheckoutModal,
}: {
  openCheckoutModal: (invoice: BillingInvoice) => void;
}) => {
  const { invoiceId } = useParams<{ invoiceId: string }>();
  const entityId = useCurrentEntityId();
  const group = useCurrentGroupContext();

  const { data: invoice } = useGetBillingInvoiceQuery(
    { invoiceId, groupId: group.uuid, entityId: entityId! },
    { skip: !invoiceId || !entityId || !group.uuid }
  );

  if (invoice) {
    return (
      <>
        <Slider.Header>
          <Slider.Title>{invoice.name}</Slider.Title>
          <Slider.Close />
        </Slider.Header>
        <Slider.Body>
          <SliderAccordion.Root
            type="multiple"
            defaultValue={["ABOUT", "DOCUMENTS"]}
            className="t-space-y-5"
          >
            <SliderAccordion.Item value="ABOUT">
              <SliderAccordion.Trigger>About</SliderAccordion.Trigger>
              <SliderAccordion.Content>
                <SliderAccordion.ItemGrid>
                  <div className="t-col-span-2">
                    <InfoItem label="Description">
                      {invoice.task ? (
                        <Link to={`/tax/filings/${invoice.task.uuid}`}>
                          {invoice.task.title}
                        </Link>
                      ) : (
                        invoice.short_name
                      )}
                    </InfoItem>
                  </div>

                  {invoice.paid_on && (
                    <div className="t-col-span-2">
                      <InfoItem label="Paid on">
                        {formatDate(invoice.paid_on)}
                      </InfoItem>
                    </div>
                  )}

                  {invoice.invoice_type && (
                    <div className="t-col-span-2">
                      <InfoItem label="Payment type">
                        {invoiceTypeMap[invoice.invoice_type]}
                      </InfoItem>
                    </div>
                  )}

                  {invoice.payment_card_details && (
                    <div className="t-col-span-2">
                      <InfoItem label="Payment method">
                        <Link to={`/settings/billing/paymentmethod?entity=`}>
                          <span className="t-capitalize">
                            {invoice.payment_card_details.brand}
                          </span>{" "}
                          ****
                          {invoice.payment_card_details.last_four}
                        </Link>
                      </InfoItem>
                    </div>
                  )}

                  {invoice.status && (
                    <div className="t-col-span-2">
                      <InfoItem label="Status">{invoice.status}</InfoItem>
                    </div>
                  )}

                  {Number(invoice.credits_used) > 0 && (
                    <div className="t-col-span-2">
                      <InfoItem label="Credits used">
                        {invoice.credits_used}
                      </InfoItem>
                    </div>
                  )}
                  {Number(invoice.amount) > 0 && (
                    <div className="t-col-span-2">
                      <InfoItem label="Total amount">
                        $ {invoice.amount}
                      </InfoItem>
                    </div>
                  )}
                </SliderAccordion.ItemGrid>
              </SliderAccordion.Content>
            </SliderAccordion.Item>

            <SliderAccordion.Item value="DOCUMENTS">
              <SliderAccordion.Trigger>Documents</SliderAccordion.Trigger>
              <SliderAccordion.Content>
                <SliderAccordion.ItemGrid>
                  {invoice.document && (
                    <div className="t-col-span-2">
                      <InfoItem label="Invoice">
                        <FilePreviewTag
                          block
                          file={invoice.document}
                          groupId={invoice.company_group.uuid}
                        >
                          <DownloadIcon color="currentColor" />
                        </FilePreviewTag>
                      </InfoItem>
                    </div>
                  )}

                  {invoice.receipt_document && (
                    <div className="t-col-span-2">
                      <InfoItem label="Receipt">
                        <FilePreviewTag
                          block
                          file={invoice.receipt_document}
                          groupId={invoice.company_group.uuid}
                        >
                          <DownloadIcon color="currentColor" />
                        </FilePreviewTag>
                      </InfoItem>
                    </div>
                  )}
                </SliderAccordion.ItemGrid>
              </SliderAccordion.Content>
            </SliderAccordion.Item>
          </SliderAccordion.Root>
        </Slider.Body>
        {invoice.status !== PAID && !isNaN(Number(invoice.amount)) && (
          <Slider.Footer>
            <Button
              customType="primary"
              block
              onClick={openCheckoutModal.bind(this, invoice)}
            >
              Pay &nbsp; <AmountSuperScript amount={Number(invoice.amount)} />{" "}
            </Button>
          </Slider.Footer>
        )}
      </>
    );
  }

  return (
    <Slider.Body className="t-h-full">
      <div className="t-h-full t-flex t-w-full t-justify-center t-items-center">
        <Loader />
      </div>
    </Slider.Body>
  );
};
