import classNames from "classnames";
import { ParentSelector } from "components/ChartOfAccounts/ParentSelector";
import { Avatar } from "components/DesignSystem/AvatarGroup/Avatar";
import { Button } from "components/DesignSystem/Button/Button";
import { Combobox } from "components/DesignSystem/Combobox/Combobox";
import Modal from "components/DesignSystem/Modal/Modal";
import { AmountSuperScript } from "components/design/AmountSuperScript";
import { TableUI } from "components/design/TableUI";
import ConditionalToolTip from "components/design/conditionalToolTip";
import Loader from "components/design/loader";
import { BankLogo } from "components/icons/BankLogo";
import { DD_MMM_YYYY } from "constants/date";
import dayjs from "dayjs";
import { Form, Formik, FormikHelpers } from "formik";
import { AnimatePresence, motion } from "framer-motion";
import { useChartOfAccounts } from "hooks/useChartOfAccounts";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useModal } from "hooks/useModal";
import { useToast } from "hooks/useToast";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  createColumnHelper,
  getCoreRowModel,
  OnChangeFn,
  Row,
  RowSelectionState,
  useReactTable,
} from "react-table-8.10.7";
import {
  useCategorizeGroupTransactionsMutation,
  useGetAllTransactionsDataQuery,
} from "store/apis/transactions";
import { setSelectedTransactionIds } from "store/slices/transactions";
import { RootState } from "store/store";
import { Transactions } from "types/Models/books";
import { BackendError } from "types/utils/error";
import { ModalProps } from "types/utils/modal";
import { flattenTypes } from "utils/flattenCOA";
import { object, string } from "yup";
import { MerchantComponent } from "./MerchantSelector";
import { RuleConfirmation } from "./RuleConfirmation";
import { coaOptions, getCOALabel } from "./TransactionColumn";
import { setRuleInfo } from "store/slices/ruleEngine";
import { Checkbox } from "components/DesignSystem/Checkbox/Checkbox";

const useCOA = () => {
  const { chartOfAccounts = [] } = useChartOfAccounts({
    hiddenCategory: ["BANK_ACCOUNT", "BANK_TRANSFER", "PAY_DOWN_CREDIT"],
  });

  return coaOptions(chartOfAccounts);
};

const Disclaimer = ({
  selectedCategory,
}: {
  selectedCategory: { value: string; label: string } | null;
}) => {
  const { uuid: groupId } = useCurrentGroupContext();
  const entityId = useCurrentEntityId();

  const { selectedTransactionIds } = useSelector(
    (store: RootState) => store.transactions
  );

  const { data: transactionData } = useGetAllTransactionsDataQuery(
    {
      groupId,
      entityId,
      transactionIds: selectedTransactionIds.join(","),
    },
    { skip: !groupId || !entityId, refetchOnMountOrArgChange: true }
  );

  const allCategorisedTransactions =
    transactionData?.transactions.filter(({ transaction: { category } }) =>
      Boolean(category?.uuid)
    ) || [];

  const allLinkedTransactions =
    transactionData?.transactions.filter(
      ({ transaction: { linked_transaction } }) => Boolean(linked_transaction)
    ) || [];

  const isAnyLinkedTransaction = allLinkedTransactions?.length > 0;
  const isAnyCategorisedTransaction = allCategorisedTransactions?.length > 0;

  return (
    <div className="t-text-body">
      You have assigned{" "}
      <motion.b
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ ease: "easeInOut", duration: 0.4 }}
        key={selectedCategory?.value}
      >
        {" "}
        "{selectedCategory?.label}"
      </motion.b>{" "}
      to {selectedTransactionIds.length} transactions
      {isAnyCategorisedTransaction && (
        <>
          {" "}
          that includes
          <b> {allCategorisedTransactions?.length} categorized transactions</b>
        </>
      )}
      {isAnyLinkedTransaction && (
        <>
          {" "}
          and
          <b> {allLinkedTransactions?.length} linked </b>
          transactions
        </>
      )}
      . Are you sure you want to proceed?
    </div>
  );
};

export const TransactionsView = ({
  transactions,
  rowSelection,
  setRowSelection,
  showCheckbox = false,
  disableUncategoriseTransaction,
}: {
  transactions: Transactions[];
  rowSelection?: RowSelectionState;
  setRowSelection?: OnChangeFn<RowSelectionState>;
  showCheckbox?: boolean;
  disableUncategoriseTransaction?: boolean;
}) => {
  const createColumn = createColumnHelper<Transactions>();

  const checkBoxColumn = showCheckbox
    ? [
        createColumn.display({
          id: "select",
          size: 1,
          enableSorting: false,
          header: ({ table }) => (
            <Checkbox
              {...{
                checked: table.getIsAllRowsSelected(),
                indeterminate: table.getIsSomeRowsSelected(),
                onChange: table.getToggleAllRowsSelectedHandler(),
              }}
            />
          ),

          cell: ({ row }) => {
            const disableCheckBox =
              disableUncategoriseTransaction &&
              !row.original.transaction.category;

            return (
              <ConditionalToolTip
                condition={
                  disableCheckBox && "This transaction has no category."
                }
              >
                <span>
                  <Checkbox
                    {...{
                      checked: row.getIsSelected(),
                      disabled: disableCheckBox,
                      indeterminate: row.getIsSomeSelected(),
                      onChange: row.getToggleSelectedHandler(),
                      key: row.id,
                    }}
                    onClick={(e) => e.stopPropagation()}
                  />
                </span>
              </ConditionalToolTip>
            );
          },
        }),
      ]
    : [];

  const columns = useMemo(
    () => [
      ...checkBoxColumn,
      createColumn.accessor("transaction.date", {
        size: 15,
        header: "DATE",
        cell: (info) => (
          <div className="t-py-3">
            {dayjs(info.getValue()).format(DD_MMM_YYYY)}
          </div>
        ),
      }),

      createColumn.accessor("transaction.from", {
        size: 15,
        header: () => (
          <div className="t-flex t-justify-center t-w-full">Source</div>
        ),
        cell: (info) => {
          const from = info.getValue();
          const { bank_account } = from || {};
          const { bank_brand } = bank_account || {};
          const { logo_url } = bank_brand || {};

          const mask = from?.bank_account?.mask
            ? `•••• ${from?.bank_account?.mask}`
            : "";

          return (
            <div className="t-flex t-items-center t-w-full t-justify-center">
              <ConditionalToolTip
                condition={
                  from?.bank_account &&
                  `${from?.bank_account?.nickname} ${mask}`
                }
              >
                <span>
                  {logo_url ? (
                    <Avatar
                      src={logo_url}
                      alt={from?.bank_account?.mask || "Bank"}
                    />
                  ) : (
                    <BankLogo />
                  )}
                </span>
              </ConditionalToolTip>
            </div>
          );
        },
      }),

      createColumn.accessor("transaction.merchant", {
        size: 25,
        header: "VENDOR",
        cell: (info) => {
          const {
            row: {
              original: {
                transaction: { logo, merchant },
              },
            },
          } = info;

          if (!merchant) {
            return "-";
          }

          return <MerchantComponent name={merchant} logo={logo} />;
        },
      }),

      createColumn.accessor("transaction.category", {
        size: 25,
        header: () => "CATEGORY",
        cell: (info) => {
          const {
            row: {
              original: {
                transaction: { category },
              },
            },
          } = info;

          if (!category) {
            return "-";
          }

          return <>{category?.name}</>;
        },
      }),

      createColumn.accessor("transaction.amount", {
        size: 20,
        header: () => <div className="t-flex t-justify-end">AMOUNT</div>,
        cell: (info) => {
          const amount = info.getValue();
          const {
            transaction: { is_credit_card },
          } = info.row.original || {};

          const isCredit = amount > 0;

          if (!amount) {
            return "-";
          }

          return (
            <div
              className={classNames("t-flex t-justify-end", {
                "t-text-dark_green-50": isCredit && !is_credit_card,
              })}
            >
              <AmountSuperScript amount={amount} />
            </div>
          );
        },
      }),
    ],
    []
  );

  const table = useReactTable({
    data: transactions || [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    enableRowSelection: showCheckbox,
    onRowSelectionChange: setRowSelection ?? (() => {}),
    state: {
      rowSelection,
    },
    getRowId: ({ transaction: { uuid } }) => uuid,
    defaultColumn: {
      size: 10,
      minSize: 1,
      maxSize: 100,
    },
  });

  return <TableUI table={table} />;
};

export const BulkCategorise = ({
  close,
  isOpen,
  deselectRows,
}: ModalProps & { deselectRows: () => void }) => {
  const dispatch = useDispatch();
  const flattenChartOfAccounts = useCOA();
  const { chartOfAccounts = [] } = useChartOfAccounts({
    hiddenCategory: ["BANK_ACCOUNT", "BANK_TRANSFER", "PAY_DOWN_CREDIT"],
  });

  const { successToast, alertToast } = useToast();
  const entityId = useCurrentEntityId();
  const { uuid: groupId } = useCurrentGroupContext();
  const [groupCategorize] = useCategorizeGroupTransactionsMutation();

  const {
    isOpen: isOpenConfirmation,
    open: openConfirmation,
    close: closeConfirmation,
  } = useModal();
  const { selectedTransactionIds } = useSelector(
    (store: RootState) => store.transactions
  );
  const [rowSelection, setRowSelection] = useState<RowSelectionState>({});

  const { data: transactionData, isLoading } = useGetAllTransactionsDataQuery(
    {
      groupId,
      entityId,
      transactionIds: selectedTransactionIds.join(","),
    },
    { skip: !groupId || !entityId, refetchOnMountOrArgChange: true }
  );

  const { transactions = [] } = transactionData || {};

  const merchants = transactions?.map(
    ({ transaction: { merchant_uuid } }) => merchant_uuid
  );

  const merchantIds = merchants.join(", ");

  const {
    close: closeParentSelectorModal,
    isOpen: isParentSelectorModalOpen,
    open: openParentSelectorModal,
  } = useModal();

  useEffect(() => {
    const selectedTxns = selectedTransactionIds.reduce(
      (accumulator, currentId, i) => {
        return { ...accumulator, [currentId]: true };
      },
      {}
    );
    setRowSelection(selectedTxns);
  }, [selectedTransactionIds]);

  const onClose = () => {
    close();
    dispatch(setSelectedTransactionIds([]));
  };

  const onSubmit = async (
    { category }: { category: string },
    { resetForm }: FormikHelpers<{ category: string }>
  ) => {
    try {
      const transactionIds = Object.keys(rowSelection).join(", ");

      await groupCategorize({
        groupId,
        entityId,
        payload: {
          transaction_category_uuid: category,
          transaction_ids: transactionIds,
        },
      }).unwrap();
      dispatch(setRuleInfo({ merchantIds, categoryId: category }));
      openConfirmation();
      onClose();
      successToast({
        message: "Transactions Categorised",
      });
    } catch (error) {
      alertToast({ message: (error as BackendError)?.data?.error?.message });
    }
  };

  return (
    <>
      <Modal.Root open={isOpen} onOpenChange={onClose}>
        <Formik
          initialValues={{ category: "" }}
          onSubmit={onSubmit}
          validateOnChange
          validateOnMount
          enableReinitialize
          validationSchema={object({
            category: string().required("Please select Category"),
          })}
        >
          {({
            submitForm,
            isSubmitting,
            isValid,
            values: { category },
            resetForm,
            setFieldValue,
          }) => {
            const flattenedCOAs = flattenTypes({
              accounts: chartOfAccounts,
            }).map((account) => {
              const label = getCOALabel({ account });

              return {
                value: account.uuid,
                label: label,
                data: label,
                isDisabled: account.topLevel,
              };
            });

            const selectedCategory =
              flattenedCOAs.find(({ value }) => value === category) || null;

            return (
              <Modal.Content
                useCustomOverlay
                size={Boolean(category) ? "xl" : "large"}
              >
                <Modal.Header>
                  <Modal.Title>Categorise transactions</Modal.Title>
                  <Modal.Close />
                </Modal.Header>
                <Modal.Body className="t-flex t-flex-col t-gap-6">
                  <Form className="t-m-0 t-flex t-flex-col t-gap-4">
                    <Combobox
                      withForm
                      name="category"
                      label="Category"
                      placeholder="Select account"
                      menuPortalTarget={document.body}
                      value={selectedCategory}
                      options={flattenChartOfAccounts}
                      filterOption={(v, i) =>
                        v.data.data
                          ?.toLocaleLowerCase()
                          .includes(i.toLocaleLowerCase()) || false
                      }
                      actions={
                        <Button
                          customType="link"
                          type="button"
                          onClick={openParentSelectorModal}
                        >
                          Add a new account
                        </Button>
                      }
                    />

                    {category && (
                      <AnimatePresence>
                        <Disclaimer selectedCategory={selectedCategory} />
                        <motion.div
                          layout
                          initial={{ opacity: 0, y: 100 }}
                          animate={{ opacity: 1, y: 0 }}
                          transition={{ ease: "easeInOut", duration: 0.5 }}
                        >
                          {isLoading ? (
                            <Loader />
                          ) : (
                            <TransactionsView
                              transactions={transactions}
                              rowSelection={rowSelection}
                              setRowSelection={setRowSelection}
                              showCheckbox
                            />
                          )}
                        </motion.div>
                      </AnimatePresence>
                    )}
                  </Form>
                </Modal.Body>
                <Modal.Footer>
                  <div className="t-flex t-justify-end t-gap-3">
                    <Button
                      type="reset"
                      onClick={() => {
                        resetForm();
                        onClose();
                      }}
                      disabled={isSubmitting}
                    >
                      Cancel
                    </Button>
                    <Button
                      customType="primary"
                      onClick={submitForm}
                      isLoading={isSubmitting}
                      disabled={isSubmitting || !isValid}
                    >
                      Categorise all
                    </Button>
                  </div>
                </Modal.Footer>
                <ParentSelector
                  isOpen={isParentSelectorModalOpen}
                  close={closeParentSelectorModal}
                  open={openParentSelectorModal}
                  onSuccess={({ uuid }) => {
                    setFieldValue("category", uuid);
                  }}
                />
              </Modal.Content>
            );
          }}
        </Formik>
      </Modal.Root>
      <RuleConfirmation
        isOpen={isOpenConfirmation}
        close={closeConfirmation}
        deselectRows={deselectRows}
      />
    </>
  );
};
