import { Button } from "components/DesignSystem/Button/Button";
import { LinkBankAccount } from "components/LinkBankAccount";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { useBankConnect } from "hooks/useBankConnect";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useRoleBasedView } from "hooks/useRoleBasedView";
import { useState } from "react";
import { useUpdateItemErrorStateMutation } from "store/apis/bankConnections";
import { Connection } from "store/apis/booksConnections";

dayjs.extend(relativeTime);

export const ReConnect = ({
  onConnect,
  connection,
  isLoading,
  type = "RECONNECT",
}: {
  connection: Connection & { direct_base_connection_id: string };
  onConnect: ReturnType<typeof useBankConnect>["onConnect"];
  isLoading: boolean;
  type?: "RECONNECT" | "UPDATE";
}) => {
  const { uuid: groupId } = useCurrentGroupContext();
  const entityId = useCurrentEntityId();
  const { isCpa } = useRoleBasedView();

  const [reconnectBankId, setReconnectBankId] = useState("");

  const [updateErrorItem, { isLoading: isUpdateItemErrorStateLoading }] =
    useUpdateItemErrorStateMutation();

  const onBankReconnect = (entityItemId: string) => () => {
    updateErrorItem({
      groupId,
      entityItemId,
    });
  };

  const buttonTxt = type === "RECONNECT" ? "Reconnect" : "Update";

  if (connection.connection_type === "PLAID") {
    const connectionId = connection.uuid;
    return (
      <LinkBankAccount
        entityId={entityId}
        onBankReconnect={onBankReconnect(connectionId)}
        groupId={groupId}
        reconnectBankId={connectionId}
      >
        {({ connectPlaid, ready }) => (
          <Button
            customType="danger"
            disabled={
              !ready ||
              (isUpdateItemErrorStateLoading &&
                reconnectBankId === connectionId)
            }
            size="small"
            onClick={(e) => {
              if (document.body) {
                document.body.style.pointerEvents = "auto";
              }
              connectPlaid(e);
              setReconnectBankId(connectionId);
              e.stopPropagation();
            }}
          >
            {isUpdateItemErrorStateLoading &&
            reconnectBankId === connectionId ? (
              <span className="t-flex t-items-center t-justify-center t-gap-2">
                <i className="fas fa-spinner fa-spin" />
                {type === "RECONNECT" ? "Reconnecting..." : "Updating..."}
              </span>
            ) : (
              buttonTxt
            )}
          </Button>
        )}
      </LinkBankAccount>
    );
  }

  return (
    <Button
      customType="danger"
      size="small"
      isLoading={isLoading}
      disabled={isLoading}
      onClick={(e) => {
        e.stopPropagation();
        onConnect({
          connectionId: connection.direct_base_connection_id,
          invokedFrom: isCpa
            ? `/books/data-sources?company=${groupId}&entity=${entityId}`
            : `/books/data-sources?entity=${entityId}`,
          entityItemId: connection.uuid,
        });
      }}
    >
      {buttonTxt}
    </Button>
  );
};
