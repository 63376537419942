import classNames from "classnames";
import { Button } from "components/DesignSystem/Button/Button";
import Dropdown from "components/DesignSystem/Dropdown/Dropdown";
import { ArrowRight } from "components/icons/ArrowRight";
import { ChangeEvent, ReactNode, useContext, useId, useState } from "react";
import FunnelIcon from "static/images/Funnel.svg";
import { FilterContext } from "./FilterContext";
import { Search } from "../Search/Search";
import { Checkbox } from "../Checkbox/Checkbox";
import InfiniteScroll from "react-infinite-scroll-component";
import { LoadingIcon } from "components/icons/LoadingIcon";
import DashboardContainer from "components/dashboard/DashboardContainer";

const List = ({ children }: { children: ReactNode }) => {
  return (
    <div className="t-min-w-[220px] t-py-5 t-px-3 t-flex t-flex-col t-rounded-l-md t-bg-surface-lighter-grey t-gap-1 t-border-solid t-border-r t-border-l-0 t-border-b-0 t-border-t-0 t-border-purple-10">
      {children}
    </div>
  );
};

const Body = ({
  children,
  value,
  block = false,
}: {
  children: ReactNode;
  value: string;
  block?: boolean;
}) => {
  const { value: selectedListItem } = useContext(FilterContext);
  if (selectedListItem === value) {
    return (
      <div
        className={classNames("t-p-6 t-rounded-r-md t-overflow-auto", {
          "t-w-full": block,
        })}
      >
        {children}
      </div>
    );
  }
  return null;
};

const Portal = ({
  children,
  size = "regular",
}: {
  children: ReactNode;
  size?: "small" | "regular";
}) => {
  return (
    <Dropdown.Content
      sideOffset={8}
      align="start"
      side="bottom"
      className={classNames(
        "t-flex t-w-[600px] t-rounded-md t-shadow-filter-shadow t-border t-border-solid !t-border-purple-10",
        {
          "t-h-[360px]": size === "small",
          "t-h-[440px]": size === "regular",
        }
      )}
    >
      {children}
    </Dropdown.Content>
  );
};

const Root = ({
  children,
  defaultValue,
  title = "Filters",
  capsule,
}: {
  children: ReactNode;
  defaultValue: string;
  title?: ReactNode;
  capsule?: ReactNode;
}) => {
  const [value, setValue] = useState(defaultValue);
  const [open, setOpen] = useState(false);

  const onSelect = (value: string) => {
    if (value) {
      setValue(value);
    }
  };

  return (
    <FilterContext.Provider value={{ value, onSelect, setOpen, open }}>
      <Dropdown.Root open={open} onOpenChange={setOpen} modal={false}>
        <div className="t-w-fit t-flex t-gap-3 t-h-full t-flex-wrap">
          <Dropdown.Trigger asChild>
            <div className="t-w-fit">
              <Button size="small">
                <div className="t-flex t-gap-1.5 t-select-none">
                  <img src={FunnelIcon} alt="filter" />
                  <div className="t-text-text-60">{title}</div>
                </div>
              </Button>
            </div>
          </Dropdown.Trigger>
          {capsule && (
            <div className="t-border-0 t-border-l t-border-solid t-border-neutral-10 t-mt-1 t-h-6" />
          )}
          {capsule}
        </div>
        <Dropdown.Portal>{children}</Dropdown.Portal>
      </Dropdown.Root>
    </FilterContext.Provider>
  );
};

const ListItem = ({
  children,
  value,
}: {
  children: ReactNode;
  value: string;
}) => {
  const { value: selectedListItem, onSelect } = useContext(FilterContext);
  const isSelected = Boolean(selectedListItem === value);

  return (
    <Dropdown.Item
      onSelect={(e) => {
        e.preventDefault();
        onSelect(value);
      }}
      textValue=""
      className={classNames(
        "all:unset !t-px-4 t-py-2 t-h-[34px] t-items-center t-rounded !t-text-body t-flex t-justify-between hover:!t-bg-purple-10 t-transform t-transition-colors t-duration-500 t-ease-in-out",
        {
          "!t-bg-purple-10 t-text-text-100": isSelected,
          "t-text-text-60": !isSelected,
        }
      )}
    >
      <div>{children}</div>

      <div
        className={classNames(
          "t-transform -t-translate-x-4 t-transition-all t-duration-300 t-ease-in-out",
          { "t-translate-x-0": isSelected }
        )}
      >
        {isSelected && <ArrowRight color="black" stroke="1.4" />}
      </div>
    </Dropdown.Item>
  );
};

type Option = { label: string; value: string };

export const MultiSelectFilter = ({
  options,
  selected = [],
  onChange,
  totalCount,
  loadNext,
  hasMore,
  onSearchValueChange,
}: {
  options: Option[];
  selected?: string[];
  onChange: (newValues: string[]) => void;
  hasMore?: boolean;
  loadNext?: () => void;
  totalCount?: number;
  onSearchValueChange?: (searchValue: string) => void;
}) => {
  const [searchValue, setSearch] = useState("");
  const infiniteScrollId = useId();
  const addOrRemoveOption = (e: ChangeEvent<HTMLInputElement>) => {
    const currentOption = options.find(({ value }) => value === e.target.name)!;

    if (e.target.checked) {
      const newSelected = new Set([...selected, currentOption.value]);
      return onChange(Array.from(newSelected));
    }

    const selectedOptionsExcludingCurrent = selected.filter(
      (v) => v !== currentOption?.value
    );

    onChange(selectedOptionsExcludingCurrent);
  };

  const onSearch = (e: ChangeEvent<HTMLInputElement>) => {
    if (onSearchValueChange) {
      return onSearchValueChange(e.target.value);
    }

    setSearch(e.target.value);
  };

  const filterOptions = options.filter((o) =>
    searchValue
      ? o.label.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase())
      : true
  );

  return (
    <DashboardContainer className="t-h-full t-gap-4">
      <DashboardContainer.Header>
        <Search onChange={onSearch} placeholder="Search" className="t-w-full" />
      </DashboardContainer.Header>
      <DashboardContainer.Content>
        {filterOptions.length === 0 ? (
          <div className="t-flex t-justify-center t-items-center t-h-40 t-text-text-60">
            No results found
          </div>
        ) : (
          <div className="t-h-full t-overflow-y-auto" id={infiniteScrollId}>
            <InfiniteScroll
              dataLength={totalCount || 0}
              next={loadNext || function () {}}
              hasMore={hasMore || false}
              scrollableTarget={infiniteScrollId}
              className="t-space-y-5"
              loader={
                <div className="t-flex t-justify-center t-items-center t-w-full t-gap-2 t-p-2">
                  <span className="t-flex t-origin-center t-animate-spin">
                    <LoadingIcon />
                  </span>
                  Loading...
                </div>
              }
            >
              {filterOptions.map(({ value, label }) => (
                <Checkbox
                  key={value}
                  onChange={addOrRemoveOption}
                  name={value}
                  label={label}
                  checked={selected.includes(value)}
                />
              ))}
            </InfiniteScroll>
          </div>
        )}
      </DashboardContainer.Content>
    </DashboardContainer>
  );
};

export const Filter = {
  Root: Root,
  Portal: Portal,
  List: List,
  ListItem: ListItem,
  Body: Body,
};
