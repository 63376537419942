import qs from "qs";
import { FiltersValues } from "store/slices/transactionFilter";
import {
  SourceFilter,
  SplitTransaction,
  Transaction,
  TransactionResponse,
  Transactions,
  TxnAccountType,
} from "types/Models/books";
import { FileObject } from "types/Models/fileObject";
import { CSVParsedResponse, CSVResponse } from "types/Models/reconciliation";
import { emptyApi } from "./emptyApi";

type FilterValue = {
  value: boolean | string | null | string[];
};
export type TransactionQuery = {
  groupId: string;
  entityId: string;
  page_num?: number;
  searchTerm?: string | null;
  transactionIds?: string;
  startDate?: FilterValue;
  endDate?: FilterValue;
  fromIds?: FilterValue;
  manualEntry?: FilterValue;
  bankStatement?: FilterValue;
  vendors?: FilterValue;
  categoryIds?: FilterValue;
  uncategorized?: FilterValue;
  cashFlow?: FilterValue;
  minAmount?: string;
  maxAmount?: string;
  hasMemo?: FilterValue;
  hasComments?: FilterValue;
  hasInvoices?: FilterValue;
  sortCol?: "AMOUNT" | "DATE" | "VENDOR";
  sortOrder?: "ASC" | "DSC";
  hasNoVendors?: FilterValue;
  splitTransactions?: FilterValue;
  fetch_page_num?: boolean;
  jump_txn_id?: string;
  notReviewed?: FilterValue;
  txnAccountType?: TxnAccountType;
  pageSize?: string | null;
  hasInvoiceOrMemo?: FilterValue;
  messageId?: string;
  txnStatus?: "EXCLUDED";
};
export const transactionApi = emptyApi.injectEndpoints({
  endpoints: (build) => ({
    getAllTransactionsData: build.query<TransactionResponse, TransactionQuery>({
      query: ({
        groupId,
        entityId,
        page_num,
        searchTerm,
        transactionIds,
        startDate,
        endDate,
        fromIds,
        manualEntry,
        bankStatement,
        vendors,
        categoryIds,
        uncategorized,
        cashFlow,
        minAmount,
        maxAmount,
        hasMemo,
        hasComments,
        hasInvoices,
        sortCol,
        sortOrder,
        hasNoVendors,
        splitTransactions,
        fetch_page_num,
        jump_txn_id,
        notReviewed,
        txnAccountType,
        pageSize,
        hasInvoiceOrMemo,
        messageId,
        txnStatus,
      }) => {
        const conditionalPageSize = Math.max(
          transactionIds?.split(",").length || 0,
          20
        );

        let queryUrl = qs.stringify(
          {
            page_num,
            search_term: searchTerm,
            transaction_ids: transactionIds,
            start_date: startDate?.value,
            end_date: endDate?.value,
            journal_entry: manualEntry?.value,
            bank_statement: bankStatement?.value,
            //@ts-ignore
            from_ids: fromIds?.value?.join(","),
            //@ts-ignore
            merchants: vendors?.value?.join(","),
            //@ts-ignore
            category_ids: categoryIds?.value?.join(","),
            uncategorized: uncategorized?.value,
            cash_flow: cashFlow?.value,
            min_amount: minAmount,
            max_amount: maxAmount,
            has_memo: hasMemo?.value,
            has_comments: hasComments?.value,
            has_invoices: hasInvoices?.value,
            has_no_merchants: hasNoVendors?.value,
            has_split_transactions: splitTransactions?.value,
            sort_col: sortCol,
            sort_order: sortOrder,
            not_reviewed: notReviewed?.value,
            fetch_page_num,
            jump_txn_id,
            txn_account_type: txnAccountType || null,
            page_size: pageSize || conditionalPageSize,
            has_invoice_or_memo: hasInvoiceOrMemo?.value,
            message_id: messageId,
            txn_status: txnStatus,
          },
          {
            filter: (prefix, value) =>
              value === false || value === "" ? undefined : value,
            skipNulls: true,
          }
        );
        return {
          url: `/api/inkle/bookkeeping/group/${groupId}/entity/${entityId}/entity_transactions/`,
          params: {
            ...qs.parse(queryUrl),
          },
        };
      },
      providesTags: (result) => (result ? ["BOOKS_TRANSACTIONS"] : []),
    }),

    getTransactionData: build.query<
      Transactions,
      {
        groupId: string;
        entityId: string;
        transactionId: string;
      }
    >({
      query: ({ groupId, entityId, transactionId }) => {
        let queryUrl = qs.stringify(
          { transaction_id: transactionId },
          { skipNulls: true, addQueryPrefix: true }
        );
        return {
          url: `/api/inkle/bookkeeping/group/${groupId}/entity/${entityId}/entity_transaction/${queryUrl}`,
        };
      },
      providesTags: (result) => (result ? ["BOOKS_SINGLE_TRANSACTION"] : []),
    }),

    editTransaction: build.mutation<
      Transactions,
      {
        groupId: string;
        entityId: string;
        transactionId: string;
        date?: string;
        amount?: string;
        account_id?: string;
        description?: string;
        merchant_data_id?: string;
        transaction_category_id?: string;
        invoice_mapping_id?: string;
        invoice_status?: string;
      }
    >({
      query: ({ groupId, entityId, transactionId, ...restPayload }) => {
        const formData = new FormData();
        Object.entries(restPayload).forEach((payload) =>
          formData.append(payload[0], payload[1])
        );

        return {
          url: `/api/inkle/bookkeeping/group/${groupId}/entity/${entityId}/entity_transaction/${transactionId}/`,
          method: "PATCH",
          body: formData,
        };
      },
      invalidatesTags: (result) =>
        result
          ? [
              "BOOKS_TRANSACTIONS",
              "BOOKS_SINGLE_TRANSACTION",
              "GENERAL_LEDGER",
              "LIVE_REPORT",
            ]
          : [],
    }),

    deleteTransaction: build.mutation<
      void,
      {
        groupId: string;
        entityId: string;
        transactionId: string;
        event_update_type?: string;
      }
    >({
      query: ({ groupId, entityId, transactionId, event_update_type }) => {
        return {
          url: `/api/inkle/bookkeeping/group/${groupId}/entity/${entityId}/entity_transaction/`,
          method: "DELETE",
          params: {
            event_update_type,
            transaction_ids: transactionId,
          },
          body: {
            event_update_type,
          },
        };
      },
      invalidatesTags: (result) =>
        result
          ? ["BOOKS_TRANSACTIONS", "GENERAL_LEDGER", "JOURNAL_ENTRIES"]
          : [],
    }),

    updateTransaction: build.mutation<
      Transactions,
      {
        groupId: string;
        entityId: string;
        previewTxnId: string;
        payload: {
          transaction_category_uuid: string | null;
          memo?: string | null;
          linked_transaction_uuid?: string | null;
          ai_category_confirmation?: boolean;
          invoice_mapping_id?: string;
          invoice_status?: "AUTO_MATCH_CONFIRM";
        };
      }
    >({
      query: ({ groupId, entityId, previewTxnId, payload }) => {
        let queryUrl = qs.stringify(
          {
            transaction_id: previewTxnId,
          },
          { skipNulls: true, addQueryPrefix: true }
        );
        return {
          url: `/api/inkle/bookkeeping/group/${groupId}/entity/${entityId}/entity_transaction/${queryUrl}`,
          method: "PUT",
          body: payload,
        };
      },
      invalidatesTags: (result) =>
        result
          ? [
              "BOOKS_TRANSACTIONS",
              "BOOKS_SINGLE_TRANSACTION",
              "GENERAL_LEDGER",
              "LIVE_REPORT",
            ]
          : [],
    }),

    addTransactionComment: build.mutation<
      Transactions,
      { groupId: string; previewTxnId: string; payload: any }
    >({
      query: ({ groupId, previewTxnId, payload }) => {
        return {
          url: `/api/inkle/bookkeeping/group/${groupId}/transaction/${previewTxnId}/comment/`,
          method: "POST",
          body: payload,
        };
      },
      invalidatesTags: (result) =>
        result ? ["BOOKS_SINGLE_TRANSACTION", "GENERAL_LEDGER"] : [],
    }),

    addTransactionInvoice: build.mutation<
      Transactions,
      { groupId: string; previewTxnId: string; files: any }
    >({
      query: ({ groupId, previewTxnId, files }) => {
        const formData = new FormData();
        files.forEach((file: File) => {
          formData.append("invoices", file);
        });
        return {
          url: `/api/inkle/bookkeeping/group/${groupId}/transaction/${previewTxnId}/invoice/`,
          method: "POST",
          body: formData,
        };
      },
      invalidatesTags: (result) =>
        result
          ? ["BOOKS_TRANSACTIONS", "BOOKS_SINGLE_TRANSACTION", "GENERAL_LEDGER"]
          : [],
    }),

    deleteTransactionInvoice: build.mutation<
      Transactions,
      { groupId: string; previewTxnId: string; payload: any }
    >({
      query: ({ groupId, previewTxnId, payload }) => {
        return {
          url: `/api/inkle/bookkeeping/group/${groupId}/transaction/${previewTxnId}/invoice/`,
          method: "DELETE",
          body: payload,
        };
      },
      invalidatesTags: (result) =>
        result
          ? ["BOOKS_TRANSACTIONS", "BOOKS_SINGLE_TRANSACTION", "GENERAL_LEDGER"]
          : [],
    }),

    getAllMerchants: build.query<
      { merchants: string[]; total_pages: number; current_page: number },
      {
        groupId: string;
        entityId: string;
        pageNum?: number;
        searchTerm?: string | null;
      }
    >({
      query: ({ groupId, entityId, pageNum, searchTerm }) => {
        let queryUrl = qs.stringify(
          { page_num: pageNum, search_term: searchTerm },
          { skipNulls: true, addQueryPrefix: true }
        );
        return {
          url: `/api/inkle/bookkeeping/group/${groupId}/entity/${entityId}/merchant_filter/${queryUrl}`,
        };
      },
    }),

    getAllAccount: build.query<
      SourceFilter[],
      {
        groupId: string;
        entityId: string;
        account_type?: "STRIPE" | "EXCLUDE_STRIPE" | "ALL";
      }
    >({
      query: ({ groupId, entityId, account_type }) => {
        return {
          url: `/api/inkle/bookkeeping/group/${groupId}/entity/${entityId}/account_filter/`,
          params: { account_type },
        };
      },
    }),

    pushToChat: build.mutation<
      { message_id: string },
      {
        groupId: string;
        payload: {
          channel_url: string;
          custom_type: string;
          transaction_ids: string;
          message?: string;
        };
        entityId: string;
      }
    >({
      query: ({ groupId, payload, entityId }) => {
        return {
          url: `/api/inkle/bookkeeping/group/${groupId}/entity/${entityId}/push_to_chat/`,
          method: "POST",
          body: payload,
        };
      },
    }),

    uploadCSVTransaction: build.mutation<
      CSVParsedResponse,
      {
        groupId: string;
        accountId: string;
        csv: File;
        entityId: string;
      }
    >({
      query: ({ groupId, accountId, entityId, csv }) => {
        const formData = new FormData();
        formData.append("account_id", accountId);
        formData.append("csv", csv);

        return {
          url: `/api/inkle/bookkeeping/group/${groupId}/entity/${entityId}/manual-transaction/csv/`,
          method: "POST",
          body: formData,
        };
      },
    }),

    populateCSVTransactions: build.mutation<
      CSVResponse[],
      {
        groupId: string;
        entityId: string;
        date_field: string;
        description_field: string;
        merchant_field: string;
        amount_field: string;
        credit_field: string;
        debit_field: string;
        memo_field: string;
        bank_csv_id: string;
      }
    >({
      query: ({ groupId, entityId, ...restPayload }) => {
        const formData = new FormData();
        Object.entries(restPayload).forEach((payload) =>
          formData.append(payload[0], payload[1])
        );

        return {
          url: `/api/inkle/bookkeeping/group/${groupId}/entity/${entityId}/manual-transaction/csv/`,
          method: "PUT",
          body: formData,
        };
      },
      invalidatesTags: (result) => (result ? ["BOOKS_TRANSACTIONS"] : []),
    }),

    uploadManualTransactionInvoice: build.mutation<
      FileObject,
      {
        groupId: string;
        entityId: string;
        invoice: File;
      }
    >({
      query: ({ groupId, entityId, invoice }) => {
        const formData = new FormData();
        formData.append("invoice", invoice);
        return {
          url: `/api/inkle/bookkeeping/group/${groupId}/entity/${entityId}/manual-transaction/invoice/`,
          method: "PUT",
          body: formData,
        };
      },
    }),

    addManualTransactions: build.mutation<
      FileObject,
      {
        groupId: string;
        entityId: string;
        accountId: string;
        transactions: {
          date: string;
          description: string;
          amount: number;
          merchant_data_id: string;
          transaction_category_id: string;
          invoice_id: string | null;
        }[];
      }
    >({
      query: ({ groupId, entityId, accountId, transactions }) => {
        return {
          url: `/api/inkle/bookkeeping/group/${groupId}/entity/${entityId}/manual-transaction/`,
          method: "POST",
          body: { account_id: accountId, transactions },
        };
      },
      invalidatesTags: (result) => (result ? ["BOOKS_TRANSACTIONS"] : []),
    }),

    categorizeGroupTransactions: build.mutation<
      Transactions,
      {
        groupId: string;
        entityId: string;
        payload: {
          transaction_ids?: string;
          merchant_data_uuid?: string;
          transaction_category_uuid: string | null;
          previous_assigned_category_id?: string | null;
        };
      }
    >({
      query: ({ groupId, entityId, payload }) => {
        return {
          url: `/api/inkle/bookkeeping/group/${groupId}/entity/${entityId}/entity_transactions/`,
          method: "PUT",
          body: payload,
        };
      },
      invalidatesTags: (result) => (result ? ["BOOKS_TRANSACTIONS"] : []),
    }),
    splitTransactions: build.mutation<
      Transactions,
      {
        groupId: string;
        entityId: string;
        transactionId: string;
        transactions: {
          description: string;
          amount: number;
          merchant_id?: string | null;
          category_id: string;
          invoice_id: string | null;
        }[];
      }
    >({
      query: ({ groupId, entityId, transactionId, transactions }) => {
        return {
          url: `/api/inkle/bookkeeping/group/${groupId}/entity/${entityId}/transaction/${transactionId}/split_transactions/`,
          method: "POST",
          body: { split_transactions: transactions },
        };
      },
      invalidatesTags: (result) => (result ? ["BOOKS_TRANSACTIONS"] : []),
    }),
    updateSplitTransactions: build.mutation<
      Transactions,
      {
        groupId: string;
        entityId: string;
        transactionId: string;
        transactions: {
          description: string;
          amount: number;
          merchant_id?: string | null;
          category_id: string;
          invoice_id: string | null;
        }[];
      }
    >({
      query: ({ groupId, entityId, transactionId, transactions }) => {
        return {
          url: `/api/inkle/bookkeeping/group/${groupId}/entity/${entityId}/transaction/${transactionId}/split_transactions/`,
          method: "PUT",
          body: { split_transactions: transactions },
        };
      },
      invalidatesTags: (result) => (result ? ["BOOKS_TRANSACTIONS"] : []),
    }),
    deleteSplitTransactions: build.mutation<
      Transactions,
      {
        groupId: string;
        entityId: string;
        transactionId: string;
      }
    >({
      query: ({ groupId, entityId, transactionId }) => {
        return {
          url: `/api/inkle/bookkeeping/group/${groupId}/entity/${entityId}/transaction/${transactionId}/split_transactions/`,
          method: "DELETE",
        };
      },
      invalidatesTags: ["BOOKS_TRANSACTIONS"],
    }),
    getAllSplitTransactions: build.query<
      SplitTransaction[],
      {
        groupId: string;
        entityId: string;
        transactionId: string;
      }
    >({
      query: ({ groupId, entityId, transactionId }) => {
        return {
          url: `/api/inkle/bookkeeping/group/${groupId}/entity/${entityId}/transaction/${transactionId}/split_transactions/`,
        };
      },
    }),

    updateMultipleTransactionsData: build.mutation<
      Transaction[],
      {
        groupId: string;
        entityId: string;
        transactions?: {
          uuid: string;
          category_id: string;
          merchant_id?: string;
          description?: string;
        }[];
      }
    >({
      query: ({ groupId, entityId, transactions }) => {
        return {
          url: `/api/inkle/bookkeeping/group/${groupId}/entity/${entityId}/transactions_update/`,
          method: "PUT",
          body: { transactions },
        };
      },
      invalidatesTags: (result) => (result ? ["MONEY_IN_TRANSIT"] : []),
    }),

    bulkAssignMerchant: build.mutation<
      {
        total_transactions_updated: number;
        transactions_updated: {
          merchant_data: {
            name: string;
            logo: string;
            uuid: string;
          };
          transaction_uuid: string;
        }[];
      },
      {
        groupId: string;
        entityId: string;
        payload: {
          transaction_ids: string;
          entity_merchant_uuid: string;
        };
      }
    >({
      query: ({ groupId, entityId, payload }) => {
        return {
          url: `/api/inkle/bookkeeping/group/${groupId}/entity/${entityId}/merchants_assign/`,
          method: "PUT",
          body: payload,
        };
      },
      invalidatesTags: (result) => (result ? ["BOOKS_TRANSACTIONS"] : []),
    }),

    refreshTransactions: build.mutation<
      void,
      {
        groupId: string;
        entityId: string;
      }
    >({
      query: ({ groupId, entityId }) => {
        return {
          url: `/api/inkle/bookkeeping/group/${groupId}/entity/${entityId}/refresh_transactions/`,
          method: "PUT",
        };
      },
      invalidatesTags: (result) => (result ? ["BOOKS_TRANSACTIONS"] : []),
    }),
    refreshReceipts: build.mutation<
      void,
      {
        groupId: string;
        entityId: string;
        payload: {
          from_date: string;
          to_date: string;
        };
      }
    >({
      query: ({ groupId, entityId, payload }) => {
        return {
          url: `/api/inkle/bookkeeping/group/${groupId}/entity/${entityId}/refresh_receipts/`,
          method: "PUT",
          body: payload,
        };
      },
    }),

    changeTransactionStatus: build.mutation<
      void,
      {
        entityId: string;
        payload: {
          transaction_ids: string[];
          transaction_status: "EXCLUDED" | "RESTORED";
        };
      }
    >({
      query: ({ entityId, payload }) => {
        return {
          url: `/api/inkle/bookkeeping/entity/${entityId}/txn_status/`,
          method: "PUT",
          body: payload,
        };
      },
      invalidatesTags: (result) =>
        result
          ? [
              "BOOKS_TRANSACTIONS",
              "BOOKS_SINGLE_TRANSACTION",
              "GENERAL_LEDGER",
              "LIVE_REPORT",
            ]
          : [],
    }),
  }),
});

export const {
  useGetAllTransactionsDataQuery,
  useLazyGetAllTransactionsDataQuery,
  useGetTransactionDataQuery,
  useUpdateTransactionMutation,
  useAddTransactionCommentMutation,
  useAddTransactionInvoiceMutation,
  useDeleteTransactionInvoiceMutation,
  useGetAllMerchantsQuery,
  useGetAllAccountQuery,
  usePushToChatMutation,
  useUploadCSVTransactionMutation,
  usePopulateCSVTransactionsMutation,
  useUploadManualTransactionInvoiceMutation,
  useAddManualTransactionsMutation,
  useEditTransactionMutation,
  useDeleteTransactionMutation,
  useCategorizeGroupTransactionsMutation,
  useSplitTransactionsMutation,
  useDeleteSplitTransactionsMutation,
  useUpdateSplitTransactionsMutation,
  useGetAllSplitTransactionsQuery,
  useUpdateMultipleTransactionsDataMutation,
  useBulkAssignMerchantMutation,
  useRefreshTransactionsMutation,
  useRefreshReceiptsMutation,
  useChangeTransactionStatusMutation,
} = transactionApi;
