import classNames from "classnames";
import Async from "components/DesignSystem/AsyncComponents/Async";
import { Avatar } from "components/DesignSystem/AvatarGroup/Avatar";
import { Button } from "components/DesignSystem/Button/Button";
import { Checkbox } from "components/DesignSystem/Checkbox/Checkbox";
import { Pagination } from "components/DesignSystem/Pagination/Pagination";
import { Search } from "components/DesignSystem/Search/Search";
import Table from "components/DesignSystem/Table/V2/Table";
import { RequestInfo } from "components/Transaction/RequestInfo";
import DashboardContainer from "components/dashboard/DashboardContainer";
import { LoadingToast } from "components/design/LoadingToast";
import { MagnifyingGlass } from "components/icons/MagnifyingGlass";
import { VendorDefault } from "components/icons/VendorDefault";
import { DD_MMM_YYYY } from "constants/date";
import dayjs from "dayjs";
import { AnimatePresence, motion } from "framer-motion";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useModal } from "hooks/useModal";
import { usePageTitle } from "hooks/usePageTitle";
import { usePagination } from "hooks/usePagination";
import { useQuery, useUpdateQuery } from "hooks/useQuery";
import { useToast } from "hooks/useToast";
import { parse, stringify } from "qs";
import React, {
  ChangeEvent,
  Fragment,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useDrag } from "react-dnd";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import {
  Row,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "react-table-8.10.7";
import { useAddOpenItemsMutation } from "store/apis/openItem";
import {
  useGetAllTransactionsDataQuery,
  useRefreshReceiptsMutation,
  useRefreshTransactionsMutation,
} from "store/apis/transactions";
import {
  setMessageIdForTransactionList,
  setPopulateTransactionIDs,
} from "store/slices/chat";
import { setFiltersAplied } from "store/slices/transactionCardPreview";
import { resetTxnFilters, setTxnFilters } from "store/slices/transactionFilter";
import {
  openSlider,
  setSelectedTransactionIds,
} from "store/slices/transactions";
import { setTransactionsInChat } from "store/slices/transactionsInChat";
import { RootState } from "store/store";
import { Transactions, TxnAccountType } from "types/Models/books";
import { BackendError } from "types/utils/error";
import { debounce } from "utils/debouncing";
import { EmptyScreen } from "../../pages/Books/EmptyScreen";
import { AddTransactionOptions } from "./AddTransaction/AddTransactionOptions";
import { AutoAssignModal } from "./AutoAssignModal";
import { TransactionSlider } from "./Slider/TransactionSlider";
import { TableSelectionControls } from "./TableSelectionControls";
import TransactionColumn from "./TransactionColumn";
import { RuleConfirmation } from "./RuleConfirmation";
import { SplitTransaction } from "./SplitTransaction/SplitTransaction";
import { useLazyExportTxnQuery } from "store/apis/books";
import ConditionalToolTip from "components/design/conditionalToolTip";
import { getFilterStatus } from "store/selector/transactionFilter";
import { useAppDispatch } from "hooks/useAppDispatch";
import { Sync } from "components/icons/Sync";
import { useWindowSize } from "hooks/useWindowSize";
import { useRoleBasedView } from "hooks/useRoleBasedView";
import Dropdown from "components/DesignSystem/Dropdown/Dropdown";
import { CaretDown } from "components/icons/CaretDown";
import { FileArrowDown } from "components/icons/FileArrowDown";
import Modal from "components/DesignSystem/Modal/Modal";
import { Combobox } from "components/DesignSystem/Combobox/Combobox";
import { duration } from "@mui/material";
import { Info } from "components/icons/Info";
import { InfoFilled } from "components/icons/InfoFilled";
import { InfoFilledSmall } from "components/icons/InfoFilledSmall";
import { Field, FieldProps, Form, Formik, useFormikContext } from "formik";
import { divMod } from "stream-chat-react";
import { DateInput } from "components/DesignSystem/DateInput/DateInput";
import { DateRangeValue, getDateRange } from "utils/getDateRange";
import { datePeriod } from "constants/bookkeeping";
import { currency } from "utils/Currency";

const AnimateRow = ({
  row,
  onAnimateComplete,
}: {
  row: Row<Transactions>;
  onAnimateComplete(): void;
}) => {
  const content = useRef<null | HTMLTableRowElement>(null);
  const [refAvailable, setRefAvailable] = useState(false);

  return (
    <motion.tr
      className={classNames(
        "t-cursor-pointer t-absolute t-overflow-hidden t-rounded-md t-z-[9999] t-w-14 t-h-14"
      )}
      ref={(ref) => {
        content.current = ref;
        setRefAvailable(true);
      }}
      animate={
        refAvailable
          ? {
              opacity: [null, 0],
              x: [
                null,
                document.body.getBoundingClientRect().right -
                  200 -
                  (content.current?.getBoundingClientRect()?.right ?? 0),
              ],
              y: [
                null,
                document.body.getBoundingClientRect().bottom -
                  32 -
                  (content.current?.getBoundingClientRect()?.bottom || 0),
              ],
            }
          : {}
      }
      transition={{
        type: "tween",
        duration: 0.6,
        ease: "easeInOut",
      }}
      onAnimationComplete={() => {
        onAnimateComplete();
      }}
    >
      {row.getVisibleCells().map((cell: any) => {
        if (!cell.id.includes("merchant")) {
          return null;
        }

        return (
          <td
            key={cell.id}
            style={{
              width: "100px",
              opacity: cell?.id?.includes("merchant") ? 1 : 0,
            }}
          >
            <div className="t-flex t-gap-2 t-items-center t-border t-border-solid t-border-neutral-30 t-w-7 t-h-7 t-justify-center t-rounded-full">
              {row.original.transaction.merchant ? (
                <Avatar
                  src={row.original.transaction.logo || ""}
                  alt={row.original.transaction.merchant}
                />
              ) : (
                <VendorDefault />
              )}
            </div>
          </td>
        );
      })}
    </motion.tr>
  );
};

const DraggableRow = (
  props: React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLTableRowElement>,
    HTMLTableRowElement
  > & { row: Row<Transactions> }
) => {
  const query = useQuery();
  const selectedTransactionId = query.get("selected_transaction_id");
  const { sliderTransactions } = useSelector(
    (state: RootState) => state?.transactions
  );

  const { transaction } = props.row.original;

  const [{ isDragging }, drag] = useDrag(
    () => ({
      type: "transaction",
      item: { transaction: JSON.stringify(transaction) },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    }),
    [transaction.uuid]
  );

  const ref = selectedTransactionId
    ? (ref: any) => {
        if (transaction.uuid === selectedTransactionId) {
          ref?.scrollIntoView({
            behavior: "smooth",
            block: "center",
          });
        }
      }
    : drag;

  return (
    <tr
      ref={ref}
      {...props}
      className={classNames(
        "hover:t-bg-surface-lighter-grey t-cursor-pointer t-border-neutral-0 t-border-b t-border-0 t-text-body t-px-3 t-border-solid",
        {
          "t-bg-surface-lighter-grey":
            sliderTransactions.find(
              ({ transactionId }) => transactionId === transaction?.uuid
            ) || isDragging,
          "animate-transaction-row": transaction.uuid === selectedTransactionId,
        }
      )}
    />
  );
};

const ReceiptForm = () => {
  const dateOptions = [
    ...datePeriod,
    {
      label: "Custom",
      value: "custom",
    },
  ];

  const { values } = useFormikContext<{
    duration: string;
    fromDate: string;
    toDate: string;
  }>();

  const { duration, fromDate, toDate } = values || {};
  const selectedOption = dateOptions.find(({ value }) => value === duration);

  return (
    <div className="t-flex t-flex-col t-gap-4">
      <Combobox
        withForm
        name="duration"
        label="Duration"
        menuPortalTarget={document.body}
        options={dateOptions}
        value={selectedOption ? selectedOption : null}
      />
      {selectedOption?.value == "custom" && (
        <div className="t-flex  t-gap-4">
          <div className="t-w-full">
            <Field name="from_date">
              {({ field }: FieldProps) => {
                return (
                  <DateInput
                    {...field}
                    label="From"
                    placeholder={DD_MMM_YYYY}
                    required
                    portalId="from"
                  />
                );
              }}
            </Field>
          </div>
          <div className="t-w-full">
            <Field name="to_date">
              {({ field }: FieldProps) => {
                return (
                  <DateInput
                    {...field}
                    label="To"
                    placeholder={DD_MMM_YYYY}
                    required
                    portalId="to"
                  />
                );
              }}
            </Field>
          </div>
        </div>
      )}

      <span className="t-flex t-items-start t-text-body t-text-neutral t-gap-1">
        <InfoFilledSmall color="currentColor" />
        Only transactions fetched from Brex and Mercury direct connection will
        refresh
      </span>
    </div>
  );
};
const FetchReceiptsModal = ({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) => {
  const [refreshReceipts] = useRefreshReceiptsMutation();
  const { infoToast, alertToast } = useToast();
  const entityId = useCurrentEntityId();
  const { uuid: groupId } = useCurrentGroupContext();

  const handleSubmit = async (values: {
    duration: string;
    from_date: string;
    to_date: string;
  }) => {
    const dateFromDuration =
      values.duration !== "custom"
        ? getDateRange(values?.duration as DateRangeValue)
        : null;
    const final_from_date =
      values.duration === "custom"
        ? dayjs(values?.from_date).format("YYYY-MM-DD")
        : dayjs(dateFromDuration?.startDate!).format("YYYY-MM-DD");
    const final_to_date =
      values.duration === "custom"
        ? dayjs(values?.to_date).format("YYYY-MM-DD")
        : dayjs(dateFromDuration?.endDate!).format("YYYY-MM-DD");

    try {
      const payload = {
        from_date: final_from_date,
        to_date: final_to_date,
      };
      await refreshReceipts({
        entityId,
        groupId,
        payload,
      }).unwrap();

      infoToast({
        title: "Refreshing",
        message: "We'll send you an in-app notification when done",
      });
      onClose();
    } catch (error) {
      alertToast(
        {
          message: (error as BackendError).data?.error?.message,
        },
        error as Error
      );
    }
  };
  return (
    <Formik
      initialValues={{
        duration: "lastMonth",
        from_date: "",
        to_date: "",
      }}
      onSubmit={handleSubmit}
    >
      {({ submitForm }) => (
        <Form>
          <Modal.Root open={isOpen} onOpenChange={onClose}>
            <Modal.Content useCustomOverlay>
              <Modal.Header>
                <Modal.Title>Refresh Invoices and Memos</Modal.Title>
                <Modal.Close />
              </Modal.Header>
              <Modal.Body className=" t-flex t-flex-col t-gap-2">
                <ReceiptForm />
              </Modal.Body>

              <Modal.FooterButtonGroup>
                <Button onClick={onClose}>Cancel</Button>
                <Button customType="primary" type="submit" onClick={submitForm}>
                  Submit
                </Button>
              </Modal.FooterButtonGroup>
            </Modal.Content>
          </Modal.Root>
        </Form>
      )}
    </Formik>
  );
};
export const TransactionTable = ({
  setChannelId,
  txnAccountType,
}: {
  setChannelId?(v: string): void;
  txnAccountType?: TxnAccountType;
}) => {
  usePageTitle("Transactions");
  const [animateTransactions, setAnimateTransactions] = useState<string[]>([]);
  const [sorting, setSorting] = useState<
    {
      desc: boolean;
      id: "AMOUNT" | "DATE" | "VENDOR";
    }[]
  >([
    {
      id: "DATE",
      desc: true,
    },
  ]);
  const { alertToast, successToast } = useToast();
  const { populateTransactionIDsList, messageIdForTransactionList } =
    useSelector((state: RootState) => state.chat);
  const { update } = useUpdateQuery();
  const dispatch = useAppDispatch();
  const query = useQuery();
  const searchTerm = query.get("search_term");
  const page = query.get("page");
  const pageSize = query.get("page_size");
  const selectedTransactionId = query.get("selected_transaction_id");
  const resetFilter = query.get("reset_filter");
  const transactionId = query.get("transaction_id");
  const [rowSelection, setRowSelection] = useState({});
  const { uuid: groupId } = useCurrentGroupContext();
  const entityId = useCurrentEntityId();
  const history = useHistory();
  const location = useLocation();
  const [exportTxn, { isLoading: isExporting }] = useLazyExportTxnQuery();
  const { appliedFilterCount } = useSelector(getFilterStatus);
  const isFilterApplied = appliedFilterCount > 0;
  const { width = 0 } = useWindowSize();
  const { isAdmin } = useRoleBasedView();
  const [refreshTransactions, { isLoading: isRefreshing }] =
    useRefreshTransactionsMutation();

  const { isOpen } = useSelector(
    (state: RootState) => state.similarTransactions
  );
  const { showSplitTransactionModal } = useSelector(
    (state: RootState) => state.splitTransaction
  );

  useEffect(() => {
    if (transactionId) {
      dispatch(openSlider(transactionId));
    }
  }, [transactionId]);

  const {
    pageNum,
    goToFirstPage,
    goToPrevPage,
    goToNextPage,
    goToLastPage,
    setTotalPage,
  } = usePagination({
    pageNumber: Number(page),
    onPageNumChange: (currentPageNumber) => {
      update({ query: "page", value: currentPageNumber });
    },
  });

  const {
    filters: {
      uncategorised,
      minAmount,
      maxAmount,
      excludedTransactions,
      ...restFilter
    },
  } = useSelector((state: RootState) => state.transactionFilter);

  const sortCol = sorting[0]?.id;
  const sortOrder = sorting[0]?.desc;

  useEffect(() => {
    let currentSearch = parse(location.search, { ignoreQueryPrefix: true });
    if (currentSearch.reset_filter) {
      delete currentSearch.reset_filter;
    }

    if (Boolean(resetFilter)) {
      dispatch(setPopulateTransactionIDs(""));
      dispatch(setMessageIdForTransactionList(""));
      dispatch(resetTxnFilters());
    }

    let timeout: NodeJS.Timeout;
    if (selectedTransactionId || Boolean(resetFilter)) {
      if (currentSearch.selected_transaction_id) {
        delete currentSearch.selected_transaction_id;
      }

      timeout = setTimeout(() => {
        history.replace(
          `${location.pathname}${stringify(currentSearch, {
            addQueryPrefix: true,
          })}`
        );
      }, 5000);
    }

    return () => clearTimeout(timeout);
  }, [resetFilter, selectedTransactionId, page]);

  const allFilters = useMemo(
    () => ({
      groupId,
      entityId,
      page_num: pageNum,
      searchTerm,
      sortCol: sortCol,
      txnAccountType,
      sortOrder: sortOrder ? ("DSC" as const) : ("ASC" as const),
      transactionIds: populateTransactionIDsList,
      messageId: messageIdForTransactionList,
      uncategorized: uncategorised,
      minAmount: ((minAmount?.value || "") as string)
        ?.replaceAll("$", "")
        .replaceAll(",", ""),
      maxAmount: ((maxAmount?.value || "") as string)
        ?.replaceAll("$", "")
        .replaceAll(",", ""),
      pageSize,
      txnStatus: excludedTransactions?.value
        ? ("EXCLUDED" as const)
        : undefined,
      ...restFilter,
    }),
    [
      entityId,
      groupId,
      maxAmount?.value,
      minAmount?.value,
      pageNum,
      pageSize,
      populateTransactionIDsList,
      JSON.stringify(restFilter),
      searchTerm,
      sortCol,
      sortOrder,
      uncategorised,
      txnAccountType,
      messageIdForTransactionList,
      excludedTransactions,
    ]
  );

  useEffect(() => {
    dispatch(setFiltersAplied(allFilters));
  }, [allFilters]);

  const {
    data: transactionData,
    isLoading,
    isSuccess,
    isFetching,
  } = useGetAllTransactionsDataQuery(allFilters, {
    skip: !groupId || !entityId,
    refetchOnMountOrArgChange: true,
  });

  const onExport = async () => {
    try {
      const { download_url } = await exportTxn(allFilters).unwrap();
      successToast({ message: "Transaction export successful" });
      window.open(download_url);
    } catch (e) {
      alertToast({ message: (e as BackendError).data?.error?.message });
    }
  };

  const loading = Boolean(populateTransactionIDsList) ? isFetching : isLoading;

  const [
    txnRequestInfo,
    {
      data: requestedTxnInfo,
      isSuccess: isRequested,
      reset,
      isLoading: isRequesting,
    },
  ] = useAddOpenItemsMutation();

  const {
    transactions = [],
    channel_url = "",
    current_page = 1,
    per_page = 1,
    total_count = 1,
    total_pages = 1,
  } = transactionData || {};

  const isEmpty = transactions.length === 0;

  const paginationData = {
    totalPage: total_pages,
    currentPage: current_page,
    itemsPerPage: per_page,
    totalItemCount: total_count,
  };

  useEffect(() => {
    setTotalPage(total_pages);
  }, [total_pages]);

  useEffect(() => {
    if (channel_url) {
      setChannelId?.(channel_url);
    }
  }, [channel_url]);

  const handleChange = debounce((e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    update({ query: "search_term", value: value || null });
    dispatch(setTxnFilters({ search_term: value }));
  });

  const onRowClick = (
    transactionId: string,
    e: Event | React.MouseEvent<HTMLTableRowElement, MouseEvent>
  ) => {
    e.stopPropagation();
    dispatch(openSlider(transactionId));
  };

  const onAnimateComplete = () => {
    const transactionsToSendtoChat = transactions
      .filter((txn) => animateTransactions.includes(txn.transaction.uuid))
      .map((txn) => ({
        id: txn.transaction.uuid,
        amount: txn.transaction.amount,
        source: txn.transaction.source,
        date: txn.transaction.date,
        vendor: {
          logo: txn.transaction.logo,
          name: txn.transaction.merchant,
        },
        isCreditCard: txn.transaction.is_credit_card,
      }));

    dispatch(
      setTransactionsInChat({
        transactionsInChat: transactionsToSendtoChat,
        entityId: entityId,
      })
    );
    setAnimateTransactions([]);
  };

  const sendToChat = async ({
    transactionIds,
  }: {
    transactionIds: string[];
  }) => {
    setAnimateTransactions(transactionIds);
  };

  const onRequestInfo = async ({
    transactionIds,
  }: {
    transactionIds: string;
  }) => {
    try {
      await txnRequestInfo({
        groupId,
        transactionIds,
      }).unwrap();
      deselectRows();
    } catch (error) {
      alertToast({
        message: (error as BackendError)?.data?.error?.message,
      });
    }
  };

  const transactionsTableData = useMemo(
    () => transactionData?.transactions || [],
    [isFetching]
  );
  const showDescriptionColumn = width >= 1536;

  const columnHelper = createColumnHelper<Transactions>();

  const columns = useMemo(
    () => [
      columnHelper.display({
        id: "select",
        size: 1,
        enableSorting: false,
        header: ({ table }) => (
          <Checkbox
            {...{
              checked: table.getIsAllRowsSelected(),
              indeterminate: table.getIsSomeRowsSelected(),
              onChange: table.getToggleAllRowsSelectedHandler(),
            }}
          />
        ),

        cell: ({ row }) => (
          <Checkbox
            {...{
              checked: row.getIsSelected(),
              disabled: !row.getCanSelect(),
              indeterminate: row.getIsSomeSelected(),
              onChange: row.getToggleSelectedHandler(),
              key: row.id,
            }}
            aria-label={`Select transaction, source: ${
              row.original.transaction.merchant || "Unassingned"
            }, amount: ${currency({
              amount: Number(row.original.transaction.amount || 0),
            })}, and description: ${
              row.original.transaction.description || "Empty"
            }`}
            onClick={(e) => e.stopPropagation()}
          />
        ),
      }),

      columnHelper.accessor("transaction.date", {
        id: "DATE",
        size: showDescriptionColumn ? 15 : 17,
        header: "Date",
        cell: (info) => (
          <div className="t-text-subtext t-text-text-30">
            {dayjs(info.getValue()).format(DD_MMM_YYYY)}
          </div>
        ),
      }),

      columnHelper.accessor("transaction.from", {
        id: "logo",
        size: showDescriptionColumn ? 2 : 5,
        enableSorting: false,
        header: () => (
          <div className="t-flex t-justify-center t-w-full">Source</div>
        ),
        cell: (info) => {
          const transaction = info.row.original.transaction;

          return <TransactionColumn.Source transaction={transaction} />;
        },
      }),

      columnHelper.accessor("transaction.merchant", {
        id: "VENDOR",
        size: showDescriptionColumn ? 30 : 33,
        header: "Vendor",
        cell: (info) => {
          return <TransactionColumn.Merchant info={info} showSplitVendors />;
        },
      }),

      columnHelper.accessor("transaction.description", {
        id: "DESCRIPTION",
        enableSorting: false,
        size: showDescriptionColumn ? 15 : 0,
        header: "DESCRIPTION",
        cell: (info) => {
          const description = info.getValue();
          return (
            <ConditionalToolTip condition={description}>
              <span className="t-line-clamp-1">{description || "-"}</span>
            </ConditionalToolTip>
          );
        },
      }),

      columnHelper.accessor("transaction.amount", {
        id: "AMOUNT",
        size: showDescriptionColumn ? 8 : 12,
        header: () => (
          <span className="t-flex t-justify-end t-ml-auto">Amount</span>
        ),
        cell: (info) => {
          const amount = info.getValue();
          const {
            transaction: { is_credit_card },
          } = info.row.original || {};

          return (
            <TransactionColumn.Amount
              amount={amount}
              isCreditCard={is_credit_card}
            />
          );
        },
      }),

      columnHelper.accessor("transaction.category", {
        id: "category",
        size: 28,
        header: "Category",
        enableSorting: false,
        cell: (info) => {
          return (
            <TransactionColumn.Category
              info={info}
              tooltip
              truncate
              showSplitActions
            />
          );
        },
      }),

      columnHelper.accessor("transaction", {
        id: "threedots",
        size: showDescriptionColumn ? 2 : 5,
        header: "",
        enableSorting: false,
        cell: (info) => (
          <TransactionColumn.Actions
            info={info}
            onRowClick={onRowClick}
            sendToChat={(transactionIds) =>
              sendToChat({ transactionIds: [transactionIds] })
            }
            onRequestInfo={onRequestInfo}
          />
        ),
      }),
    ],
    [isFetching, showDescriptionColumn]
  );

  const table = useReactTable({
    data: transactionsTableData || [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    enableRowSelection: true,
    manualSorting: true,
    state: {
      columnVisibility: {
        DESCRIPTION: showDescriptionColumn,
      },
      rowSelection,
      sorting,
    },
    // @ts-ignore
    onSortingChange: setSorting,
    onRowSelectionChange: setRowSelection,
    enableMultiSort: false,
    enableSortingRemoval: false,
    defaultColumn: {
      size: 10,
      minSize: 1,
      maxSize: 100,
    },
  });

  const deselectRows = () => {
    table.resetRowSelection();
    dispatch(setSelectedTransactionIds([]));
  };

  const {
    isOpen: isAddTransactionModalOpen,
    close: closeAddTransactionModal,
    open: openAddTransactionModal,
  } = useModal();

  const {
    isOpen: isOpenConfirmation,
    open: openConfirmation,
    close: closeConfirmation,
  } = useModal();

  const {
    isOpen: isFetchReceiptsModalOpen,
    open: openFetchReceiptsModal,
    close: closeFetchReceiptsModal,
  } = useModal();

  const refreshTransactionsData = async () => {
    try {
      await refreshTransactions({ groupId, entityId }).unwrap();
      successToast({ message: "Transactions refreshed" });
    } catch (error) {
      alertToast({
        message: (error as BackendError)?.data?.error?.message,
      });
    }
  };

  return (
    <>
      <DashboardContainer className="t-relative t-gap-5">
        <DashboardContainer.Header className="t-sticky t-top-0 t-z-header t-pt-5 t-bg-surface">
          <div className="t-bg-surface t-flex t-flex-col t-gap-5">
            <div className="t-flex t-gap-4 t-w-full t-items-center">
              <div className="t-w-1/2">
                <Search block onChange={handleChange} placeholder="Search" />
              </div>

              <div className="t-flex t-gap-2 t-ml-auto">
                <Dropdown.Root>
                  <Dropdown.Trigger asChild>
                    <Button size="small">
                      <span className="t-flex t-gap-2 t-items-center">
                        {isRefreshing ? "Refreshing" : "Refresh"}
                        {isRefreshing ? (
                          <motion.span
                            className={classNames("t-flex t-items-center", {
                              "t-animate-spin": isRefreshing,
                            })}
                          >
                            <Sync />
                          </motion.span>
                        ) : (
                          <CaretDown />
                        )}
                      </span>
                    </Button>
                  </Dropdown.Trigger>
                  <Dropdown.Portal>
                    <Dropdown.Content sideOffset={4} align="end">
                      <Dropdown.Item
                        onSelect={refreshTransactionsData}
                        className=" t-flex t-gap-2 t-items-center"
                      >
                        <span className=" t-text-neutral">
                          <Sync size="15" />
                        </span>
                        <span> New transactions</span>
                      </Dropdown.Item>

                      <Dropdown.Item
                        onSelect={openFetchReceiptsModal}
                        className=" t-flex t-gap-2 t-items-center"
                      >
                        <span className=" t-text-neutral">
                          <FileArrowDown />
                        </span>

                        <span>Invoices and Memos</span>
                      </Dropdown.Item>
                    </Dropdown.Content>
                  </Dropdown.Portal>
                </Dropdown.Root>
                {Boolean(total_count) && (
                  <ConditionalToolTip
                    condition={
                      isFilterApplied
                        ? `Export ${total_count} filtered transactions as a .CSV file`
                        : `Export ${total_count} transactions as a .CSV file`
                    }
                  >
                    <span>
                      <Button
                        onClick={onExport}
                        isLoading={isExporting}
                        disabled={isExporting}
                        customType="secondary"
                        size="small"
                      >
                        Export
                      </Button>
                    </span>
                  </ConditionalToolTip>
                )}

                <Button
                  customType="primary"
                  size="small"
                  onClick={openAddTransactionModal}
                >
                  Add transaction
                </Button>
              </div>
            </div>
            <div className="t-flex t-gap-4">
              <AnimatePresence>
                <TableSelectionControls
                  txnAccountType={txnAccountType}
                  table={table}
                  sendToChat={sendToChat}
                  onRequestInfo={onRequestInfo}
                  deselectRows={deselectRows}
                />
              </AnimatePresence>

              {isSuccess && (
                <div className="t-ml-auto t-flex-shrink-0">
                  <Pagination
                    {...paginationData}
                    goToFirstPage={goToFirstPage}
                    goToPrevPage={goToPrevPage}
                    goToNextPage={goToNextPage}
                    goToLastPage={goToLastPage}
                    showOptions={isAdmin}
                  />
                </div>
              )}
            </div>
          </div>
        </DashboardContainer.Header>
        <DashboardContainer.Content>
          <Async.Root {...{ isLoading: loading, isEmpty, isSuccess }}>
            <Async.Empty>
              <EmptyScreen text="No transactions found">
                <span className="t-text-i-neutral-10">
                  <MagnifyingGlass size="149" />
                </span>
              </EmptyScreen>
            </Async.Empty>
            <Async.Success>
              <div className="t-pb-6">
                <Table.Container className="t-h-full">
                  <Table.Content>
                    <Table.Head>
                      {table.getHeaderGroups().map((headerGroup) => (
                        <Table.HeadRow key={headerGroup.id}>
                          {headerGroup.headers.map((header) => (
                            <Table.HeadCell
                              className="t-text-subtext-sm t-uppercase t-px-2 t-py-4 t-group"
                              onClick={header.column.getToggleSortingHandler()}
                              key={header.id}
                              style={{ width: `${header.getSize()}%` }}
                              {...(header.column.getCanSort()
                                ? { role: "button" }
                                : {})}
                            >
                              <span className="t-flex t-gap-1 t-items-center">
                                {header.isPlaceholder
                                  ? null
                                  : flexRender(
                                      header.column.columnDef.header,
                                      header.getContext()
                                    )}
                                {header.column.getCanSort() && (
                                  <Table.HeadCellSort
                                    nextSortOrder={header.column.getNextSortingOrder()}
                                    isSorted={header.column.getIsSorted()}
                                  />
                                )}
                              </span>
                            </Table.HeadCell>
                          ))}
                        </Table.HeadRow>
                      ))}
                    </Table.Head>
                    <Table.Body>
                      {table.getRowModel().rows.map((row) => (
                        <Fragment key={row.id}>
                          <DraggableRow
                            row={row}
                            onClick={(e) =>
                              onRowClick(row.original.transaction.uuid, e)
                            }
                          >
                            {row.getVisibleCells().map((cell) => {
                              return (
                                <Table.Cell
                                  id={cell.id}
                                  key={cell.id}
                                  style={{
                                    width: `${cell.column.getSize()}%`,
                                  }}
                                >
                                  {flexRender(
                                    cell.column.columnDef.cell,
                                    cell.getContext()
                                  )}
                                </Table.Cell>
                              );
                            })}
                          </DraggableRow>
                          {animateTransactions.includes(
                            row.original.transaction.uuid
                          ) && (
                            <AnimateRow
                              onAnimateComplete={onAnimateComplete}
                              row={row}
                            />
                          )}
                        </Fragment>
                      ))}
                    </Table.Body>
                  </Table.Content>
                </Table.Container>
                {isOpen && (
                  <AutoAssignModal openConfirmation={openConfirmation} />
                )}
                <RuleConfirmation
                  isOpen={isOpenConfirmation}
                  close={closeConfirmation}
                />
              </div>
            </Async.Success>
          </Async.Root>
        </DashboardContainer.Content>
      </DashboardContainer>

      {isFetchReceiptsModalOpen && (
        <FetchReceiptsModal
          isOpen={isFetchReceiptsModalOpen}
          onClose={closeFetchReceiptsModal}
        />
      )}
      {isRequested && (
        <RequestInfo
          isOpen={isRequested}
          onClose={reset}
          csvId={requestedTxnInfo?.csv_id || ""}
          channelUrl={channel_url}
        />
      )}
      <LoadingToast loading={isRequesting} title="Please wait">
        Requesting...
      </LoadingToast>
      <TransactionSlider
        sendToChat={({ transactionId }) =>
          sendToChat({
            transactionIds: [transactionId],
          })
        }
      />
      <AddTransactionOptions
        close={closeAddTransactionModal}
        isOpen={isAddTransactionModalOpen}
        openAddTransactionModal={openAddTransactionModal}
      />
      {showSplitTransactionModal && (
        <SplitTransaction isOpen={showSplitTransactionModal} />
      )}
    </>
  );
};
