import { BlockedButton } from "components/BlockedButton/BlockedButton";
import { AgentCallingModals } from "components/chat/CallAgentModals";
import { MeetWithCPA } from "components/chat/MeetWithCPA";
import { Button } from "components/DesignSystem/Button/Button";
import Dropdown from "components/DesignSystem/Dropdown/Dropdown";
import { CaretDown } from "components/icons/CaretDown";
import { User } from "components/icons/Navbar/User";
import { Padlock } from "components/icons/Padlock";
import { PhoneCallIcon } from "components/icons/PhoneCallIcon";
import { PermissionBasedUI } from "components/PermissionBasedUI/PermissionBasedUI";
import {
  ANAND_GENERIC_MEET_LINK,
  INKLE_CONSULTATION,
} from "constants/meetingRequestUrls";
import { CALL_AGENT } from "constants/subscriptionPermissionFeatures";
import { FREE_PLAN } from "constants/subscriptionType";
import { useModal } from "hooks/useModal";
import { useRoleBasedView } from "hooks/useRoleBasedView";
import authContext from "jwt_context&axios/authContext";
import { useContext } from "react";
import { useDispatch } from "react-redux";
import CalendarBlank from "static/images/CalendarBlank.svg";
import CalendarMoney from "static/images/CalendarMoney.svg";
import UsersThree from "static/images/UsersThree.svg";
import "static/styles/containers/dashboard.css";
import { openMeetWithTranqModal } from "store/slices/chat";

export const CallUs = ({
  shouldShowUpgrade: shouldShowUpgradeFromProps,
}: {
  shouldShowUpgrade: boolean;
}) => {
  const dispatch = useDispatch();
  const meetWithCpaModal = useModal();
  const agentCallingModals = useModal();

  const { isCustomer, isInvestor } = useRoleBasedView();
  const { authtoken } = useContext(authContext);

  const currentPlan = authtoken?.platform_subscription?.subscription_name;
  const shouldUpgrade = !currentPlan || currentPlan === FREE_PLAN;
  const shouldShowUpgrade =
    Boolean(isCustomer && shouldUpgrade && !isInvestor) &&
    shouldShowUpgradeFromProps;

  return (
    <>
      <Dropdown.Root>
        <Dropdown.Trigger asChild className="t-group">
          <div>
            <Button
              customType={shouldShowUpgrade ? "primary-outlined" : "primary"}
              size="small"
              onClick={(e) => e.stopPropagation()}
            >
              <div className="t-flex t-items-center t-gap-1.5">
                <PhoneCallIcon />
                Call us
                <div className="group-data-state-open:t-rotate-180">
                  <CaretDown />
                </div>
              </div>
            </Button>
          </div>
        </Dropdown.Trigger>
        <Dropdown.Portal>
          <Dropdown.Content sideOffset={4} align="end" side="bottom">
            <PermissionBasedUI
              feature={CALL_AGENT}
              product_type="TAX"
              blockedUI={
                <Dropdown.Item
                  icon={<PhoneCallIcon />}
                  className=" t-flex t-items-center t-w-full"
                  disabled
                >
                  Call Inkle Support{" "}
                  <div className=" t-ml-5">
                    <Padlock />
                  </div>
                </Dropdown.Item>
              }
            >
              <Dropdown.Item
                icon={<PhoneCallIcon />}
                onSelect={agentCallingModals.open}
              >
                Call Inkle Support
              </Dropdown.Item>
            </PermissionBasedUI>
            <Dropdown.Sub>
              <Dropdown.SubTrigger
                icon={
                  <img
                    src={CalendarBlank}
                    width="20px"
                    height="20px"
                    alt="meeting"
                  />
                }
                asChild
              >
                Book a meeting
              </Dropdown.SubTrigger>
              <Dropdown.Portal>
                <Dropdown.SubContent sideOffset={4}>
                  <Dropdown.Item
                    icon={
                      <img
                        src={UsersThree}
                        width="20px"
                        height="20px"
                        alt="inkleConsultation"
                      />
                    }
                    onSelect={() =>
                      dispatch(openMeetWithTranqModal(INKLE_CONSULTATION))
                    }
                  >
                    Meet Inkle Support
                  </Dropdown.Item>
                  <Dropdown.Item
                    icon={
                      <img
                        src={CalendarMoney}
                        width="20px"
                        height="20px"
                        alt="cpaConsultation"
                      />
                    }
                    onSelect={meetWithCpaModal.open}
                  >
                    Meet with CPA
                  </Dropdown.Item>
                  <Dropdown.Item
                    icon={<User size="20" />}
                    onSelect={() =>
                      dispatch(openMeetWithTranqModal(ANAND_GENERIC_MEET_LINK))
                    }
                  >
                    Meet with CEO
                  </Dropdown.Item>
                </Dropdown.SubContent>
              </Dropdown.Portal>
            </Dropdown.Sub>
          </Dropdown.Content>
        </Dropdown.Portal>
      </Dropdown.Root>
      <AgentCallingModals
        close={agentCallingModals.close}
        isOpen={agentCallingModals.isOpen}
      />
      <MeetWithCPA {...meetWithCpaModal} />
    </>
  );
};
