export const RegistrationsIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
  >
    <path
      d="M13.3607 7.32199C13.4177 7.28999 13.4417 7.23599 13.484 7.15066C13.835 6.32599 14.4623 6.49433 14.515 5.41733C14.57 5.22566 14.6977 5.23066 14.8027 5.06799L14.803 5.06833C14.9167 4.92899 14.675 4.71733 14.683 4.60566C14.6093 4.28866 14.1397 4.06566 13.8677 4.41399C13.7853 4.64533 13.75 4.90533 13.5767 5.04433C13.249 5.57866 13.0313 5.00199 12.6347 5.89699C11.9627 6.02999 12.06 6.24333 11.8333 6.66666"
      stroke="currentColor"
      stroke-width="1.4"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M13.282 7.46934V7.466M13.282 7.47267C13.281 7.47267 13.2827 7.46767 13.2853 7.46934"
      stroke="currentColor"
      stroke-width="1.4"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M13.2853 7.466C13.2807 7.466 13.289 7.475 13.282 7.48267M12.5967 9.63734C13.2503 8.71534 13.7197 8.878 13.2717 7.50367"
      stroke="currentColor"
      stroke-width="1.4"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M13.282 7.48667C13.262 7.50067 13.276 7.531 13.282 7.483M12.6003 9.63734H12.597M12.5903 9.64067L12.6037 9.63067C12.3163 9.96767 12.2363 10.371 12.549 10.7643M12.929 11.7407C12.877 11.428 12.6373 11.0847 12.5523 10.7643"
      stroke="currentColor"
      stroke-width="1.4"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M8.01399 11.5387C8.65733 10.9743 9.46599 11.1497 10.244 11.0593C10.467 10.71 10.935 10.6177 11.2747 10.72C11.631 10.813 13.0927 12.906 12.929 11.7373M7.97266 11.7303C7.93999 11.6403 7.99999 11.5897 8.01366 11.535M5.36266 10.189C5.64233 10.714 5.74533 11.0323 6.47599 10.9833C6.67633 11.092 6.68933 11.3207 6.83566 11.5213C7.07433 11.7557 8.10433 12.4727 7.97599 11.7407M11.7407 6.60267C11.7727 6.654 11.819 6.66167 11.816 6.695M11.7407 6.59934C11.3327 6.585 11.361 6.029 11.0897 5.70867C10.8263 5.452 10.5747 5.13867 10.1377 5.02C9.35933 4.594 4.5 4.33334 3 3.66667"
      stroke="currentColor"
      stroke-width="1.4"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M5.27334 10.1033C4.58167 10.3963 2.96567 9.55066 2.345 9.11333M2.345 9.11C2.28167 9.01333 1.94634 8.91666 1.79367 8.771"
      stroke="currentColor"
      stroke-width="1.4"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M1.79334 8.76733C1.80168 8.803 1.75434 8.75533 1.78334 8.76066M1.78668 8.76066C1.64234 8.68166 1.57768 8.34733 1.47834 8.13733M1.47834 8.13366C1.34401 7.949 1.37168 7.457 1.23834 7.25366"
      stroke="currentColor"
      stroke-width="1.4"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M1.24199 7.25999C1.22099 7.19666 1.17466 7.15233 1.16666 7.08899M1.16666 7.08566C1.19566 6.66833 1.11499 5.77933 1.33432 5.39699M1.33466 5.40033C1.39466 5.24533 1.47999 5.19499 1.57432 5.08866M1.57432 5.08533C1.93299 4.80666 1.67966 4.36299 1.77966 3.92066C2.11666 3.05233 4.26366 4.20833 5.21866 4.19466"
      stroke="currentColor"
      stroke-width="1.4"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M6 8L7 9L9 7"
      stroke="currentColor"
      stroke-width="1.4"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
