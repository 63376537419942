import {
  BareInput,
  ErrorMessage,
  INPUT_CLASSNAMES,
  InputProps,
  Label,
  NoteMessage,
} from "components/DesignSystem/TextInput/TextInput";
import { Field, FieldAttributes, FieldProps, getIn } from "formik";
import {
  ComponentProps,
  HTMLAttributes,
  InputHTMLAttributes,
  MouseEvent,
  ReactNode,
} from "react";
import { NumericFormat } from "react-number-format";
import { default as classNames, default as cx } from "classnames";

const CustomInput = (
  props: InputHTMLAttributes<HTMLInputElement> & {
    rightComponent?: ReactNode;
  } & ComponentProps<typeof BareInput>
) => {
  if (!props.rightComponent) {
    return <BareInput {...props} />;
  }

  return (
    <div className={INPUT_CLASSNAMES("regular", undefined)}>
      <input
        id={props.name}
        className={cx("all:unset t-appearance-textfield", {
          "t-w-3/4": props.rightComponent,
          "t-w-full": !props.rightComponent,
        })}
        {...props}
      />
      <div>{props.rightComponent && props.rightComponent}</div>
    </div>
  );
};

export const PriceInput = (
  props: FieldAttributes<any> & {
    label: string;
    allowNegative?: boolean;
    note?: string;
    rightAlign?: boolean;
    rightComponent: ReactNode;
    customSize?: InputProps["customSize"];
    placeholder?: string;
  }
) => (
  <Field {...props}>
    {({ field, form: { errors, setFieldValue, touched } }: FieldProps) => {
      let hasError = getIn(errors, field.name) && getIn(touched, field.name);

      return (
        <div className="t-relative t-block t-w-full">
          <div className="t-flex">
            {Boolean(props.label) && (
              <Label htmlFor={field.name} required={props.required}>
                {props.label}
              </Label>
            )}
          </div>

          <NumericFormat
            placeholder={props.placeholder}
            style={{ textAlign: props.rightAlign ? "right" : "left" }}
            onBlur={field.onBlur}
            defaultValue={props.defaultValue}
            allowNegative={props.allowNegative}
            disabled={props.disabled}
            customSize={props.customSize}
            onClick={(event: MouseEvent<HTMLInputElement>) => {
              // @ts-ignore
              if (event.target.value === "$ 0.00") {
                // @ts-ignore
                event.target.select();
              }
            }}
            name={props.name}
            allowLeadingZeros={false}
            onValueChange={(values, ...rest) => {
              const { floatValue } = values;
              props.onValueChange?.(values, ...rest);

              if (field.name) {
                return setFieldValue(field.name, floatValue || 0.0);
              }
            }}
            rightComponent={props.rightComponent}
            value={props.value || field.value}
            customInput={CustomInput}
            isAllowed={({ value }) => {
              if (value === "") {
                setFieldValue(field.name, 0.0);
              }
              return value !== "";
            }}
            valueIsNumericString
            prefix="$ "
            thousandSeparator=","
            decimalScale={2}
            thousandsGroupStyle="thousand"
            fixedDecimalScale
            aria-label={props["aria-label"]}
          />
          {!props.hideError && hasError && (
            <ErrorMessage>{getIn(errors, field.name)}</ErrorMessage>
          )}
          {props.note && <NoteMessage>{props.note}</NoteMessage>}
        </div>
      );
    }}
  </Field>
);
