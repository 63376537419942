import { useCallback, useMemo } from "react";
import {
  HiddenCategoryTypes,
  useGetChartOfAccountsQuery,
} from "store/apis/chartOfAccounts";
import { TxnCategories } from "types/Models/books";
import { useCurrentEntityId } from "./useCurrentEntityId";
import { useCurrentGroupContext } from "./useCurrentGroupContext";

export const useChartOfAccounts = ({
  search,
  hiddenCategory,
  root_category_id,
}: {
  search?: string;
  hiddenCategory?: HiddenCategoryTypes[];
  root_category_id?: string;
}) => {
  const { uuid: groupId } = useCurrentGroupContext();
  const entityId = useCurrentEntityId();

  const { data = [], ...options } = useGetChartOfAccountsQuery(
    {
      groupId,
      entityId,
      hiddenCategory,
      root_category_id,
    },
    { skip: !groupId || !entityId }
  );

  const getTopParent = useCallback(
    (categoryId: string) => {
      const findCategory = (
        data: TxnCategories[],
        id: string
      ): null | TxnCategories => {
        let result = null;

        for (let item of data) {
          if (item.uuid === id) {
            result = item;
            break;
          } else if (item.types) {
            result = findCategory(item.types, id);
            if (result) {
              break;
            }
          }
        }

        return result;
      };

      return data.find((item) => {
        if (!item.types) {
          return false;
        }

        const result = findCategory(item.types, categoryId);
        if (result) {
          return result;
        }

        return false;
      });
    },
    [data]
  );

  const searchCategories = useCallback(
    (data: TxnCategories[]) => {
      let results = [];
      for (let item of data) {
        if (
          item.name.toLowerCase().includes(search || "") ||
          item.identifier.toString().includes(search || "")
        ) {
          results.push(item);
        } else if (item.types) {
          const nestedResults = searchCategories(item.types);
          if (Boolean(nestedResults.length)) {
            const newItem = { ...item };
            newItem.types = nestedResults;
            results.push(newItem);
          }
        }
      }
      return results;
    },
    [search]
  );

  const chartOfAccounts = useMemo(() => {
    if (search) {
      return searchCategories(data);
    }
    return data;
  }, [data, search]);

  return { chartOfAccounts, ...options, getTopParent };
};
