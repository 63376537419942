import { AppSwitcher } from "components/AppSwitcher/AppSwitcher";
import ArchivedUser from "components/archived/ArchivedUser";
import { PageNotFound } from "components/design/PageNotFound";
import { ShareDocuments } from "components/documents/shareDocuments";
import { GlobalContexts } from "components/GlobalContexts/GlobalContexts";
import PhoneVerify from "components/magicLink/PhoneVerify";
import { ToastContainer } from "components/ToastContainer";
import { CurrentGroupContextProvider } from "contexts/CurrentGroupContext";
import { ContractorForm } from "ContractorForm";
import { ContractorFormSelector } from "ContractorFormSelector";
import { useRoleBasedView } from "hooks/useRoleBasedView";
import { AdminDashboard } from "pages/AdminDashboard";
import { BooksDashboard } from "pages/Books/BooksDashboard";
import { CommunitygroupDashboard } from "pages/CommunityDashboard";
import { ConnectionConfirmation } from "pages/ConnectionConfirmation";
import { IntragroupDashboard } from "pages/IntragroupDashboard";
import { MailroomDashboard } from "pages/MailroomDashboard";
import { Payment3DSConfirmation } from "pages/Payment3DSConfirmation";
import { PublicCalendarLayout } from "pages/PublicCalendar";
import { RaiseInvestorDashboard } from "pages/RaiseInvestorDashboard";
import { SalesTaxDashboard } from "pages/SalesTaxDashboard";
import { SettingsDashboard } from "pages/SettingsDashbaord";
import { TaxDashboard } from "pages/Tax/TaxDashboard";
import { useContext, useEffect, useState } from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import { RestrictedRoute } from "RestrictedRoute";
import "stream-chat-react/dist/css/v2/index.css";
import MagicLink from "./components/magicLink/magicLink";
import VerifyOtpPage from "./components/magicLink/verifyOtp";
import authContext, { AuthProvider } from "./jwt_context&axios/authContext";
import { ProtectedRoutes } from "./protectedRoutes";
import ForgotPassword from "./signInSignUp/forgotPassword";
import Profile from "./signInSignUp/profile";
import Verifying from "./signInSignUp/verifying";
import "./static/styles/common.css";
import { GoogleRedirecting } from "pages/InvestorUpdates/GoogleRedirecting";
import { parse } from "qs";
import { InvitationContext } from "contexts/InvitationContext";
import PracticeDashboard from "pages/PracticeDashboard";
import { useModal } from "hooks/useModal";
import { EstimatedDelivery } from "components/EstimatedDelivery/EstimatedDelivery";
import { useGetUserEtdCountQuery } from "store/apis/profile";
import { TIME_GAP_TO_SHOW_REMINDER } from "constants/routeState";
import { RedirectPage } from "pages/RedirectPage";

const AppRedirects = ({ from, to }: { from: string; to: string }) => {
  return (
    <Route
      path={from}
      render={(props) => (
        <Redirect
          to={`${to}${props.location.pathname}${props.location.search}`}
        />
      )}
    />
  );
};

const HomeRoute = () => {
  const { isCpa, isInvestor, isForeignCA } = useRoleBasedView();

  if (isForeignCA) {
    return <Redirect to="/practice" />;
  }

  if (isInvestor) {
    return <Redirect to="/raise/investor" />;
  }

  if (isCpa) {
    return <Redirect to="/admin" />;
  }

  return <AppSwitcher />;
};

const AllProtectedRoutes = () => {
  const { isCpa, isInvestor, isForeignCA, isUserTypeForeignCA, isCustomer } =
    useRoleBasedView();
  const estimatedDeliveryReminder = useModal();
  const { authtoken } = useContext(authContext);
  const reminderKey = `reminderTimestamp${authtoken.email}`;
  const { data } = useGetUserEtdCountQuery(void 0, {
    skip: isCustomer,
  });

  useEffect(() => {
    const reminderTimestamp = localStorage.getItem(reminderKey);
    const currentTime = new Date().getTime();
    const reminderTimestampNumber = reminderTimestamp
      ? Number(reminderTimestamp)
      : null;

    if (
      data?.task_etd_pending_count &&
      data.task_etd_pending_count > 0 &&
      (!reminderTimestampNumber ||
        currentTime - reminderTimestampNumber > TIME_GAP_TO_SHOW_REMINDER)
    ) {
      estimatedDeliveryReminder.open();
      localStorage.setItem(reminderKey, currentTime.toString());
    }
  }, [data?.task_etd_pending_count]);

  const isFCA = isForeignCA || isUserTypeForeignCA;

  return (
    <>
      {estimatedDeliveryReminder.isOpen && (
        <EstimatedDelivery
          pendingFilingCount={data!.task_etd_pending_count}
          {...estimatedDeliveryReminder}
        />
      )}
      <Switch>
        <RestrictedRoute path="/profile" exact component={Profile} />
        <RestrictedRoute path="/verify" exact component={Verifying} />
        <RestrictedRoute path="/archived" exact component={ArchivedUser} />
        <RestrictedRoute
          path="/payment-confirmation"
          component={Payment3DSConfirmation}
        />
        <RestrictedRoute
          path="/connection-confirmation/:bank"
          component={ConnectionConfirmation}
        />
        <RestrictedRoute path="/redirect" component={RedirectPage} />
        <GlobalContexts>
          <Switch>
            <RestrictedRoute path="/books" component={BooksDashboard} />
            <RestrictedRoute path="/tax" component={TaxDashboard} />
            <RestrictedRoute
              path="/redirecting"
              component={GoogleRedirecting}
            />

            <RestrictedRoute
              path="/mailroom"
              render={() => (isCpa ? <PageNotFound /> : <MailroomDashboard />)}
            />
            <RestrictedRoute
              path="/intragroup"
              render={() =>
                isCpa ? <PageNotFound /> : <IntragroupDashboard />
              }
            />
            <RestrictedRoute
              path="/sales-tax"
              render={() => (isCpa ? <PageNotFound /> : <SalesTaxDashboard />)}
            />
            <RestrictedRoute
              path="/community"
              render={() =>
                isCpa ? <PageNotFound /> : <CommunitygroupDashboard />
              }
            />

            <RestrictedRoute
              path="/raise/investor"
              exact
              render={() =>
                isInvestor ? <RaiseInvestorDashboard /> : <PageNotFound />
              }
            />

            <RestrictedRoute
              path="/admin"
              render={() =>
                isCpa ? (
                  <AdminDashboard />
                ) : (
                  <Redirect to={isFCA ? "/practice" : `/`} />
                )
              }
            />

            <RestrictedRoute
              path="/practice"
              render={() => (isFCA ? <PracticeDashboard /> : <PageNotFound />)}
            />

            <RestrictedRoute path="/settings" component={SettingsDashboard} />

            <RestrictedRoute
              path="/docs/:groupId/:docId"
              component={ShareDocuments}
            />
            <RestrictedRoute exact path="/" render={() => <HomeRoute />} />

            {/* Redirects */}
            <Route
              path="/home"
              render={(props) => {
                const authCode = parse(props.location.search, {
                  ignoreQueryPrefix: true,
                }).code;

                if (authCode) {
                  return <Redirect to={`/settings${props.location.search}`} />;
                }
                return <Redirect to={`/${props.location.search}`} />;
              }}
            />

            <Route exact path="/dashboard">
              <Redirect to={`/tax`} />
            </Route>
            <Route exact path="/rewards/referral">
              <Redirect to="/billing/credits" />
            </Route>
            <Route exact path="/rewards">
              <Redirect to="/billing/credits" />
            </Route>
            <Route exact path="/tasks/addtask">
              <Redirect to="/filings/addtask" />
            </Route>
            <Route exact path="/tasks">
              <Redirect to="/filings" />
            </Route>
            <Route exact path="/chat/tickets">
              <Redirect to={isCustomer ? "/action-items" : "/admin/tickets"} />
            </Route>
            {isCustomer && (
              <Route exact path="/tickets">
                <Redirect to="/tax/action-items" />
              </Route>
            )}
            <Route
              exact
              path="/tasks/:taskId"
              render={({
                match: {
                  params: { taskId },
                },
              }) => <Redirect to={`/filings/${taskId}`} />}
            />

            <Route
              exact
              path="/tasks/addtask/:templateId"
              render={({
                match: {
                  params: { templateId },
                },
              }) => <Redirect to={`/filings/addtask/${templateId}`} />}
            />

            <Route
              path="/board/captable"
              render={(props) => {
                const entityId = parse(props.location.search, {
                  ignoreQueryPrefix: true,
                }).entity;

                if (entityId) {
                  return (
                    <Redirect
                      to={`/tax/entities/entity/${entityId}/cap-table`}
                    />
                  );
                }
                return <PageNotFound />;
              }}
            />

            <Route exact path="/interco">
              <Redirect to="/intragroup" />
            </Route>
            <Route exact path="/handbook">
              <Redirect to="/community/handbook" />
            </Route>
            <Route exact path="/bookkeeping">
              <Redirect to="/books" />
            </Route>
            <Route exact path="/bookkeeping/tp-payments">
              <Redirect to="/books/transfers" />
            </Route>
            <Route exact path="/mailbox">
              <Redirect to="/mailroom" />
            </Route>
            <Route exact path="/messages">
              <Redirect to="/chat" />
            </Route>

            <AppRedirects from="/chat" to={isCpa ? "/admin" : "/tax"} />
            <AppRedirects from="/tickets" to="/admin" />
            <AppRedirects from="/action-items" to="/tax" />
            <AppRedirects from="/documents" to={isCpa ? "/admin" : "/tax"} />
            <AppRedirects from="/entities" to="/tax" />
            <AppRedirects from="/billing" to="/settings" />
            <AppRedirects from="/perks" to="/community" />
            <AppRedirects from="/filings" to="/tax" />
            <AppRedirects from="/deadlines" to="/tax" />
            <AppRedirects from="/compliance" to="/tax" />
            <AppRedirects from="/crm/:groupId" to="/admin" />
            <AppRedirects from="/crm" to="/admin" />

            {/* Default */}
            <Route path="*">
              <PageNotFound />
            </Route>
          </Switch>
        </GlobalContexts>
      </Switch>
    </>
  );
};

const Routes = () => {
  const [recaptchaLoaded, setRecaptchaLoaded] = useState(true);

  useEffect(() => {
    let recaptchaScript = document.getElementById("recaptcha-script");

    if (!recaptchaScript) {
      const scriptEl = document.createElement("script");
      scriptEl.id = "recaptcha-script";
      scriptEl.src = `https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_RECAPTCHA_KEY}`;

      document.head.appendChild(scriptEl);
      recaptchaScript = scriptEl;
    }

    const onScriptLoad = () => {
      setRecaptchaLoaded(true);
    };
    recaptchaScript.addEventListener("load", onScriptLoad);

    return () => recaptchaScript?.removeEventListener("load", onScriptLoad);
  }, []);

  return (
    <BrowserRouter>
      <AuthProvider>
        <CurrentGroupContextProvider>
          <ToastContainer />
          <Switch>
            {/* Home */}
            <Route path="/signup" exact>
              <InvitationContext.Provider value={false}>
                <MagicLink recaptchaLoaded={recaptchaLoaded} />
              </InvitationContext.Provider>
            </Route>
            <Route path="/invitation" exact>
              <InvitationContext.Provider value={true}>
                <MagicLink recaptchaLoaded={recaptchaLoaded} />
              </InvitationContext.Provider>
            </Route>
            <Route path="/signin" exact>
              <InvitationContext.Provider value={false}>
                <MagicLink recaptchaLoaded={recaptchaLoaded} />
              </InvitationContext.Provider>
            </Route>
            <Route path="/invitation/otpverify" exact>
              <InvitationContext.Provider value={true}>
                <VerifyOtpPage />
              </InvitationContext.Provider>
            </Route>
            <Route path="/otpverify" exact>
              <InvitationContext.Provider value={false}>
                <VerifyOtpPage />
              </InvitationContext.Provider>
            </Route>
            <Route path="/phoneverify" exact>
              <InvitationContext.Provider value={false}>
                <PhoneVerify />
              </InvitationContext.Provider>
            </Route>
            <Route path="/invitation/phoneverify" exact>
              <InvitationContext.Provider value={true}>
                <PhoneVerify />
              </InvitationContext.Provider>
            </Route>
            <Route path="/forgotPassword" exact component={ForgotPassword} />
            <Route
              path="/inkledeadlines"
              exact
              component={PublicCalendarLayout}
            />
            <Route path="/inklecalendar" exact>
              <Redirect to="/inkledeadlines" />
            </Route>
            <Route
              path="/inklecalendar/:sessionId/:calendarType"
              exact
              component={PublicCalendarLayout}
            />
            <Route
              exact
              path="/contractor-form/:merchantId"
              component={ContractorFormSelector}
            />
            <Route
              exact
              path="/contractor-form/:merchantId/form"
              component={ContractorForm}
            />

            <ProtectedRoutes path="/" component={AllProtectedRoutes} />
            {/* <Route path="*">
              <div>We are in maintance mode.</div>
            </Route> */}
          </Switch>
        </CurrentGroupContextProvider>
      </AuthProvider>
    </BrowserRouter>
  );
};

export default Routes;
