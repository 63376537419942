import { Badge } from "components/design/badge";
import ConditionalToolTip from "components/design/conditionalToolTip";
import { OptionData } from "components/DesignSystem/Combobox/Combobox";
import { GroupSelector } from "components/GroupSelector/GroupSelector";
import { FREE_PLAN, PRO_PLAN, STANDARD_PLAN } from "constants/subscriptionType";
import { SUBSCRIPTION_TYPE } from "dictionaries";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useCurrentGroupSubscription } from "hooks/useCurrentGroupSubscription";
import { useQuery, useUpdateQuery } from "hooks/useQuery";
import { useEffect } from "react";
import { MultiValue, SingleValue } from "react-select";
import {
  useGetGroupDataQuery,
  useLazyGetGroupDataQuery,
} from "store/apis/group";
import { entitiesSortedByCountry } from "utils/entitiesSortedByCountry";

const DefaultGroup = ({ firstGroupId }: { firstGroupId: string }) => {
  const { updateMultiple } = useUpdateQuery();
  const query = useQuery();
  const selectedGroupId = query.get("company");
  const { dispatch: groupDispatch } = useCurrentGroupContext();
  const [getGroupDataCRM] = useLazyGetGroupDataQuery();

  const groupId = selectedGroupId || firstGroupId;

  const getGroupData = async ({ groupId }: { groupId: string }) => {
    const { entities } = await getGroupDataCRM({ groupId }).unwrap();
    const { sortedEntities } = entitiesSortedByCountry(entities);
    const entity = sortedEntities?.[0]?.uuid;

    updateMultiple([
      { query: "company", value: groupId },
      { query: "entity", value: entity },
    ]);
  };

  useEffect(() => {
    if (!selectedGroupId && firstGroupId) {
      getGroupData({ groupId: firstGroupId });
    }
  }, [selectedGroupId, firstGroupId]);

  const { data: currentGroupData } = useGetGroupDataQuery(
    { groupId },
    { skip: !groupId }
  );

  const { uuid } = currentGroupData || {};

  useEffect(() => {
    if (currentGroupData) {
      groupDispatch({ type: "SET_GROUP", payload: currentGroupData });
    }
  }, [uuid]);

  return null;
};

export const GroupSelectorWithDefaultGroup = () => {
  const { updateMultiple } = useUpdateQuery();
  const query = useQuery();
  const groupId = query.get("company") || "";

  const { isProUser, isBooksProUser, isStandardUser, isFreeUser, isLoading } =
    useCurrentGroupSubscription();

  const [getGroupDataCRM] = useLazyGetGroupDataQuery();

  const {
    data: currentGroupData,
    isFetching,
    isSuccess,
  } = useGetGroupDataQuery({ groupId }, { skip: !groupId });

  const { uuid, name } = currentGroupData || {};

  const onGroupSelect = async ({ uuid }: { uuid: string }) => {
    const { entities } = await getGroupDataCRM({ groupId: uuid }).unwrap();

    const { sortedEntities } = entitiesSortedByCountry(entities);
    const entity = sortedEntities?.[0]?.uuid;

    updateMultiple([
      { query: "company", value: uuid },
      { query: "entity", value: entity },
    ]);
  };

  const userPlan = () => {
    if (isProUser || isBooksProUser) return PRO_PLAN;
    if (isStandardUser) return STANDARD_PLAN;
    if (isFreeUser) return FREE_PLAN;
  };

  const Label = () => (
    <div className="t-flex t-items-center t-gap-1 t-overflow-hidden">
      <span className="t-truncate t-max-w-36">{name}</span>
      {isSuccess && !isLoading && (
        <Badge color={SUBSCRIPTION_TYPE[userPlan()!]}>{userPlan()?.[0]}</Badge>
      )}
    </div>
  );

  return (
    <ConditionalToolTip condition={uuid && name} side="right">
      <span>
        <GroupSelector
          isClearable={false}
          isLoading={isFetching}
          isDisabled={isFetching}
          onChange={(
            value: SingleValue<OptionData> | MultiValue<OptionData>
          ) => {
            if (value instanceof Array) {
              return null;
            }
            onGroupSelect({ uuid: value?.value! });
          }}
          value={uuid ? { label: <Label />, value: uuid } : null}
          styles={{
            menu: (base) => ({
              ...base,
              width: "256px",
              zIndex: 1210,
            }),
          }}
        >
          {({ firstGroupId }) => <DefaultGroup firstGroupId={firstGroupId} />}
        </GroupSelector>
      </span>
    </ConditionalToolTip>
  );
};
