import { Price } from "components/Price";
import { OPENED_MANAGE_PLAN_MODAL } from "constants/analyticsEvents";
import { MONTHLY, YEARLY } from "constants/billingCycles";
import { useAnalytics } from "hooks/useAnalytics";
import { useRoleBasedView } from "hooks/useRoleBasedView";
import authContext from "jwt_context&axios/authContext";
import { useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

import { TagSolid } from "./TagSolid";

import classNames from "classnames";
import {
  BOOKKEEPING_SOFTWARE,
  GROUP_BOOKS_CONSOLIDATION,
  MAILROOM,
  REGISTERED_AGENT,
  _MANAGE,
} from "constants/addons";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useQuery } from "hooks/useQuery";
import { useGetSubscriptionsQuery } from "store/apis/subscriptions";
import { AddonData, setAddonData, setAddonType } from "store/slices/addons";
import { toggleCartSubscription } from "store/slices/cartSubscription";
import { SubscriptionInProfile } from "types/Models/subscription";
import { Button } from "./DesignSystem/Button/Button";
import { Discount } from "./Discount";
import { ProductCard } from "./ProductCard/ProductCard";
import { SubscriptionUpdateModal } from "./SubscriptionUpdateModal";
import { AmountSuperScript } from "./design/AmountSuperScript";
import { Badge } from "./design/badge";
import { Cart } from "./icons/Cart";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import dayjs from "dayjs";
import { formatDate } from "utils/formatDate";
import { useGetSubscriptionsToShow } from "hooks/useGetSubscriptionsToShow";

const AddonPricing = ({ addon }: { addon: AddonData }) => {
  if (addon?.stripe_coupon) {
    const {
      stripe_coupon: { discounted_amount },
      billing_cycle,
    } = addon;

    return (
      <div className="t-mt-2 t-flex t-flex-col t-gap-1">
        <Price amount={discounted_amount} cycle={billing_cycle} size="small" />
        <Discount coupon={addon.stripe_coupon} amount={addon.amount} />
      </div>
    );
  }

  if (
    addon?.subscription_type === BOOKKEEPING_SOFTWARE &&
    addon?.group_subscription
  ) {
    return (
      <div className="t-mt-2">
        <Price
          cycle={addon?.billing_cycle}
          amount={addon?.group_subscription?.[0]?.amount}
          size="small"
        />
      </div>
    );
  }

  if (addon.subscription_type === GROUP_BOOKS_CONSOLIDATION) {
    const amount = addon.group_subscription
      ? addon?.group_subscription?.[0]?.amount
      : addon.amount;

    let billingPeriod = "/quarter or month";

    if (addon.group_subscription) {
      if (addon.group_subscription?.[0]?.billingCycle === MONTHLY) {
        billingPeriod = "/month";
      } else {
        billingPeriod = "/once in 3 months";
      }
    }

    return (
      <p className="t-m-0 t-text-subtext t-text-text-100">
        <AmountSuperScript amount={Number(amount)} />
        {addon?.billing_cycle && (
          <span className="t-text-body-sm t-text-text-30">{billingPeriod}</span>
        )}
      </p>
    );
  }

  const conditionForStartsAt =
    (addon?.subscription_type === BOOKKEEPING_SOFTWARE ||
      addon?.subscription_type === REGISTERED_AGENT ||
      addon?.subscription_type === GROUP_BOOKS_CONSOLIDATION) &&
    !addon?.group_subscription;

  return (
    <div
      className={classNames("", {
        "t-mt-2":
          addon?.subscription_type !== BOOKKEEPING_SOFTWARE &&
          addon?.subscription_type !== REGISTERED_AGENT,
        "t-mt-1": conditionForStartsAt,
      })}
    >
      {conditionForStartsAt && (
        <div className="t-text-body-sm t-text-text-30">Starts at</div>
      )}
      {addon?.subscription_type === REGISTERED_AGENT &&
      addon?.group_subscription ? (
        <></>
      ) : (
        <Price
          cycle={addon?.billing_cycle}
          amount={addon?.amount}
          size="small"
        />
      )}
    </div>
  );
};

const GetDescription = ({
  type,
  subscriptions,
}: {
  type: string;
  subscriptions: any;
}) => {
  if (type === BOOKKEEPING_SOFTWARE) {
    return (
      <div className="t-text-body-sm t-text-text-30">
        {subscriptions ? (
          <>Selected software: {subscriptions?.[0]?.subscriptionName}</>
        ) : (
          "Choose between QuickBooks USA or Zoho Books USA."
        )}
      </div>
    );
  }
  if (type === REGISTERED_AGENT) {
    let totalAmount: number = 0;

    const othersStates = subscriptions?.filter(
      (ele: any) => ele.type === "Others"
    );

    const othersStatesLength = othersStates?.[0]?.selectedStates?.length || 0;

    totalAmount = Number(othersStates?.[0]?.amount) * othersStatesLength || 0;

    subscriptions
      ?.filter((ele: any) => ele.type !== "Others")
      ?.map((ele: any) => {
        totalAmount = Number(totalAmount) + Number(ele?.amount);
      });

    const statesSelectedLength: number =
      subscriptions?.length +
      othersStatesLength -
      (othersStatesLength > 0 ? 1 : 0);

    if (statesSelectedLength === 1) {
      let selectedStateName = "";
      if (othersStatesLength === 1) {
        selectedStateName =
          othersStates?.[0]?.selectedStates?.[0]?.ra_state_name;
      } else {
        selectedStateName = subscriptions?.filter(
          (ele: any) => ele.type !== "Others"
        )?.[0]?.type;
      }
      return (
        <div className="t-flex t-justify-between t-items-center">
          <div className="t-text-body">{selectedStateName}</div>
          <Price amount={totalAmount} size="medium" cycle={MONTHLY} />
        </div>
      );
    }

    return (
      <>
        {subscriptions ? (
          <div className="t-flex t-justify-between t-flex-wrap">
            <div className="t-text-body">
              Active States: {statesSelectedLength}
            </div>
            <Price amount={totalAmount} size="medium" cycle={MONTHLY} />
          </div>
        ) : (
          <div className="t-text-body-sm t-text-text-30">
            Registered Agent service for all states of the United States of
            America
          </div>
        )}
      </>
    );
  }
  if (type === MAILROOM) {
    return (
      <div className="t-text-body-sm t-text-text-30">
        {!subscriptions &&
          "Get a virtual mailroom with a permanent U.S. address accessible through our platform anytime, anywhere."}
      </div>
    );
  }
  if (type === GROUP_BOOKS_CONSOLIDATION) {
    return (
      <div className="t-text-body-sm t-text-text-30">
        Consolidated financials of all your entities
      </div>
    );
  }

  return <></>;
};

export const SubscriptionSummary = ({
  freeSeats,
  extraSeats,
  activeSeats,
  setShowSeatManage,
}: {
  freeSeats: number;
  extraSeats: number;
  activeSeats: number;
  setShowSeatManage: (v: boolean) => void;
}) => {
  const [isSubscriptionUpdateModalOpen, setSubscriptionUpdateModal] =
    useState(false);
  const { authtoken } = useContext(authContext);
  const { groupId: currentGroup } = useParams<{ groupId?: string }>();
  const { uuid } = useCurrentGroupContext();
  const groupId = currentGroup || uuid;
  const { platform_subscription: groupPlatformSubscription } =
    useCurrentGroupContext();

  const { isCpa } = useRoleBasedView();
  const [openBooksModal] = useState(false);
  const entityId = useCurrentEntityId();
  const dispatch = useDispatch();
  const { trackEvent } = useAnalytics();
  const query = useQuery();
  let subscriptionTypeForModalOpen = query.get("subscription_to_open");

  const { data: allSubscriptions = [] } = useGetSubscriptionsQuery(
    {
      groupId,
      entityId,
    },
    { skip: !groupId || !entityId }
  );

  const { allAddOnSubscriptions, booksPlusPlanToshow } =
    useGetSubscriptionsToShow({ allSubscriptions });

  let {
    platform_subscription: currentSubscription,
  }: {
    platform_subscription: SubscriptionInProfile;
  } = authtoken || {};

  if (currentGroup) {
    currentSubscription = groupPlatformSubscription;
  }

  const isCurrentYearIncorporated =
    dayjs(currentSubscription.scheduled_date)
      .subtract(1, "year")
      .year()
      .toString() === new Date().getFullYear().toString();

  const isAnnualSubscription = currentSubscription.billing_cycle === YEARLY;

  const subscriptionAmount =
    currentSubscription.stripe_coupon?.discounted_amount ||
    currentSubscription.amount;

  // Why we are doing this?
  useEffect(() => {
    document.body.style.pointerEvents = "auto";
  }, [openBooksModal]);

  useEffect(() => {
    allAddOnSubscriptions.forEach((addon: any) => {
      if (subscriptionTypeForModalOpen === addon.subscription_type) {
        onSubscribeHandler(addon);
      }
    });
  }, [subscriptionTypeForModalOpen, allAddOnSubscriptions.length]);

  const onManagePlan = (useCart: boolean = false) => {
    trackEvent(OPENED_MANAGE_PLAN_MODAL);
    dispatch(toggleCartSubscription(useCart));
    setSubscriptionUpdateModal(true);
  };

  const onSubscribeHandler = (addon: AddonData, useCart: boolean = false) => {
    dispatch(toggleCartSubscription(useCart));
    dispatch(setAddonData(addon));
    dispatch(setAddonType(addon?.subscription_type!));
  };

  const onManageHandler = (addon: AddonData) => {
    dispatch(setAddonData(addon));
    dispatch(setAddonType(addon?.subscription_type + _MANAGE));
  };

  return (
    <>
      {currentSubscription && (
        <div className="!t-border-none">
          <div className="t-grid md:t-grid-cols-2 lg:t-grid-cols-3 t-gap-5">
            <ProductCard
              title={
                <div className="t-flex t-flex-col t-gap-1">
                  <div className="t-flex t-gap-2">
                    {currentSubscription.subscription_name || "Free Plan"}
                    {!Boolean(currentSubscription.subscription_status) ||
                    currentSubscription.subscription_status === "ACTIVE" ? (
                      <Badge color="light-green" size="small">
                        Active
                      </Badge>
                    ) : (
                      <Badge color="red" size="small">
                        Overdue
                      </Badge>
                    )}
                  </div>
                  {isCurrentYearIncorporated && (
                    <div className="t-flex t-text-body-sm t-text-neutral-70 t-bg-neutral-0 t-rounded-3xl t-px-3 t-items-center t-w-2/3 t-py-1">
                      Next payment:{" "}
                      {formatDate(currentSubscription?.scheduled_date)}
                    </div>
                  )}
                </div>
              }
              price={
                <span className="">
                  <Price
                    amount={subscriptionAmount}
                    size="small"
                    cycle={isAnnualSubscription ? YEARLY : MONTHLY}
                  />

                  <div>
                    <div className="t-mt-2">
                      <Discount
                        coupon={currentSubscription.stripe_coupon}
                        amount={currentSubscription.amount}
                      />
                    </div>
                    {authtoken.platform_subscription.ongoing_extended_month && (
                      <span className="t-mb-4 t-text-body-sm t-text-dark_green t-flex t-gap-1">
                        <TagSolid />
                        Offer applied! You have a Free month.
                      </span>
                    )}
                  </div>
                </span>
              }
              action={
                <div className="t-flex t-gap-1">
                  <Button
                    size="small"
                    onClick={() => onManagePlan(false)}
                    disabled={isCpa}
                  >
                    Manage plan
                  </Button>
                  {!currentSubscription.subscription_uuid && (
                    <Button
                      size="small"
                      onClick={() => onManagePlan(true)}
                      customType="icon"
                    >
                      <Cart />
                    </Button>
                  )}
                </div>
              }
            />

            {allAddOnSubscriptions?.length > 0 &&
              allAddOnSubscriptions
                .toSorted((a, b) => (a.group_subscription ? -1 : 1))
                .map((addon) => {
                  return (
                    <ProductCard
                      key={addon.uuid}
                      title={
                        <div className="t-flex t-gap-2">
                          {addon?.subscription_name}
                          {addon?.group_subscription && (
                            <div className="t-text-body">
                              <Badge color="light-green">Active</Badge>
                            </div>
                          )}
                        </div>
                      }
                      description={
                        !addon?.stripe_coupon && (
                          <GetDescription
                            type={addon?.subscription_type}
                            subscriptions={addon?.group_subscription}
                          />
                        )
                      }
                      price={<AddonPricing addon={addon} />}
                      action={
                        addon?.group_subscription ? (
                          <div className="t-flex t-gap-1">
                            <Button
                              size="small"
                              onClick={() => onManageHandler(addon)}
                            >
                              Manage plan
                            </Button>
                            <>
                              {[
                                REGISTERED_AGENT,
                                BOOKKEEPING_SOFTWARE,
                              ].includes(addon.subscription_type) && (
                                <Button
                                  customType="icon"
                                  size="small"
                                  onClick={() =>
                                    onSubscribeHandler(addon, true)
                                  }
                                >
                                  <Cart />
                                </Button>
                              )}
                            </>
                          </div>
                        ) : (
                          <div className="t-flex t-gap-1">
                            <Button
                              size="small"
                              onClick={() => onSubscribeHandler(addon, false)}
                            >
                              Subscribe
                            </Button>
                            <Button
                              customType="icon"
                              size="small"
                              onClick={() => onSubscribeHandler(addon, true)}
                            >
                              <Cart />
                            </Button>
                          </div>
                        )
                      }
                    />
                  );
                })}

            {booksPlusPlanToshow && (
              <ProductCard
                title={
                  <div className="t-flex t-gap-2">
                    {booksPlusPlanToshow.subscription_name}
                    {booksPlusPlanToshow.group_subscription && (
                      <div className="t-text-body">
                        <Badge color="light-green">Active</Badge>
                      </div>
                    )}
                  </div>
                }
                price={
                  <Price
                    amount={booksPlusPlanToshow.amount}
                    size="small"
                    cycle={booksPlusPlanToshow.billing_cycle}
                  />
                }
                description={
                  <div className="t-text-body-sm t-text-text-30">
                    Take your accounting to the next level with premium features
                    and automation.
                  </div>
                }
                action={
                  booksPlusPlanToshow.group_subscription ? (
                    <Button
                      size="small"
                      onClick={() => onManageHandler(booksPlusPlanToshow)}
                    >
                      Manage plan
                    </Button>
                  ) : (
                    <div className="t-flex t-gap-1">
                      <Button
                        size="small"
                        onClick={() =>
                          onSubscribeHandler(booksPlusPlanToshow, false)
                        }
                      >
                        Subscribe
                      </Button>
                      <Button
                        customType="icon"
                        size="small"
                        onClick={() =>
                          onSubscribeHandler(booksPlusPlanToshow, true)
                        }
                      >
                        <Cart />
                      </Button>
                    </div>
                  )
                }
              />
            )}
          </div>
        </div>
      )}
      <SubscriptionUpdateModal
        isOpen={isSubscriptionUpdateModalOpen}
        handleClose={() => setSubscriptionUpdateModal(false)}
        freeSeats={freeSeats}
        extraSeats={extraSeats}
        activeSeats={activeSeats}
        setShowSeatManage={setShowSeatManage}
      />
    </>
  );
};
