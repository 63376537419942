import {
  Combobox,
  OptionData,
} from "components/DesignSystem/Combobox/Combobox";
import {
  BareInput,
  TextInput,
} from "components/DesignSystem/TextInput/TextInput";
import { getIn, useFormikContext } from "formik";
import { useGetCountriesDataQuery } from "store/apis/onboarding";
import {
  APIProvider,
  ControlPosition,
  MapControl,
  AdvancedMarker,
  Map,
  useMap,
  useMapsLibrary,
  useAdvancedMarkerRef,
  AdvancedMarkerRef,
} from "@vis.gl/react-google-maps";
import {
  useState,
  useRef,
  useEffect,
  ChangeEvent,
  ComponentProps,
} from "react";
import Dropdown from "components/DesignSystem/Dropdown/Dropdown";
import { debounce } from "utils/debouncing";
import { Countries } from "types/Models/countries";
import { MultiValue, SingleValue } from "react-select";

const API_KEY = process.env.REACT_APP_GOOGLE_MAP_KEY;

type PlaceAutocompleteProps = {
  availableCountriesData: Countries[];
  formPrefix?: string;
  required?: boolean;
};

const getCity = (place: google.maps.places.Place) => {
  return place.addressComponents?.find((c) => c.types.includes("locality"))
    ?.longText;
};

const getCountry = (
  place: google.maps.places.Place,
  availableCountriesData: Countries[]
) => {
  return availableCountriesData.find(
    (country) =>
      country.code_alpha_2 ===
      place.addressComponents?.find((c) => c.types.includes("country"))
        ?.shortText
  )?.name;
};

const getState = (
  place: google.maps.places.Place,
  availableCountriesData: Countries[]
) => {
  return availableCountriesData
    .find(
      (country) =>
        country.code_alpha_2 ===
        place.addressComponents?.find((c) => c.types.includes("country"))
          ?.shortText
    )
    ?.states.find(
      (state) =>
        state.name ===
        place.addressComponents?.find((c) =>
          c.types.includes("administrative_area_level_1")
        )?.longText
    )?.name;
};

const getZipCode = (place: google.maps.places.Place) => {
  return place.addressComponents?.find((c) => c.types.includes("postal_code"))
    ?.longText;
};

const getStreetName = (place: google.maps.places.Place) => {
  return place.addressComponents?.find((c) => c.types.includes("route"))
    ?.longText;
};

const getStreetAddress = (place: google.maps.places.Place) => {
  return `${
    place.addressComponents?.find((c) => c.types.includes("street_number"))
      ?.longText || ""
  } ${getStreetName(place) || ""}`;
};

export const PlaceAutocomplete = ({
  availableCountriesData,
  formPrefix = "",
  required = true,
}: PlaceAutocompleteProps) => {
  const place = useMapsLibrary("places");
  const tokenRef = useRef<any>();
  const [suggestedPlaces, setSuggestedPlaces] = useState<
    google.maps.places.AutocompleteSuggestion[]
  >([]);
  const [isLoading, setIsLoading] = useState(false);
  const { setFieldValue, values, errors } = useFormikContext<{
    street_address: string;
  }>();

  useEffect(() => {
    if (place?.AutocompleteSessionToken) {
      tokenRef.current = new place.AutocompleteSessionToken();
    }
  }, [place?.AutocompleteSessionToken]);

  const onChange = async (newValue: string) => {
    if (place) {
      const input = newValue;
      let request = {
        input,
        language: "en-US",
        sessionToken: tokenRef.current,
      };
      try {
        setIsLoading(true);
        const { suggestions } =
          await place.AutocompleteSuggestion.fetchAutocompleteSuggestions(
            request
          );
        setSuggestedPlaces(suggestions);
      } catch (error) {}
      setIsLoading(false);
    }
  };

  const onSelect = async (
    e: SingleValue<OptionData> | MultiValue<OptionData>
  ) => {
    try {
      if (!e) {
        setFieldValue(`${formPrefix}city`, "");
        setFieldValue(`${formPrefix}country`, "");
        setFieldValue(`${formPrefix}state`, "");
        setFieldValue(`${formPrefix}zipcode`, "");
        setFieldValue(`${formPrefix}street_address`, "");
      }

      if (!(e instanceof Array) && place && e?.value) {
        const selectedPlace = new place.Place({
          id: e.value,
        });

        const placeInfo = await selectedPlace.fetchFields({
          fields: ["addressComponents"],
        });
        setFieldValue(`${formPrefix}city`, getCity(placeInfo.place));
        setFieldValue(
          `${formPrefix}country`,
          getCountry(placeInfo.place, availableCountriesData)
        );
        setFieldValue(
          `${formPrefix}state`,
          getState(placeInfo.place, availableCountriesData)
        );
        setFieldValue(`${formPrefix}zipcode`, getZipCode(placeInfo.place));
        setFieldValue(
          `${formPrefix}street_address`,
          getStreetAddress(placeInfo.place)
        );
      }
    } catch (error) {}
  };

  const debouncedOnChange = debounce(onChange);

  const error = getIn(errors, `${formPrefix}street_address`);
  const selectedStreetAddress = getIn(values, `${formPrefix}street_address`);

  return (
    <div className="autocomplete-container">
      <Combobox
        isLoading={isLoading}
        required={required}
        label="Street Address"
        onInputChange={(newValue) => {
          debouncedOnChange(newValue);
        }}
        onCreateOption={(newValue) =>
          setFieldValue(`${formPrefix}street_address`, newValue)
        }
        placeholder="Start typing to search"
        onMenuClose={() => setSuggestedPlaces([])}
        value={
          selectedStreetAddress
            ? {
                label: selectedStreetAddress,
                value: selectedStreetAddress,
              }
            : null
        }
        onChange={onSelect}
        formatCreateLabel={(inputValue) => `Enter manually "${inputValue}"`}
        creatable
        filterOption={() => true}
        // @ts-ignore
        options={suggestedPlaces
          .map((suggestion) => ({
            value: suggestion.placePrediction?.toPlace().id,
            label: suggestion.placePrediction?.text.text,
          }))
          .filter(({ value }) => value)}
        type={error && "error"}
      />

      {error && (
        <div className="t-mt-1.5 t-text-caption t-text-red">{error}</div>
      )}
    </div>
  );
};

export const AddressFormFields = ({
  formPrefix = "",
  required = true,
}: {
  formPrefix?: string;
  required?: boolean;
}) => {
  const { data: countries, isLoading: isCountriesLoading } =
    useGetCountriesDataQuery();

  const { values } = useFormikContext<{}>();

  const selectedCountry = getIn(values, `${formPrefix}country`);
  const selectedState = getIn(values, `${formPrefix}state`);

  return (
    <>
      <APIProvider
        apiKey={API_KEY || ""}
        solutionChannel="GMP_devsite_samples_v3_rgmautocomplete"
      >
        <div className="autocomplete-control">
          <PlaceAutocomplete
            formPrefix={formPrefix}
            availableCountriesData={countries || []}
            required={required}
          />
        </div>
      </APIProvider>
      <Combobox
        required={required}
        name={`${formPrefix}country`}
        withForm
        label="Country"
        placeholder="Select Country"
        options={
          countries?.map((country) => ({
            value: country.name,
            label: country.name,
          }))!
        }
        value={
          selectedCountry
            ? { label: selectedCountry, value: selectedCountry }
            : null
        }
      />
      <Combobox
        name={`${formPrefix}state`}
        required={required}
        withForm
        label="State"
        placeholder="Select State"
        options={
          countries
            ?.find((c) => c.name === selectedCountry)
            ?.states?.map((state) => ({
              value: state.name,
              label: state.name,
            }))!
        }
        value={
          selectedState ? { label: selectedState, value: selectedState } : null
        }
      />

      <TextInput
        required={required}
        block
        label="City"
        name={`${formPrefix}city`}
      />
      <TextInput
        required={required}
        block
        label="Zip, postal or pin code"
        name={`${formPrefix}zipcode`}
      />
    </>
  );
};
